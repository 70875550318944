import { createSelector } from 'reselect';

import { AppState } from '../../_store/rootReducer';
import { IssuesState } from './reducer';

const selectNode = (state: AppState) => state.issues;

export const isLoading = createSelector(selectNode, (state: IssuesState) => state.isLoading);
export const getError = createSelector(selectNode, (state: IssuesState) => state.error);
export const getIssues = createSelector(selectNode, (state: IssuesState) => state.issues);
export const getIssue = (state: AppState, issueId: string) => getIssues(state).find(({ id }) => id === issueId);
