import React, { FC } from 'react';
import { CheckboxProps, Radio } from 'semantic-ui-react';
import classnames from 'classnames';

import ErrorMessage from '../errorMessage/ErrorMessage';
import { useInputError } from '../../_hooks';
import { InputCheckboxProps } from './InputCheckbox';

export interface InputRadioProps extends InputCheckboxProps {
  value: string;
}

const InputRadio: FC<InputRadioProps> = ({ error, errorMessage, onChange, ...props }) => {
  const { showError, setDirty } = useInputError(error);

  return (
    <div className={classnames('input-wrapper', { error: showError })}>
      <Radio
        {...props}
        type="radio"
        onChange={(_, data: CheckboxProps) => {
          onChange(data.checked, data.name);
          setDirty();
        }}
      />
      <ErrorMessage isVisible={showError}>{errorMessage}</ErrorMessage>
    </div>
  );
};

export default InputRadio;
