import React, { FC, ReactNode, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useToggle, useActionPromise } from '../../../_hooks';
import { translations } from '../../../_translations';
import { Button, Modal, InputCheckbox } from '../../../_shared';
import TeamsDropdown from '../../../teams/teamsDropdown/TeamsDropdown';
import { ITask } from '../../_models/task';
import { canTeamExecuteTask } from '../../../teams/_models/rules';
import { PlanTaskAction } from '../../_store/actions';
import { tasksSelectors } from '../../../_store/selectors';

import '../moveTaskModal/moveTaskModal.scss';

interface Props {
  task: ITask;
  trigger: ReactNode;
}

const AddTaskTeamModal: FC<Props> = ({ trigger, task }) => {
  const isLoading = useSelector(tasksSelectors.isPlanTaskLoading);
  const error = useSelector(tasksSelectors.errorPlanTask);
  const dispatch = useDispatch();

  const [isModalVisible, toggleModal] = useToggle(false);
  const [isError, toggleError] = useToggle(false);
  const [selectedTeamId, setSelectedTeamId] = useState('');
  const [isMainTeam, setIsMainTeam] = useToggle(false);

  useActionPromise(isLoading, toggleModal, toggleError, error);

  return (
    <Modal className="plan-task-modal" open={isModalVisible} onOpen={toggleModal} onClose={toggleModal} trigger={trigger}>
      <Modal.Header>{translations.getLabel('MODAL_ADD_TASK_TEAM_TITLE')}</Modal.Header>
      <Modal.Content>
        <p>{translations.getLabel('MODAL_ADD_TASK_TEAM_BODY')}</p>
        <TeamsDropdown
          error={isError}
          errorMessage={translations.getLabel('SCHEDULE_ERROR')}
          filter={team => canTeamExecuteTask(team, task)}
          onChange={(value: string) => {
            toggleError(false);
            setSelectedTeamId(value);
          }}
          value={selectedTeamId}
        />
        <InputCheckbox
          checked={isMainTeam}
          label={translations.getLabel('MODAL_ADD_TASK_TEAM_MAIN_TEAM')}
          name="mainTeam"
          onChange={checked => setIsMainTeam(checked)}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button isTextLink onClick={toggleModal}>
          {translations.getLabel('CANCEL')}
        </Button>
        <Button
          primary
          loading={isLoading}
          onClick={() =>
            dispatch(
              new PlanTaskAction({
                task,
                plannedTeamId: selectedTeamId,
                mainTeamId: isMainTeam ? selectedTeamId : null,
                startTime: task.startTime,
              }),
            )
          }
        >
          {translations.getLabel('SCHEDULE')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default AddTaskTeamModal;
