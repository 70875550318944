import React, { FC } from 'react';
import { Checkbox, CheckboxProps } from 'semantic-ui-react';
import classnames from 'classnames';

import ErrorMessage from '../errorMessage/ErrorMessage';
import { useInputError } from '../../_hooks';

export interface InputCheckboxProps {
  checked?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  label?: string;
  name?: string;
  onChange?: (checked: boolean, name: string) => void;
}

const InputCheckbox: FC<InputCheckboxProps> = ({ error, errorMessage, onChange, ...props }) => {
  const { showError, setDirty } = useInputError(error);

  return (
    <div className={classnames('input-wrapper', { error: showError })}>
      <Checkbox
        {...props}
        onChange={(_, data: CheckboxProps) => {
          onChange(data.checked, data.name);
          setDirty();
        }}
        type="checkbox"
      />
      <ErrorMessage isVisible={showError}>{errorMessage}</ErrorMessage>
    </div>
  );
};

export default InputCheckbox;
