import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ITeam } from '../_models/team';
import { InputDropdown } from '../../_shared';
import { DropdownOption, InputDropdownProps } from '../../_shared/input/InputDropdown';
import { translations } from '../../_translations';
import { teamsSelectors } from '../../_store/selectors';

interface Props extends InputDropdownProps {
  filter?: (team: ITeam) => boolean;
  value?: string;
  onChange: (value: string, name: string) => void;
}

const TeamsDropdown: FC<Props> = ({ value, onChange, filter, ...props }) => {
  const teams = useSelector(teamsSelectors.teams) || [];

  const options: DropdownOption[] = useMemo(
    () => teams.filter(filter).map(team => ({ text: team.name, value: team.id })),
    [teams, filter],
  );

  return (
    <InputDropdown
      fluid
      name="team"
      onChange={(value: string, name: string) => onChange(value, name)}
      options={options}
      placeholder={translations.getLabel('INPUT_TEAM_PLACEHOLDER')}
      value={value}
      {...props}
    />
  );
};

export default TeamsDropdown;
