import { Action } from 'redux';

export enum ActionType {
  SetCurrentDate = '[Planning] setCurrentDate',
  PollRealtimeData = '[Planning] PollRealtimeData',
}

export class SetCurrentDateAction implements Action<ActionType> {
  readonly type = ActionType.SetCurrentDate;
  constructor(public payload: { date: Date }) {}
}

export class PollRealtimeDataAction implements Action<ActionType> {
  readonly type = ActionType.PollRealtimeData;
}

export type Actions = SetCurrentDateAction | PollRealtimeDataAction;
