import { Action } from 'redux';

import { ITeam } from '../_models/team';
import { ApiError } from '../../_http';
import { ITeamMember } from '../_models/teamMember';
import { IEditTeam } from '../_models/editTeam';
import { IEmployee } from '../_models/employee';

export enum ActionType {
  GetTeams = '[Teams] GetTeams',
  GetTeamsSuccess = '[Teams] GetTeamsSuccess',
  GetTeamsError = '[Teams] GetTeamsError',
  EditTeam = '[Teams] EditTeam',
  EditTeamSuccess = '[Teams] EditTeamSuccess',
  EditTeamError = '[Teams] EditTeamError',
  PlanTeam = '[Teams] PlanTeam',
  PlanTeamSuccess = '[Teams] PlanTeamSuccess',
  PlanTeamError = '[Teams] PlanTeamError',
  GetEmployees = '[Teams] GetEmployees',
  GetEmployeesSuccess = '[Teams] GetEmployeesSuccess',
  GetEmployeesError = '[Teams] GetEmployeesError',
  RemoveTeam = '[Teams] RemoveTeam',
  RemoveTeamSuccess = '[Teams] RemoveTeamSuccess',
  RemoveTeamError = '[Teams] RemoveTeamError',
  RemoveTeamMember = '[Teams] RemoveTeamMember',
  RemoveTeamMemberSuccess = '[Teams] RemoveTeamMemberSuccess',
  RemoveTeamMemberError = '[Teams] RemoveTeamMemberError',
}

export class GetTeamsAction implements Action<ActionType> {
  readonly type = ActionType.GetTeams;
  constructor(public payload: { date: Date; visibleRefresh?: boolean }) {}
}

export class GetTeamsSuccessAction implements Action<ActionType> {
  readonly type = ActionType.GetTeamsSuccess;
  constructor(public payload: { data: ITeam[] }) {}
}

export class GetTeamsErrorAction implements Action<ActionType> {
  readonly type = ActionType.GetTeamsError;
  constructor(public payload: { error: ApiError }) {}
}

export class EditTeamAction implements Action<ActionType> {
  readonly type = ActionType.EditTeam;
  constructor(public payload: IEditTeam) {}
}

export class EditTeamSuccessAction implements Action<ActionType> {
  readonly type = ActionType.EditTeamSuccess;
  constructor(public payload: { isNewTeam: boolean; name: string }) {}
}

export class EditTeamErrorAction implements Action<ActionType> {
  readonly type = ActionType.EditTeamError;
  constructor(public payload: { error: ApiError }) {}
}

export class PlanTeamAction implements Action<ActionType> {
  readonly type = ActionType.PlanTeam;
  constructor(public payload: { team: ITeam; member: ITeamMember; pdaUser?: string; shouldPlanAllMembers?: boolean }) {}
}

export class PlanTeamSuccessAction implements Action<ActionType> {
  readonly type = ActionType.PlanTeamSuccess;
  constructor(public payload: { data: ITeam; name: string; isNewTeamMember: boolean; isForAllMembers?: boolean }) {}
}

export class PlanTeamErrorAction implements Action<ActionType> {
  readonly type = ActionType.PlanTeamError;
  constructor(public payload: { error: ApiError }) {}
}

export class GetEmployeesAction implements Action<ActionType> {
  readonly type = ActionType.GetEmployees;
  constructor(public payload: { teamId: string }) {}
}

export class GetEmployeesSuccessAction implements Action<ActionType> {
  readonly type = ActionType.GetEmployeesSuccess;
  constructor(public payload: { data: IEmployee[] }) {}
}

export class GetEmployeesErrorAction implements Action<ActionType> {
  readonly type = ActionType.GetEmployeesError;
  constructor(public payload: { error: ApiError }) {}
}

export class RemoveTeamAction implements Action<ActionType> {
  readonly type = ActionType.RemoveTeam;
  constructor(public payload: { teamId: string }) {}
}

export class RemoveTeamSuccessAction implements Action<ActionType> {
  readonly type = ActionType.RemoveTeamSuccess;
  constructor(public payload: { id: string; name: string }) {}
}

export class RemoveTeamErrorAction implements Action<ActionType> {
  readonly type = ActionType.RemoveTeamError;
  constructor(public payload: { error: ApiError }) {}
}

export class RemoveTeamMemberAction implements Action<ActionType> {
  readonly type = ActionType.RemoveTeamMember;
  constructor(public payload: { teamId: string; memberId: string }) {}
}

export class RemoveTeamMemberSuccessAction implements Action<ActionType> {
  readonly type = ActionType.RemoveTeamMemberSuccess;
  constructor(public payload: { data: ITeam; name: string }) {}
}

export class RemoveTeamMemberErrorAction implements Action<ActionType> {
  readonly type = ActionType.RemoveTeamMemberError;
  constructor(public payload: { error: ApiError }) {}
}

export type Actions =
  | GetTeamsAction
  | GetTeamsSuccessAction
  | GetTeamsErrorAction
  | EditTeamAction
  | EditTeamSuccessAction
  | EditTeamErrorAction
  | PlanTeamAction
  | PlanTeamSuccessAction
  | PlanTeamErrorAction
  | GetEmployeesAction
  | GetEmployeesSuccessAction
  | GetEmployeesErrorAction
  | RemoveTeamAction
  | RemoveTeamSuccessAction
  | RemoveTeamErrorAction
  | RemoveTeamMemberAction
  | RemoveTeamMemberSuccessAction
  | RemoveTeamMemberErrorAction;
