import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { GetTasksAction } from '../../tasks/_store/actions';
import { issuesApi } from '../../_store/api';
import { planningSelectors } from '../../_store/selectors';
import {
  ActionType,
  GetIssuesAction,
  GetIssuesErrorAction,
  GetIssuesSuccessAction,
  MarkIssueAsReadAction,
  MarkIssueAsReadErrorAction,
  MarkIssueAsReadSuccessAction,
} from './actions';

export const getIssuesEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetIssues).pipe(
    exhaustMap(({ payload }: GetIssuesAction) =>
      from(issuesApi.getIssuesForTask(payload.taskId)).pipe(
        map(data => new GetIssuesSuccessAction({ data })),
        catchError(error => of(new GetIssuesErrorAction({ error }))),
      ),
    ),
  );

export const markIssueAsReadEpic$: Epic = action$ =>
  action$.ofType(ActionType.MarkIssueAsRead).pipe(
    exhaustMap(({ payload }: MarkIssueAsReadAction) =>
      from(issuesApi.markIssueAsRead(payload.issueId)).pipe(
        map(() => new MarkIssueAsReadSuccessAction(payload)),
        catchError(error => of(new MarkIssueAsReadErrorAction({ error }))),
      ),
    ),
  );

export const markIssueAsReadSuccessEpic$: Epic = (action$, state$) =>
  action$
    .ofType(ActionType.MarkIssueAsReadSuccess)
    .pipe(
      switchMap(({ payload }: MarkIssueAsReadSuccessAction) =>
        of(
          new GetTasksAction({ date: planningSelectors.currentDate(state$.value) }),
          new GetIssuesAction({ taskId: payload.taskId }),
        ),
      ),
    );

const IssuesEpics = [getIssuesEpic$, markIssueAsReadEpic$, markIssueAsReadSuccessEpic$];

export default IssuesEpics;
