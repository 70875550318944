import { isBefore, isAfter, isWithinInterval } from 'date-fns';
import * as Sentry from '@sentry/browser';
import { ITaskGroup } from '../tasks/_models/taskGroup';
import { ITask, TeamTaskState } from '../tasks/_models/task';
import { taskDerivedStartDate, taskDerivedEndDate } from '../tasks/_models/rules';
import { dateFromString, dateFromISOString, ISOStringFromDate } from './timeHelper';

export const reduceTasks = (tasks: ITask[]) => {
  const invalidTasks: ITask[][] = [];
  const validTasks: ITask[][] = [];

  tasks.forEach(task => {
    // First check if the startTime ends after the endTime -> to be sure that they are not overlapping
    if (isAfter(taskDerivedStartDate(task), taskDerivedEndDate(task))) {
      invalidTasks.push([task]);
    } else {
      const validRowIndex = validTasks.findIndex(row =>
        isAfter(taskDerivedStartDate(task), taskDerivedEndDate(row[row.length - 1])),
      );
      if (validRowIndex >= 0) {
        validTasks[validRowIndex].push(task);
      } else {
        validTasks.push([task]);
      }
    }
  });

  if (invalidTasks.length > 0) {
    Sentry.setContext('invalid tasks', {
      invalidTasks,
      validTasks,
    });
  }

  return [...invalidTasks, ...validTasks];
};

export const calculateTaskGroups = (tasks: ITask[]): ITaskGroup[] => {
  return tasks
    .reduce((accu: ITaskGroup[], task: ITask) => {
      const taskStartTime = taskDerivedStartDate(task);
      const taskEndTime = taskDerivedEndDate(task);
      const taskGroup = accu.find(group => {
        // Check to prevent isWithinInterval from crashing (API can give "wrong" dates back, due to blockTime/startTime)
        if (isBefore(group.endDate, group.startDate)) return false;
        return (
          isWithinInterval(taskStartTime, { start: group.startDate, end: group.endDate }) ||
          isWithinInterval(taskEndTime, { start: group.startDate, end: group.endDate })
        );
      });
      if (taskGroup) {
        if (isAfter(taskEndTime, taskGroup.endDate)) {
          taskGroup.endDate = taskEndTime;
        }
        if (isBefore(taskStartTime, taskGroup.startDate)) {
          taskGroup.startDate = taskStartTime;
        }
        taskGroup.taskIds.push(task.id);
      } else {
        accu.push({ startDate: taskStartTime, endDate: taskEndTime, taskIds: [task.id] });
      }
      return accu;
    }, [])
    .filter(taskGroup => taskGroup.taskIds.length > 1);
};

export const validateNewStartTime = (task: ITask, date: string): { error: boolean; message?: string; startTime?: string } => {
  const newStartTime = dateFromString(`${date}`, 'dd/MM/yyyy HH:mm');
  if (!date || !newStartTime) return { error: true, message: 'ERROR_WRONG_TIME' };
  if (isBefore(newStartTime, dateFromISOString(task.earliestPossibleStartTime)))
    return { error: true, message: 'ERROR_BEFORE_EARLIEST_TIME' };
  return { error: false, startTime: ISOStringFromDate(newStartTime) };
};

export const getIndicatorForTeamTaskState = (teamTaskState: TeamTaskState): { icon: string; label: string } => {
  switch (teamTaskState) {
    case TeamTaskState.Created:
      return { icon: 'SvgWaiting', label: 'SCHEDULED_NOT_ACCEPTED' };
    case TeamTaskState.Accepted:
      return { icon: 'SvgWaiting', label: 'SCHEDULED_ACCEPTED' };
    case TeamTaskState.Arrived:
      return { icon: 'SvgCar', label: TeamTaskState.Arrived };
    case TeamTaskState.Started:
      return { icon: 'SvgStart', label: TeamTaskState.Started };
    case TeamTaskState.Ended:
      return { icon: 'SvgFinished', label: TeamTaskState.Ended };
    default:
      return null;
  }
};
