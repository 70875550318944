import { combineEpics } from 'redux-observable';
import activityLogEpics from '../activityLog/_store/epics';
import authEpics from '../auth/_store/epics';
import modalEpics from '../modal/_store/epics';
import planningEpics from '../planning/_store/epics';
import sidebarEpics from '../sidebar/_store/epics';
import tasksEpics from '../tasks/_store/epics';
import teamsEpics from '../teams/_store/epics';
import issuesEpics from '../issues/_store/epics';
import reportsEpics from '../reports/_store/epics';

const rootEpic = combineEpics(
  ...activityLogEpics,
  ...authEpics,
  ...modalEpics,
  ...planningEpics,
  ...sidebarEpics,
  ...tasksEpics,
  ...teamsEpics,
  ...issuesEpics,
  ...reportsEpics,
);

export default rootEpic;
