import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { tap, switchMap, map, catchError, exhaustMap, filter } from 'rxjs/operators';
import { startOfDay, endOfDay } from 'date-fns';
import { toast } from 'react-toastify';

import { translations } from '../../_translations';
import { ActionType as PlanningActionType, SetCurrentDateAction } from '../../planning/_store/actions';
import { CloseSidebarAction } from '../../sidebar/_store/actions';
import { ShowConfirmationModalAction } from '../../modal/_store/actions';
import { planningSelectors, tasksSelectors } from '../../_store/selectors';
import { tasksApi } from '../../_store/api';
import {
  ActionType,
  GetTasksAction,
  EditTaskAction,
  EditTaskSuccessAction,
  PlanTaskAction,
  RemoveTaskAction,
  RemoveTaskSuccessAction,
  EndTaskAction,
  GetTasksSuccessAction,
  GetTasksErrorAction,
  EditTaskErrorAction,
  PlanTaskSuccessAction,
  PlanTaskErrorAction,
  PlanTaskCancelAction,
  RemoveTaskErrorAction,
  EndTaskSuccessAction,
  EndTaskErrorAction,
  StartTaskAction,
  StartTaskSuccessAction,
  StartTaskErrorAction,
} from './actions';

export const getTasksEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetTasks).pipe(
    exhaustMap(({ payload }: GetTasksAction) =>
      from(tasksApi.getTasks(startOfDay(payload.date), endOfDay(payload.date))).pipe(
        map(data => new GetTasksSuccessAction({ data })),
        catchError(error => of(new GetTasksErrorAction({ error }))),
      ),
    ),
  );

export const editTaskEpic$: Epic = action$ =>
  action$.ofType(ActionType.EditTask).pipe(
    exhaustMap(({ payload }: EditTaskAction) =>
      from(tasksApi.editTask(payload.data, payload.taskId)).pipe(
        map(
          () =>
            new EditTaskSuccessAction({
              isNewTask: !payload.taskId,
              taskName: payload.data.name || payload.data.arrivalFlightNumber,
            }),
        ),
        catchError(error => of(new EditTaskErrorAction({ error }))),
      ),
    ),
  );

export const editTaskSuccessEpic$: Epic = (action$, state$) =>
  action$.ofType(ActionType.EditTaskSuccess).pipe(
    tap(({ payload }: EditTaskSuccessAction) =>
      toast(translations.getLabel(payload.isNewTask ? 'CREATE_SUCCESS' : 'EDIT_SUCCESS', { name: payload.taskName })),
    ),
    switchMap(() => of(new CloseSidebarAction(), new GetTasksAction({ date: planningSelectors.currentDate(state$.value) }))),
  );

export const planTaskWithConfirmationEpic$: Epic = action$ =>
  action$.ofType(ActionType.PlanTask).pipe(
    filter((action: PlanTaskAction) => !action.confirmed),
    map(({ payload }: PlanTaskAction) => {
      const isUnplanTask = !payload.plannedTeamId;
      const unplannedTeams = payload.task.teams.filter(team => payload.unplannedTeamIds.includes(team.id));
      const teamNames = unplannedTeams.map(team => team.name).join(', ');
      const content =
        unplannedTeams.length === 1
          ? translations.getLabel(`WARNING_TASK_${unplannedTeams[0].taskState}`, { team: teamNames })
          : translations.getLabel('WARNING_TASK_MULTIPLE_TEAMS', { teamNames });
      return new ShowConfirmationModalAction({
        data: {
          title: translations.getLabel('WARNING'),
          content: `${content} ${
            isUnplanTask ? translations.getLabel('WARNING_REMOVE_TASK') : translations.getLabel('WARNING_MOVE_TASK')
          }`,
          confirmText: isUnplanTask ? translations.getLabel('REMOVE_FROM_PLANNING') : translations.getLabel('MOVE'),
          confirmAction: () => new PlanTaskAction(payload, true),
          cancelAction: () => new PlanTaskCancelAction({ taskId: payload.task.id }),
        },
      });
    }),
  );

export const planTaskEpic$: Epic = action$ =>
  action$.ofType(ActionType.PlanTask).pipe(
    filter((action: PlanTaskAction) => action.confirmed),
    exhaustMap(({ payload }: PlanTaskAction) => {
      const { task, plannedTeamId, unplannedTeamIds, mainTeamId, startTime } = payload;
      return from(tasksApi.planTask(task, plannedTeamId, unplannedTeamIds, mainTeamId, startTime)).pipe(
        map(() => new PlanTaskSuccessAction({ taskId: task.id })),
        catchError(error => of(new PlanTaskErrorAction({ error, taskId: task.id }))),
      );
    }),
  );

export const planTaskSuccessEpic$: Epic = (action$, state$) =>
  action$
    .ofType(ActionType.PlanTaskSuccess)
    .pipe(
      switchMap(() => of(new CloseSidebarAction(), new GetTasksAction({ date: planningSelectors.currentDate(state$.value) }))),
    );

export const removeTaskEpic$: Epic = action$ =>
  action$.ofType(ActionType.RemoveTask).pipe(
    exhaustMap(({ payload }: RemoveTaskAction) =>
      from(tasksApi.removeTask(payload.taskId)).pipe(
        map(() => new RemoveTaskSuccessAction({ taskId: payload.taskId })),
        catchError(error => of(new RemoveTaskErrorAction({ error }))),
      ),
    ),
  );

export const removeTaskSuccessEpic$: Epic = (action$, state$) =>
  action$.ofType(ActionType.RemoveTaskSuccess).pipe(
    tap(({ payload }: RemoveTaskSuccessAction) =>
      toast(translations.getLabel('REMOVE_SUCCESS', { name: tasksSelectors.getTask(state$.value, payload.taskId).name })),
    ),
    switchMap(() => of(new CloseSidebarAction(), new GetTasksAction({ date: planningSelectors.currentDate(state$.value) }))),
  );

export const endTaskEpic$: Epic = action$ =>
  action$.ofType(ActionType.EndTask).pipe(
    exhaustMap(({ payload }: EndTaskAction) =>
      from(tasksApi.endTask(payload.taskId)).pipe(
        map(() => new EndTaskSuccessAction()),
        catchError(error => of(new EndTaskErrorAction({ error }))),
      ),
    ),
  );

export const endTaskSuccessEpic$: Epic = (action$, state$) =>
  action$
    .ofType(ActionType.EndTaskSuccess)
    .pipe(
      switchMap(() => of(new CloseSidebarAction(), new GetTasksAction({ date: planningSelectors.currentDate(state$.value) }))),
    );

export const startTaskEpic$: Epic = action$ =>
  action$.ofType(ActionType.StartTask).pipe(
    exhaustMap(({ payload }: StartTaskAction) =>
      from(tasksApi.startTask(payload.taskId)).pipe(
        map(() => new StartTaskSuccessAction()),
        catchError(error => of(new StartTaskErrorAction({ error }))),
      ),
    ),
  );

export const startTaskSuccessEpic$: Epic = (action$, state$) =>
  action$
    .ofType(ActionType.StartTaskSuccess)
    .pipe(
      switchMap(() => of(new CloseSidebarAction(), new GetTasksAction({ date: planningSelectors.currentDate(state$.value) }))),
    );

export const setCurrentTimeEpic$: Epic = action$ =>
  action$
    .ofType(PlanningActionType.SetCurrentDate)
    .pipe(
      switchMap(({ payload }: SetCurrentDateAction) =>
        of(new CloseSidebarAction(), new GetTasksAction({ date: payload.date, visibleRefresh: true })),
      ),
    );

const TasksEpics = [
  getTasksEpic$,
  editTaskEpic$,
  editTaskSuccessEpic$,
  planTaskWithConfirmationEpic$,
  planTaskEpic$,
  planTaskSuccessEpic$,
  removeTaskEpic$,
  removeTaskSuccessEpic$,
  endTaskEpic$,
  endTaskSuccessEpic$,
  startTaskEpic$,
  startTaskSuccessEpic$,
  setCurrentTimeEpic$,
];

export default TasksEpics;
