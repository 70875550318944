import React, { FC, BaseSyntheticEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useActionPromise } from '../../../_hooks';
import { Modal, InputField, Button, InputFieldDate, ErrorMessage } from '../../../_shared';
import { translations } from '../../../_translations';

import { formatDate, dateFromISOString } from '../../../_utils/timeHelper';
import { IEditTeam } from '../../_models/editTeam';
import { ITeam } from '../../_models/team';
import { EditTeamAction } from '../../_store/actions';
import { ApiError, getValidationErrorMessage, getValidationError } from '../../../_http';
import { useForm } from '../../../_hooks';
import { teamsSelectors, planningSelectors } from '../../../_store/selectors';

import './editTeamModal.scss';

interface Props {
  hideModal: () => void;
  team?: ITeam;
}

function labelForInput(name: string): string {
  return translations.getLabel(`EDIT_TEAM_FIELD_${name.toUpperCase()}`);
}

function getErrorMessage(isDuplicateError: boolean, error?: ApiError): string {
  return (
    error &&
    (!error.validationErrors
      ? translations.getLabel(isDuplicateError ? 'DUPLICATE_TEAM_NAME' : 'NEW_TEAM_ERROR')
      : getValidationErrorMessage(error, labelForInput))
  );
}

function initialFormFromTask(currentDate: Date, team?: ITeam): IEditTeam {
  return {
    teamId: team && team.id,
    name: team && team.name ? team.name : '',
    day: formatDate(team ? dateFromISOString(team.validFrom) : currentDate),
  };
}

const EditTeamModal: FC<Props> = ({ hideModal, team }) => {
  const isLoading = useSelector(teamsSelectors.isEditTeamLoading);
  const error = useSelector(teamsSelectors.errorEditTeam);
  const currentDate = useSelector(planningSelectors.currentDate);
  const dispatch = useDispatch();
  const { form, setFormAttribute, error: displayError, setError } = useForm(initialFormFromTask(currentDate, team));
  useActionPromise(isLoading, hideModal, () => setError(error), error);
  const isDuplicateError = displayError && displayError.error === 'DUPLICATE_TEAM_NAME';

  return (
    <Modal className="edit-team-modal" open onClose={hideModal}>
      <form
        onSubmit={(e: BaseSyntheticEvent) => {
          e.preventDefault();
          dispatch(new EditTeamAction({ name: form.name, day: form.day, teamId: form.teamId }));
        }}
        aria-labelledby="edit-team-form"
      >
        <Modal.Header id="edit-team-form">{translations.getLabel(team ? 'EDIT_TEAM' : 'CREATE_TEAM')}</Modal.Header>
        <Modal.Content>
          <InputField
            label={translations.getLabel('NAME')}
            placeholder={translations.getLabel('CHOOSE_TEAM_NAME')}
            error={!!getValidationError(displayError, 'name') || isDuplicateError}
            fluid
            name="name"
            value={form.name}
            onChange={setFormAttribute}
          />
          <p className="input-requirement">
            {translations.getLabel('EDIT_TEAM_FIELD_NAME_REQUIREMENT', { amount: 15 }).toLowerCase()}
          </p>
          {!team && (
            <InputFieldDate
              label={translations.getLabel('DATE')}
              error={!!getValidationError(displayError, 'day')}
              value={form.day}
              name="day"
              onChange={setFormAttribute}
            />
          )}
          <ErrorMessage isVisible={!!displayError}>{getErrorMessage(isDuplicateError, displayError)}</ErrorMessage>
        </Modal.Content>
        <Modal.Actions>
          <Button isTextLink onClick={hideModal}>
            {translations.getLabel('CANCEL')}
          </Button>
          <Button primary loading={isLoading} type="submit">
            {translations.getLabel('SAVE')}
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  );
};

export default EditTeamModal;
