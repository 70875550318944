import { createSelector } from 'reselect';

import { AppState } from '../../_store/rootReducer';
import { ReportsLogState } from './reducer';

const selectNode = (state: AppState) => state.reportsLog;

export const isLoading = createSelector(selectNode, (state: ReportsLogState) => state.isLoading);

export const data = createSelector(selectNode, (state: ReportsLogState) => state.data);

export const metadata = createSelector(selectNode, (state: ReportsLogState) => state.metadata);

export const error = createSelector(selectNode, (state: ReportsLogState) => state.error);

export const filter = createSelector(selectNode, (state: ReportsLogState) => state.filter);

export const isExporting = createSelector(selectNode, (state: ReportsLogState) => state.isExporting);
