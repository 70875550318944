import React, { FC, BaseSyntheticEvent, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { parse, isSameDay, addDays, isBefore } from 'date-fns';

import { useActionPromise, useForm, useModal } from '../../../_hooks';
import { translations } from '../../../_translations';
import { Button, Modal, ErrorMessage, InputCheckbox, InputFieldTime } from '../../../_shared';
import { getValidationError, ApiError } from '../../../_http';
import { ITeamMember } from '../../_models/teamMember';
import { ITeam } from '../../_models/team';
import { formatTime, dateFromISOString, ISOStringFromDate, formatDateRange } from '../../../_utils/timeHelper';
import { PlanTeamAction } from '../../_store/actions';
import { teamsSelectors } from '../../../_store/selectors';
import ChangeTeamMembersShiftsModal from '../changeTeamMembersShiftsModal/ChangeTeamMembersShiftsModal';

import './changeShiftModal.scss';

interface Props {
  teamMember: ITeamMember;
  team: ITeam;
  hideModal: () => void;
  plan?: (member: ITeamMember) => void;
}

interface IChangeShift {
  shiftStart?: string;
  shiftEnd?: string;
  isNextDay?: boolean;
}

function InitialFormFromTeamMember(member?: ITeamMember): IChangeShift {
  return {
    shiftStart: formatTime(member.shiftStart),
    shiftEnd: formatTime(member.shiftEnd),
    isNextDay: !isSameDay(dateFromISOString(member.shiftStart), dateFromISOString(member.shiftEnd)),
  };
}

const formatMember = (member: ITeamMember, form: IChangeShift): ITeamMember => {
  const changeDate = (time: string, day: string, isShiftEnd?: boolean) => {
    const date = parse(time, 'HH:mm', dateFromISOString(day));
    if (isShiftEnd && form.isNextDay) {
      return ISOStringFromDate(addDays(date, 1));
    }
    return ISOStringFromDate(date);
  };
  return {
    ...member,
    shiftStart: changeDate(form.shiftStart, member.shiftStart),
    shiftEnd: changeDate(form.shiftEnd, member.shiftStart, true),
  };
};

const getShiftValidationError = (member: ITeamMember, team: ITeam) => {
  const shiftStart = parse('00:000', 'ss:SSS', dateFromISOString(member.shiftStart));
  const shiftEnd = parse('00:000', 'ss:SSS', dateFromISOString(member.shiftEnd));
  const validFrom = parse('00:000', 'ss:SSS', dateFromISOString(team.validFrom));
  const validTo = parse('00:000', 'ss:SSS', dateFromISOString(team.validTo));

  if (isBefore(shiftEnd, shiftStart)) {
    return {
      shiftStart: 'ERROR_SHIFT_END_BEFORE_SHIFT_START',
      shiftEnd: 'ERROR_SHIFT_END_BEFORE_SHIFT_START',
    };
  }
  if (!member.isPdaUser && isBefore(shiftStart, validFrom)) {
    if (isBefore(validTo, shiftEnd)) {
      return {
        shiftStart: 'ERROR_SHIFT_START_BEFORE_TEAM_SHIFT_START',
        shiftEnd: 'ERROR_SHIFT_END_BEFORE_TEAM_SHIFT_END',
      };
    }
    return {
      shiftStart: 'ERROR_SHIFT_START_BEFORE_TEAM_SHIFT_START',
      shiftEnd: '',
    };
  }
  if (!member.isPdaUser && isBefore(validTo, shiftEnd)) {
    return {
      shiftStart: '',
      shiftEnd: 'ERROR_SHIFT_END_BEFORE_TEAM_SHIFT_END',
    };
  }
  return null;
};

function getErrorMessage(error?: ApiError): string {
  if (error) {
    if (error.error === 'DUPLICATE_TEAM_NAME') return translations.getLabel('ERROR_DUPLICATE_TEAM_NAME');
    if (error.error === 'OUT_OF_BOUND_TASKS') return translations.getLabel('ERROR_OUT_OF_BOUND_TASKS');
    if (error.error === 'SHIFT_PLANNING_OVERLAP') return translations.getLabel('ERROR_SHIFT_PLANNING_OVERLAP');
    return translations.getLabel('PLAN_TEAM_ERROR');
  }
}

const ChangeShiftModal: FC<Props> = ({ teamMember, team, hideModal, plan }) => {
  const isLoading = useSelector(teamsSelectors.isPlanTeamLoading);
  const error = useSelector(teamsSelectors.errorPlanTeam);
  const dispatch = useDispatch();

  const [validationError, setValidationError] = useState(null);
  const { form, setFormAttribute, error: displayError, setError } = useForm(InitialFormFromTeamMember(teamMember));
  const [renderChangeTeamMembersShiftsModal, showChangeTeamMembersShiftsModal] = useModal(
    modalProps => <ChangeTeamMembersShiftsModal team={team} member={teamMember} {...modalProps} />,
    null,
    hideModal,
  );

  const handleSuccess = () => {
    if (teamMember.isPdaUser && team.members.length > 1) {
      showChangeTeamMembersShiftsModal();
    } else {
      hideModal();
    }
  };

  useActionPromise(isLoading, handleSuccess, () => setError(error), error);

  const date = formatDateRange(team.validFrom, team.validTo, {
    format: 'eee d MMM',
    firstMonthFormat: 'eee d',
    showSingleDay: true,
  });
  return (
    <>
      <Modal className="change-shift-modal" open onClose={hideModal}>
        <form
          onSubmit={(e: BaseSyntheticEvent) => {
            e.preventDefault();
            const formattedMember = formatMember(teamMember, form);
            const shiftValidationError = getShiftValidationError(formattedMember, team);
            if (!shiftValidationError) {
              if (plan) plan(formattedMember);
              else dispatch(new PlanTeamAction({ team, member: formattedMember }));
            } else {
              setValidationError(shiftValidationError);
            }
          }}
        >
          <Modal.Header>
            <h1>{teamMember.name}</h1>
            <h2>{translations.getLabel('SHIFT_INFO', { team: team.name, date })}</h2>
          </Modal.Header>
          <Modal.Content>
            <div className="shift-times-wrapper">
              <InputFieldTime
                error={!!getValidationError(displayError, 'shiftStart') || !!(validationError && validationError.shiftStart)}
                fluid
                label={translations.getLabel('FROM')}
                name="shiftStart"
                onChange={setFormAttribute}
                value={form.shiftStart}
              />
              <InputFieldTime
                error={!!getValidationError(displayError, 'shiftEnd') || !!(validationError && validationError.shiftEnd)}
                fluid
                label={translations.getLabel('TO')}
                name="shiftEnd"
                onChange={setFormAttribute}
                value={form.shiftEnd}
              />
            </div>
            <InputCheckbox
              label={translations.getLabel('ENDTIME_NEXT_DAY')}
              checked={form.isNextDay}
              name="isNextDay"
              onChange={setFormAttribute}
            />
            <ErrorMessage isVisible={!!displayError}>{getErrorMessage(displayError)}</ErrorMessage>
            {validationError && validationError.shiftStart === validationError.shiftEnd ? (
              <ErrorMessage isVisible>{translations.getLabel(validationError.shiftStart)}</ErrorMessage>
            ) : (
              <>
                <ErrorMessage isVisible={validationError && validationError.shiftStart}>
                  {translations.getLabel(validationError && validationError.shiftStart)}
                </ErrorMessage>
                <ErrorMessage isVisible={validationError && validationError.shiftEnd}>
                  {translations.getLabel(validationError && validationError.shiftEnd)}
                </ErrorMessage>
              </>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={hideModal} isTextLink>
              {translations.getLabel('CANCEL')}
            </Button>
            <Button primary type="submit" loading={isLoading}>
              {translations.getLabel('SAVE')}
            </Button>
          </Modal.Actions>
        </form>
      </Modal>
      {renderChangeTeamMembersShiftsModal()}
    </>
  );
};

export default ChangeShiftModal;
