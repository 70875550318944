import React, { FC, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useToggle, useActionPromise } from '../../../_hooks';
import { translations } from '../../../_translations';
import { Button, ErrorMessage, Modal } from '../../../_shared';
import { EndTaskAction } from '../../_store/actions';
import { tasksSelectors } from '../../../_store/selectors';

interface Props {
  taskId: string;
  trigger: ReactNode;
}

const EndTaskModal: FC<Props> = ({ trigger, taskId }) => {
  const isLoading = useSelector(tasksSelectors.isEndTaskLoading);
  const error = useSelector(tasksSelectors.errorEndTask);
  const dispatch = useDispatch();

  const [isModalVisible, toggleModal] = useToggle(false);
  const [isError, toggleError] = useToggle(false);

  useActionPromise(isLoading, toggleModal, toggleError, error);

  return (
    <Modal className="end-task-modal" open={isModalVisible} onOpen={toggleModal} onClose={toggleModal} trigger={trigger}>
      <Modal.Header>{translations.getLabel('END_TASK')}</Modal.Header>
      <Modal.Content>
        <p>{translations.getLabel('CONFIRM_END_TASK')}</p>
        <ErrorMessage isVisible={isError}>{translations.getLabel('END_TASK_ERROR')}</ErrorMessage>
      </Modal.Content>
      <Modal.Actions>
        <Button isTextLink onClick={toggleModal}>
          {translations.getLabel('CANCEL')}
        </Button>
        <Button primary loading={isLoading} onClick={() => dispatch(new EndTaskAction({ taskId }))}>
          {translations.getLabel('END')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default EndTaskModal;
