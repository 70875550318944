import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { translations } from '../_translations';
import { useInfiniteScroll } from '../_hooks';
import { reportsLogSelectors } from '../_store/selectors';
import { ReportsLogFilter } from './ReportsLogFilter';
import { ReportsLogTable } from './ReportsLogTable';
import { GetReportsLogAction, SetReportsLogFilterAction } from './_store/actions';

import './reportsLog.scss';
import { TReportsLogFilter } from './_models/reportsLogFilter';

export const ReportsLog = () => {
  const data = useSelector(reportsLogSelectors.data);
  const metadata = useSelector(reportsLogSelectors.metadata);
  const filter = useSelector(reportsLogSelectors.filter);
  const isLoading = useSelector(reportsLogSelectors.isLoading);
  const dispatch = useDispatch();

  const ref = useRef<HTMLDivElement>(null);
  const { resetOffset } = useInfiniteScroll(
    (offset: number) => dispatch(new GetReportsLogAction({ offset })),
    metadata,
    isLoading,
    ref,
  );

  const setFilter = (partialFilter: TReportsLogFilter) => {
    dispatch(new SetReportsLogFilterAction({ filter: { ...filter, ...partialFilter } }));
    resetOffset();
  };

  const noData = !isLoading && data && data.length === 0;
  return (
    <div ref={ref} className="reports-log">
      <ReportsLogFilter setFilter={setFilter} />
      {noData ? (
        <div className="no-data">
          <span>{translations.getLabel('NO_DATA_SEARCH')}</span>
          <span>{translations.getLabel('HISTORY_TABLE_NO_DATA')}</span>
        </div>
      ) : (
        <ReportsLogTable data={data} isLoading={isLoading} setFilter={setFilter} />
      )}
    </div>
  );
};
