import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { sessionStorageHelper } from '../_utils';

const AuthorizedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        sessionStorageHelper.hasApiKey() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/auth/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default AuthorizedRoute;
