import { Epic } from 'redux-observable';
import { map } from 'rxjs/operators';
import { LOCATION_CHANGE } from 'connected-react-router';

import { MarkIssueAsReadAction } from '../../issues/_store/actions';
import { CloseSidebarAction, ActionType, ShowSidebarTaskIssueDetailAction } from './actions';

export const locationChangeEpic$: Epic = action$ => action$.ofType(LOCATION_CHANGE).pipe(map(() => new CloseSidebarAction()));

export const showSidebarTaskIssueDetailEpic$: Epic = action$ =>
  action$
    .ofType(ActionType.ShowSidebarTaskIssueDetail)
    .pipe(
      map(
        ({ payload }: ShowSidebarTaskIssueDetailAction) =>
          new MarkIssueAsReadAction({ issueId: payload.data.issueId, taskId: payload.data.taskId }),
      ),
    );

const SidebarEpics = [locationChangeEpic$, showSidebarTaskIssueDetailEpic$];

export default SidebarEpics;
