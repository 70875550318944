import { ITask, TaskState } from '../_models/task';
import { ApiError } from '../../_http';
import { CloseSidebarAction } from '../../sidebar/_store/actions';
import { ActionType as SidebarActionType } from '../../sidebar/_store/actions';
import { ITaskGroup } from '../_models/taskGroup';
import { taskHelper } from '../../_utils';
import { ActionType, Actions } from './actions';

export interface TasksState {
  isLoading: boolean;
  isEditTaskLoading: boolean;
  isEndTaskLoading: boolean;
  isStartTaskLoading: boolean;
  isPlanTaskLoading: boolean;
  isRemoveTaskLoading: boolean;
  tasksPlanned?: Record<string, ITask[]>;
  tasksUnplanned?: ITask[];
  error?: ApiError;
  errorEditTask?: ApiError;
  errorEndTask?: ApiError;
  errorStartTask?: ApiError;
  errorPlanTask?: ApiError;
  errorRemoveTask?: ApiError;
  taskGroups?: Record<string, ITaskGroup[]>;
  collapsedTaskIds: Record<string, string[]>;
  loadingPlanTaskIds: string[];
}

const initialState: TasksState = {
  isLoading: false,
  isEditTaskLoading: false,
  isEndTaskLoading: false,
  isStartTaskLoading: false,
  isPlanTaskLoading: false,
  isRemoveTaskLoading: false,
  tasksPlanned: {},
  tasksUnplanned: [],
  error: null,
  errorEditTask: null,
  errorEndTask: null,
  errorStartTask: null,
  errorPlanTask: null,
  errorRemoveTask: null,
  taskGroups: {},
  collapsedTaskIds: {},
  loadingPlanTaskIds: [],
};

export default function reducer(state = initialState, action: Actions | CloseSidebarAction): TasksState {
  switch (action.type) {
    case ActionType.GetTasks:
      return {
        ...state,
        isLoading: action.payload.visibleRefresh,
        error: null,
      };
    case ActionType.GetTasksSuccess: {
      const [tasksPlanned, tasksUnplanned] = action.payload.data.reduce(
        (acc, task) => {
          // A planned task without teams should also be shown in the unplanned row
          if (task.state === TaskState.Unplanned || task.teams.length < 1) acc[1].push(task);
          else {
            task.teams.forEach(team => {
              if (!acc[0][team.id]) acc[0][team.id] = [];
              acc[0][team.id].push(task);
            });
          }
          return acc;
        },
        [{}, []],
      );

      // Calculate the taskGroups, and open groups that are changed
      const collapsedTaskIds = { ...(state.collapsedTaskIds || {}) };
      const taskGroups = Object.keys(tasksPlanned).reduce((accu, teamId) => {
        // Calculate the groups
        accu[teamId] = taskHelper.calculateTaskGroups(tasksPlanned[teamId]);
        let tasksForTeam = collapsedTaskIds[teamId] || [];
        // Check if a group is still fully closed. If not => Reopen.
        accu[teamId].forEach(taskGroup => {
          if (taskGroup.taskIds && !taskGroup.taskIds.every(id => tasksForTeam.includes(id))) {
            tasksForTeam = tasksForTeam.filter(id => !taskGroup.taskIds.includes(id));
          }
        });
        // Check if there are tasks in collapsedTaskIds that are no longer in a group and open them.
        tasksForTeam = tasksForTeam.filter(taskId => accu[teamId].flatMap(taskGroup => taskGroup.taskIds).includes(taskId));

        collapsedTaskIds[teamId] = tasksForTeam;
        return accu;
      }, {});

      return {
        ...state,
        isLoading: false,
        tasksPlanned,
        tasksUnplanned,
        taskGroups,
        collapsedTaskIds,
      };
    }
    case ActionType.GetTasksError:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case ActionType.EditTask:
      return {
        ...state,
        isEditTaskLoading: true,
        errorEditTask: null,
      };
    case ActionType.EditTaskSuccess:
      return {
        ...state,
        isEditTaskLoading: false,
      };
    case ActionType.EditTaskError:
      return {
        ...state,
        isEditTaskLoading: false,
        errorEditTask: action.payload.error,
      };
    case ActionType.PlanTask:
      return {
        ...state,
        isPlanTaskLoading: action.confirmed,
        errorPlanTask: null,
        loadingPlanTaskIds: [...state.loadingPlanTaskIds, action.payload.task.id],
      };
    case ActionType.PlanTaskSuccess:
    case ActionType.PlanTaskCancel:
      return {
        ...state,
        isPlanTaskLoading: false,
        loadingPlanTaskIds: state.loadingPlanTaskIds.filter(id => id !== action.payload.taskId),
      };
    case ActionType.PlanTaskError:
      return {
        ...state,
        isPlanTaskLoading: false,
        errorPlanTask: action.payload.error,
        loadingPlanTaskIds: state.loadingPlanTaskIds.filter(id => id !== action.payload.taskId),
      };
    case ActionType.RemoveTask:
      return {
        ...state,
        isRemoveTaskLoading: true,
        errorRemoveTask: null,
      };
    case ActionType.RemoveTaskSuccess:
      return {
        ...state,
        isRemoveTaskLoading: false,
      };
    case ActionType.RemoveTaskError:
      return {
        ...state,
        isRemoveTaskLoading: false,
        errorRemoveTask: action.payload.error,
      };
    case ActionType.EndTask:
      return {
        ...state,
        isEndTaskLoading: true,
        errorEndTask: null,
      };
    case ActionType.EndTaskSuccess:
      return {
        ...state,
        isEndTaskLoading: false,
      };
    case ActionType.EndTaskError:
      return {
        ...state,
        isEndTaskLoading: false,
        errorEndTask: action.payload.error,
      };
    case ActionType.StartTask:
      return {
        ...state,
        isStartTaskLoading: true,
        errorStartTask: null,
      };
    case ActionType.StartTaskSuccess:
      return {
        ...state,
        isStartTaskLoading: false,
      };
    case ActionType.StartTaskError:
      return {
        ...state,
        isStartTaskLoading: false,
        errorStartTask: action.payload.error,
      };
    case ActionType.ToggleTaskGroup: {
      const collapsedTaskIds = { ...state.collapsedTaskIds };
      let collapsedTaskIdsForTeam = collapsedTaskIds[action.payload.teamId];
      if (action.payload.isOpen) {
        collapsedTaskIdsForTeam =
          collapsedTaskIdsForTeam && collapsedTaskIdsForTeam.filter(id => !action.payload.taskIds.includes(id));
      } else {
        collapsedTaskIdsForTeam = (collapsedTaskIdsForTeam || []).concat(action.payload.taskIds);
      }
      collapsedTaskIds[action.payload.teamId] = collapsedTaskIdsForTeam;
      return {
        ...state,
        collapsedTaskIds,
      };
    }
    case ActionType.ToggleAllTaskGroups:
      return {
        ...state,
        collapsedTaskIds: Object.keys(state.collapsedTaskIds).reduce(
          (acc: Record<string, string[]>, teamId: string) => ({
            ...acc,
            [teamId]: action.payload.isOpen
              ? []
              : (state.taskGroups[teamId] || []).map(group => group.taskIds).flatMap(ids => ids),
          }),
          {},
        ),
      };
    case SidebarActionType.CloseSidebar:
      return {
        ...state,
        isEditTaskLoading: false,
        errorEditTask: null,
      };
    default:
      return state;
  }
}
