import React, { FC } from 'react';

import { translations } from '../../_translations';
import { formatTime } from '../../_utils/timeHelper';
import { Icon } from '../../_shared';
import { getIndicatorForTeamTaskState } from '../../_utils/taskHelper';
import { IIssue } from '../_models/issue';

import './issueItem.scss';

interface Props {
  issue: IIssue;
  onClick: () => void;
}

const IssueItem: FC<Props> = ({ issue, onClick }) => {
  const { icon: taskStateIcon, label: taskStateLabel } = getIndicatorForTeamTaskState(issue.teamTaskState);
  return (
    <button className="issue-item" onClick={onClick}>
      {!!(issue.attachments || []).length && (
        <div className="attachment-preview">
          <img src={`data:image/png;base64,${issue.attachments[0].content}`} alt="" />
        </div>
      )}
      <div>
        <h2>{translations.getLabel(issue.type)}</h2>
        <span className="created-at">{formatTime(issue.createdAt)}</span>
        <div className="issue-reporter">
          <div className="issue-reporter-info">
            <Icon name={taskStateIcon} size={1.6} />
            <span>{translations.getLabel(taskStateLabel)}</span>
          </div>
          <div className="issue-reporter-info">
            <Icon name="SvgTeam" size={1.6} />
            <span>{issue.team && issue.team.name}</span>
          </div>
        </div>
        {issue.description && <p className="description">{issue.description}</p>}
      </div>
      {!issue.isRead && <div className="unread-indicator" />}
    </button>
  );
};

export default IssueItem;
