import React, { FC } from 'react';

import { translations } from '../../_translations';
import { CleaningProcedureType } from '../_models/task';
import InputDropdown, { DropdownOption, InputDropdownProps } from '../../_shared/input/InputDropdown';

const CleaningProceduresDropdown: FC<InputDropdownProps> = props => {
  const options: DropdownOption[] = Object.values(CleaningProcedureType).map(value => ({
    text: translations.getLabel(`CLEANING_PROCEDURE_${value}`),
    value,
  }));

  return (
    <InputDropdown
      {...props}
      fluid
      label={translations.getLabel('EDIT_TASK_FIELD_CLEANINGPROCEDURE')}
      labelIcon="SvgClean"
      name="cleaningProcedure"
      options={options}
      placeholder={translations.getLabel('EDIT_TASK_FIELD_CLEANINGPROCEDURE_PLACEHOLDER')}
    />
  );
};

export default CleaningProceduresDropdown;
