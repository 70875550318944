import { ActionType, Actions } from './actions';

export interface PlanningState {
  currentDate?: Date;
}

const initialState: PlanningState = {
  currentDate: null,
};

export default function reducer(state = initialState, action: Actions): PlanningState {
  switch (action.type) {
    case ActionType.SetCurrentDate:
      return {
        ...state,
        currentDate: action.payload.date,
      };
    default:
      return state;
  }
}
