import React, { FC, RefObject } from 'react';

import { useSelector } from 'react-redux';
import TimeLine from '../grid/TimeLine';
import { timeHelper } from '../../_utils';

import { tasksSelectors } from '../../_store/selectors';
import './timeIndicator.scss';

interface Props {
  title: string;
  isUnplanned?: boolean;
  timeRef?: RefObject<HTMLDivElement>;
  isAtStartPosition?: boolean;
}

const TimeIndicator: FC<Props> = ({ title, isUnplanned, timeRef, isAtStartPosition }) => {
  const tasksUnplannedLength = useSelector(tasksSelectors.tasksUnplanned).length;

  return (
    <div className="time-indicator">
      <div className="title-wrapper">
        <span>{title.toUpperCase()}</span>
        {isUnplanned && <span>{`(${tasksUnplannedLength})`}</span>}
      </div>
      <div className="timeframe-wrapper" ref={timeRef}>
        {isAtStartPosition &&
          timeHelper.getQuartersOfTheDay().map((timeFrame, index) => (
            <div className="timeframe" key={timeHelper.formatTime(timeFrame)}>
              {!!index && <span>{timeHelper.formatTime(timeFrame)}</span>}
              <div className="timeframe-ticks" />
            </div>
          ))}
        <TimeLine />
      </div>
    </div>
  );
};

export default TimeIndicator;
