import { Action } from 'redux';

import { IModalData, IConfirmationModalData } from '../_models';

export enum ActionType {
  ShowConfirmationModal = '[Modal] ShowConfirmationModal',
  ShowWarningModal = '[Modal] ShowWarningModal',
  CloseModal = '[Modal] CloseModal',
}

export class ShowConfirmationModalAction implements Action<ActionType> {
  readonly type = ActionType.ShowConfirmationModal;
  constructor(public payload: { data: IConfirmationModalData }) {}
}

export class ShowWarningModalAction implements Action<ActionType> {
  readonly type = ActionType.ShowWarningModal;
  constructor(public payload: { data: IModalData }) {}
}

export class CloseModalAction implements Action<ActionType> {
  readonly type = ActionType.CloseModal;
}

export type Actions = ShowConfirmationModalAction | ShowWarningModalAction | CloseModalAction;
