import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';
import { Button as SemanticButton, Loader } from 'semantic-ui-react';

import './button.scss';

// TODO: Use HTMLProps<HTMLButtonElement> for Props if not using semantic anymore

interface Props {
  type?: 'button' | 'submit' | 'reset';
  isTextLink?: boolean;
  children: ReactNode;
  onClick?: () => void;
  primary?: boolean;
  negative?: boolean;
  backNavigation?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

const noop = () => {};

const Button: FC<Props> = ({
  type,
  isTextLink,
  children,
  onClick = noop,
  negative,
  disabled,
  loading,
  backNavigation,
  ...props
}) => {
  if (isTextLink) {
    const className = classnames('text-link', { negative, loading, 'back-navigation': backNavigation });
    return (
      <button type={type} className={className} onClick={onClick} disabled={disabled || loading}>
        <>
          {children}
          {loading && <Loader active inline inverted />}
        </>
      </button>
    );
  }
  return (
    <SemanticButton type={type} onClick={onClick} negative={negative} disabled={disabled || loading} loading={loading} {...props}>
      <div className="semantic-button-content">{children}</div>
    </SemanticButton>
  );
};

Button.defaultProps = {
  type: 'button',
};

export default Button;
