import React, { FC, ReactNode, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useToggle, useActionPromise } from '../../../_hooks';
import { translations } from '../../../_translations';
import { Button, Modal } from '../../../_shared';
import TeamsDropdown from '../../../teams/teamsDropdown/TeamsDropdown';
import { ITask } from '../../_models/task';
import { PlanTaskAction } from '../../_store/actions';
import { tasksSelectors } from '../../../_store/selectors';

import '../moveTaskModal/moveTaskModal.scss';

interface Props {
  task: ITask;
  trigger: ReactNode;
  teamIdToUnplan?: string;
  actionAfterClose?: () => void;
}

const ChangeTaskMainTeamModal: FC<Props> = ({ trigger, task, teamIdToUnplan, actionAfterClose }) => {
  const isLoading = useSelector(tasksSelectors.isPlanTaskLoading);
  const error = useSelector(tasksSelectors.errorPlanTask);
  const dispatch = useDispatch();

  const [isModalVisible, toggleModal] = useToggle(false);
  const [isError, toggleError] = useToggle(false);
  const [selectedTeamId, setSelectedTeamId] = useState('');

  useActionPromise(isLoading, actionAfterClose || toggleModal, toggleError, error);

  const otherTeams = task.teams.filter(team => !team.isMainTeam).map(team => team.id);

  return (
    <Modal className="plan-task-modal" open={isModalVisible} onOpen={toggleModal} onClose={toggleModal} trigger={trigger}>
      <Modal.Header>{translations.getLabel('MODAL_MAIN_TEAM_TITLE')}</Modal.Header>
      <Modal.Content>
        <p>{translations.getLabel('MODAL_MAIN_TEAM_BODY')}</p>
        <TeamsDropdown
          error={isError}
          errorMessage={translations.getLabel('SCHEDULE_ERROR')}
          filter={team => otherTeams.includes(team.id)}
          onChange={(value: string) => {
            toggleError(false);
            setSelectedTeamId(value);
          }}
          value={selectedTeamId}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button isTextLink onClick={toggleModal}>
          {translations.getLabel('CANCEL')}
        </Button>
        <Button
          primary
          loading={isLoading}
          onClick={() =>
            dispatch(
              new PlanTaskAction({
                task,
                unplannedTeamIds: [teamIdToUnplan],
                mainTeamId: selectedTeamId,
                startTime: task.startTime,
              }),
            )
          }
        >
          {translations.getLabel('EDIT')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ChangeTaskMainTeamModal;
