import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import Planning from '../planning/Planning';
import ActivityLog from '../activityLog/ActivityLog';
import { Menu } from '../_shared';
import Sidebar from '../sidebar/Sidebar';
import { ReportsLog } from '../reports/ReportsLog';

const AuthorizedLayout = () => {
  return (
    <div className="App">
      <Menu />
      <Sidebar />
      <Switch>
        <Route path="/planning" component={Planning} />
        <Route path="/activity-log" component={ActivityLog} />
        <Route path="/reports" component={ReportsLog} />
        <Redirect to="/planning" />
      </Switch>
    </div>
  );
};

export default AuthorizedLayout;
