import { Action } from 'redux';

import { IActivityLogItem } from '../_models/activityLogItem';
import { ApiError } from '../../_http';
import { IActivityLogFilter } from '../_models/activityLogFilter';
import { HttpMetadataPagingResponse } from '../../_http/HttpMetadata';

export enum ActionType {
  GetActivityLog = '[ActivityLog] GetActivityLog',
  GetActivityLogSuccess = '[ActivityLog] GetActivityLogSuccess',
  GetActivityLogError = '[ActivityLog] GetActivityLogError',
  SetActivityLogFilter = '[ActivityLog] SetActivityLogFilter',
  ExportActivityLog = '[ActivityLog] ExportActivityLog',
  ExportActivityLogSuccess = '[ActivityLog] ExportActivityLogSuccess',
  ExportActivityLogError = '[ActivityLog] ExportActivityLogError',
}

export class GetActivityLogAction implements Action<ActionType> {
  readonly type = ActionType.GetActivityLog;
  constructor(public payload?: { offset: number }) {}
}

export class GetActivityLogSuccessAction implements Action<ActionType> {
  readonly type = ActionType.GetActivityLogSuccess;
  constructor(public payload: { offset: number; data: IActivityLogItem[]; meta: HttpMetadataPagingResponse }) {}
}

export class GetActivityLogErrorAction implements Action<ActionType> {
  readonly type = ActionType.GetActivityLogError;
  constructor(public payload: { error: ApiError }) {}
}

export class SetActivityLogFilterAction implements Action<ActionType> {
  readonly type = ActionType.SetActivityLogFilter;
  constructor(public payload: { filter: IActivityLogFilter }) {}
}

export class ExportActivityLogAction implements Action<ActionType> {
  readonly type = ActionType.ExportActivityLog;
}

export class ExportActivityLogSuccessAction implements Action<ActionType> {
  readonly type = ActionType.ExportActivityLogSuccess;
  constructor(public payload: { name: string; file: Blob }) {}
}

export class ExportActivityLogErrorAction implements Action<ActionType> {
  readonly type = ActionType.ExportActivityLogError;
  constructor(public payload: { error: ApiError }) {}
}

export type Actions =
  | GetActivityLogAction
  | GetActivityLogSuccessAction
  | GetActivityLogErrorAction
  | SetActivityLogFilterAction
  | ExportActivityLogAction
  | ExportActivityLogSuccessAction
  | ExportActivityLogErrorAction;
