import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { GRID_ROW_HEIGHT, GRID_ROW_HEIGHT_UNPLANNED, GRID_ROW_HEIGHT_UNPLANNED_LOADING } from '../../_constants';
import { ITeam, ILoadingTeam } from '../../teams/_models/team';
import DraggableTask from '../task/DraggableTask';
import TaskGroup from '../taskGroup/TaskGroup';
import { taskHelper } from '../../_utils';
import constants from '../../_styles/constants.module.scss';
import { planningSelectors, tasksSelectors } from '../../_store/selectors';
import { AppState } from '../../_store/rootReducer';
import NotWorkingBlock from './NotWorkingBlock';

interface Props {
  isUnplanned?: boolean;
  team?: ITeam | ILoadingTeam;
  isLoading?: boolean;
}

const TasksRow: FC<Props> = ({ isUnplanned, isLoading, team }) => {
  const teamId = team && team.id;
  const currentDate = useSelector(planningSelectors.currentDate);
  const tasks = useSelector(
    isUnplanned ? tasksSelectors.tasksUnplanned : (state: AppState) => tasksSelectors.getTasksForTeam(state, teamId),
  );
  const taskGroups = useSelector((state: AppState) => tasksSelectors.getTaskGroupsForTeam(state, teamId));
  const collapsedTaskIds = useSelector((state: AppState) => tasksSelectors.collapsedTaskIdsForTeam(state, teamId));

  const rowHeight = `${
    isUnplanned ? (isLoading ? GRID_ROW_HEIGHT_UNPLANNED_LOADING : GRID_ROW_HEIGHT_UNPLANNED) : GRID_ROW_HEIGHT
  }rem`;
  const showNotWorkingBlock = team && !isLoading;

  // Stack the tasks
  const taskRows = taskHelper.reduceTasks(tasks);
  // Remove the tasks that shouldn't be shown (if a taskGroup is collapsed)
  const filteredTasks = (taskRows || [])
    .map(taskRow => taskRow.filter(task => !collapsedTaskIds.includes(task.id)))
    .filter(taskRow => taskRow.length);

  return (
    <>
      {!tasks || !tasks.length ? (
        <div className="tasks-row" style={{ height: rowHeight }}>
          {showNotWorkingBlock && <NotWorkingBlock team={team as ITeam} currentDate={currentDate} />}
        </div>
      ) : (
        <>
          {taskGroups && taskGroups.length >= 1 && (
            <div
              className="task-group-row"
              style={{ height: filteredTasks.length ? `${constants.taskGroupHeight}rem` : rowHeight }}
            >
              {taskGroups.map((taskGroup, i) => {
                return <TaskGroup key={i} teamId={team.id} taskGroup={taskGroup} />;
              })}
            </div>
          )}
          {filteredTasks.map((taskRow, i) => (
            <div className="tasks-row" key={`tasks-${i}`} style={{ height: rowHeight }}>
              {showNotWorkingBlock && <NotWorkingBlock team={team as ITeam} currentDate={currentDate} />}
              {taskRow.map(task => (
                <DraggableTask key={task.id} isLoading={isLoading} task={task} teamId={team && team.id} index={i} />
              ))}
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default TasksRow;
