export enum SessionStorageKey {
  ApiKey = 'API_KEY',
  GridHeight = 'GRID_HEIGHT',
}

export const getApiKey = (): string => sessionStorage.getItem(SessionStorageKey.ApiKey);
export const setApiKey = (value: string) => sessionStorage.setItem(SessionStorageKey.ApiKey, value);
export const hasApiKey = (): boolean => !!getApiKey();
export const removeApiKey = () => sessionStorage.removeItem(SessionStorageKey.ApiKey);

export const getGridHeight = (): number => parseFloat(sessionStorage.getItem(SessionStorageKey.GridHeight));
export const setGridHeight = (value: number) => sessionStorage.setItem(SessionStorageKey.GridHeight, `${value}`);
export const hasGridHeight = (): boolean => !!getGridHeight();
export const removeGridHeight = () => sessionStorage.removeItem(SessionStorageKey.GridHeight);
