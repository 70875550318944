import React, { FC, ReactNode } from 'react';
import { Badge } from '../../_shared';

import './sidebarHeader.scss';

interface Props {
  title: string;
  badges?: string[];
  children?: ReactNode;
}

const SidebarHeader: FC<Props> = ({ title, badges, children }) => {
  return (
    <div className="sidebar-header">
      <div>
        <h1>{title}</h1>
        {badges.map(badge => (
          <Badge key={badge} label={badge} />
        ))}
      </div>
      {children}
    </div>
  );
};

SidebarHeader.defaultProps = {
  badges: [],
};

export default SidebarHeader;
