import React, { FC } from 'react';

import { normalizeTime } from '../../_utils/normalize';
import InputField, { InputFieldProps } from './InputField';

const InputFieldTime: FC<InputFieldProps> = props => (
  <InputField {...props} type="text" normalize={normalizeTime} placeholder="00:00" />
);

export default InputFieldTime;
