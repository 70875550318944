import React, { FC, useRef, useMemo, ReactNode } from 'react';
import classNames from 'classnames';

import TimeIndicator from '../timeIndicator/TimeIndicator';
import { ITeam } from '../../teams/_models/team';
import { useSyncScroll } from '../../_hooks';
import TimeLine from './TimeLine';
import TasksRow from './TasksRow';
import DroppableTasksRow from './DroppableTasksRow';
import TeamRow from './TeamRow';

import './grid.scss';

interface Props {
  teams?: ITeam[];
  title?: string;
  isUnplanned?: boolean;
  isLoading?: boolean;
  isAtStartPosition?: boolean;
  isResizing?: boolean;
}

const loadingTeams = Array.from(Array(12).keys()).map((_, id) => ({ id: `${id}` }));

// Helper function to create the rows base on teams and tasks
const getRows = (isUnplanned: boolean, teams: ITeam[], isLoading?: boolean): ReactNode[][] => {
  if (!isUnplanned && isLoading) {
    return loadingTeams.reduce(
      (rows: ReactNode[][], team) => {
        rows[0].push(<TeamRow isLoading key={team.id} />);
        rows[1].push(<TasksRow isLoading key={`task-${team.id}`} team={team} />);
        return rows;
      },
      [[], []],
    );
  }

  if (!isUnplanned) {
    if (!teams && !isLoading) return [[], []];
    return teams.reduce(
      (rows: ReactNode[][], team) => {
        rows[0].push(<TeamRow key={team.id} team={team} />);
        rows[1].push(<DroppableTasksRow key={`task-${team.id}`} team={team} />);
        return rows;
      },
      [[], []],
    );
  } else {
    const teamRow = <TeamRow isLoading={isLoading} isUnplanned key="team-unplanned" />;
    const tasksRow = <DroppableTasksRow isLoading={isLoading} isUnplanned key="tasks-unplanned" />;
    return [[teamRow], [tasksRow]];
  }
};

// Actual grid component
const Grid: FC<Props> = ({ teams, title, isLoading, isUnplanned, isAtStartPosition, isResizing }) => {
  const teamRef = useRef<HTMLDivElement>(null);
  const tasksRef = useRef<HTMLDivElement>(null);

  const refs = useRef([teamRef, tasksRef]);
  useSyncScroll(refs, { vertical: true, horizontal: false });

  const [teamRows, taskRows] = useMemo(() => getRows(isUnplanned, teams, isLoading), [isUnplanned, teams, isLoading]);
  return (
    <div className={classNames('grid', { 'is-resizing': isResizing })}>
      <TimeIndicator title={title} isAtStartPosition={isAtStartPosition} isUnplanned={isUnplanned} />
      <div className="grid-content">
        <div className="teams-column" ref={teamRef}>
          {teamRows}
        </div>
        <div className="tasks-column" ref={tasksRef}>
          <div className="tasks-content">
            {' '}
            {/* Extra wrapper so the timeline will have correct height when scrolling */}
            <TimeLine />
            {taskRows}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Grid;
