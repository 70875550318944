import React, { FC } from 'react';
import Icon from '../icon/Icon';

const noop = () => {};
interface Props {
  closeToast?: typeof noop;
}

const CloseButton: FC<Props> = ({ closeToast }) => {
  return <Icon onClick={closeToast} name="SvgClose" size={2.4} />;
};

CloseButton.defaultProps = {
  closeToast: noop,
};

export default CloseButton;
