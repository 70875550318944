import { ITask } from '../_models/task';
import { IEditTask } from '../_models/editTask';
import { dateFromString, ISOStringFromDate } from '../../_utils/timeHelper';
import { HttpClient } from '../../_http';

export enum TasksApiError {
  InvalidPlanningError = 'INVALID_PLANNING_ERROR',
}

export function getTasks(from: Date, to: Date): Promise<ITask[]> {
  return HttpClient.get<ITask[]>('tasks', { from: ISOStringFromDate(from), to: ISOStringFromDate(to) });
}

export function editTask(data: IEditTask, taskId: string): Promise<void> {
  function composeDate(date: string, time: string): string {
    if (!date || !time) return null;
    return ISOStringFromDate(dateFromString(`${date} ${time}`, 'dd/MM/yyyy HH:mm'));
  }

  const payload = {
    name: data.name,
    type: data.type,
    startTime: composeDate(data.startDate, data.startTime),
    endTime: composeDate(data.endDate, data.endTime),
    arrivalFlightNumber: data.arrivalFlightNumber,
    imat: data.imat,
    departureTime: composeDate(data.departureDate, data.departureTime),
    arrivalTime: composeDate(data.arrivalDate, data.arrivalTime),
    description: data.description,
    location: data.location,
    cleaningProcedure: data.cleaningProcedure,
    skippable: data.skippable,
  };

  if (!taskId) return HttpClient.post('tasks', HttpClient.parseRequestPayload(payload));
  return HttpClient.put(`tasks/${taskId}`, HttpClient.parseRequestPayload(payload));
}

export function planTask(
  task: ITask,
  plannedTeamId?: string,
  unplannedTeamIds?: string[],
  mainTeamId?: string,
  startTime?: string,
): Promise<void> {
  const teams = task.teams
    .map(team => team.id)
    .concat([plannedTeamId])
    .filter(id => !!id && !(unplannedTeamIds || []).includes(id));
  return HttpClient.post(`tasks/${task.id}/plan`, {
    teams,
    mainTeam: mainTeamId || (teams.length > 0 && task.teams.find(team => team.isMainTeam).id) || null,
    startTime,
  });
}

export function removeTask(taskId: string): Promise<void> {
  return HttpClient.delete(`tasks/${taskId}`);
}

export function endTask(taskId: string): Promise<void> {
  return HttpClient.post(`tasks/${taskId}/end`);
}

export function startTask(taskId: string): Promise<void> {
  return HttpClient.post(`tasks/${taskId}/start`);
}
