import React, { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { max, min } from 'date-fns';

import { useModal } from '../../../_hooks';
import { translations } from '../../../_translations';
import { Button, Modal, ErrorMessage } from '../../../_shared';
import { ITeam } from '../../_models/team';
import EmployeesSearch from '../../employeesSearch/EmployeesSearch';
import { IEmployee } from '../../_models/employee';
import { PlanTeamAction } from '../../_store/actions';
import { teamsSelectors } from '../../../_store/selectors';
import ChangeShiftModal from '../changeShiftModal/ChangeShiftModal';
import { ITeamMember } from '../../_models/teamMember';
import { dateFromISOString, ISOStringFromDate } from '../../../_utils/timeHelper';

import './addTeamMemberModal.scss';

interface Props {
  team: ITeam;
  hideModal: () => void;
  isPdaHolder?: boolean;
}

const mapEmployeeToTeamMember = (employee: IEmployee, isPdaUser: boolean, team: ITeam): ITeamMember => ({
  id: employee.id,
  name: employee.name,
  shiftStart: isPdaUser
    ? employee.shiftStart
    : ISOStringFromDate(max([dateFromISOString(employee.shiftStart), dateFromISOString(team.validFrom)])),
  shiftEnd: isPdaUser
    ? employee.shiftEnd
    : ISOStringFromDate(min([dateFromISOString(employee.shiftEnd), dateFromISOString(team.validTo)])),
  isPdaUser,
});

const AddTeamMemberModal: FC<Props> = ({ team, hideModal, isPdaHolder }) => {
  const isGetEmployeesLoading = useSelector(teamsSelectors.isEditTeamLoading);
  const errorGetEmployees = useSelector(teamsSelectors.errorGetEmployees);
  const isPlanTeamLoading = useSelector(teamsSelectors.isPlanTeamLoading);
  const employees = useSelector(teamsSelectors.employees);
  const dispatch = useDispatch();

  const [selectedEmployee, selectEmployee] = useState<IEmployee>();

  const plan = (member: ITeamMember) =>
    dispatch(new PlanTeamAction({ team, member, pdaUser: isPdaHolder ? selectedEmployee.id : '' }));
  const [renderShiftModal, showShiftModal] = useModal(
    modalProps => (
      <ChangeShiftModal
        team={team}
        teamMember={mapEmployeeToTeamMember(selectedEmployee, isPdaHolder, team)}
        plan={plan}
        {...modalProps}
      />
    ),
    null,
    hideModal,
  );

  const getEmployees = () => {
    if (!employees || !team) return [];
    return employees.filter(employee => !team.members.some(member => member.id === employee.id));
  };

  return (
    <>
      <Modal className="add-team-member-modal" open onClose={hideModal}>
        <Modal.Header>{translations.getLabel(isPdaHolder ? 'ADD_PDA_HOLDER' : 'ADD_TEAM_MEMBER')}</Modal.Header>
        <Modal.Content>
          <EmployeesSearch employees={getEmployees()} onSelect={selectEmployee} isLoading={isGetEmployeesLoading} />
          <ErrorMessage isVisible={!!errorGetEmployees}>{translations.getLabel('GET_EMPLOYEES_ERROR')}</ErrorMessage>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={hideModal} isTextLink>
            {translations.getLabel('CLOSE')}
          </Button>
          <Button primary onClick={showShiftModal} disabled={!selectedEmployee} loading={isPlanTeamLoading}>
            {translations.getLabel('ADD')}
          </Button>
        </Modal.Actions>
      </Modal>
      {renderShiftModal()}
    </>
  );
};

export default AddTeamMemberModal;
