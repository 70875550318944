import React, { FC, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isBefore, startOfDay, endOfDay } from 'date-fns';

import { translations } from '../_translations';
import { ISOStringFromDate, dateFromString } from '../_utils/timeHelper';
import { Button, InputCheckbox, InputFieldDate } from '../_shared';
import { reportsLogSelectors } from '../_store/selectors';
import { TReportsLogFilter } from './_models/reportsLogFilter';
import { ExportReportsLogAction } from './_store/actions';

interface Props {
  setFilter: (partialFilter: TReportsLogFilter) => void;
}

export const ReportsLogFilter: FC<Props> = ({ setFilter }) => {
  const isExporting = useSelector(reportsLogSelectors.isExporting);
  const dispatch = useDispatch();

  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');

  const dateFromInputValueFrom = (value: string): Date => startOfDay(dateFromString(value));
  const dateFromInputValueTo = (value: string): Date => endOfDay(dateFromString(value));

  const canApplyDateFilters = useCallback(() => {
    if (!fromDate || !toDate) return false;
    if (isBefore(dateFromInputValueTo(toDate), dateFromInputValueFrom(fromDate))) return false;
    return true;
  }, [toDate, fromDate]);

  const applyDateFilters = () =>
    setFilter({ from: ISOStringFromDate(dateFromInputValueFrom(fromDate)), to: ISOStringFromDate(dateFromInputValueTo(toDate)) });

  const exportTimeframe = () => {
    applyDateFilters();
    dispatch(new ExportReportsLogAction());
  };

  return (
    <div className="filter-header">
      <div>
        <span className="input-label">{translations.getLabel('ACTIVITY_LOG_FILTER_DATE_FROM')}</span>
        <InputFieldDate onChange={(value: string) => setFromDate(value)} value={fromDate} />
      </div>
      <div>
        <span className="input-label">{translations.getLabel('ACTIVITY_LOG_FILTER_DATE_TO')}</span>
        <InputFieldDate onChange={(value: string) => setToDate(value)} value={toDate} />
      </div>

      <div>
        <Button primary onClick={applyDateFilters} disabled={!canApplyDateFilters()}>
          {translations.getLabel('APPLY')}
        </Button>
        <Button primary onClick={exportTimeframe} loading={isExporting} disabled={!canApplyDateFilters()}>
          {translations.getLabel('EXPORT')}
        </Button>
      </div>
      <InputCheckbox
        label={translations.getLabel('REPORTS_FILTER_REMARKS')}
        name="hasRemarks"
        onChange={value => setFilter({ hasRemarks: value || undefined })}
      />
    </div>
  );
};
