import { Action } from 'redux';

import { TReportsLogItem } from '../_models/reportsLogItem';
import { ApiError } from '../../_http';
import { TReportsLogFilter } from '../_models/reportsLogFilter';
import { HttpMetadataPagingResponse } from '../../_http/HttpMetadata';

export enum ActionType {
  GetReportsLog = '[ReportsLog] GetReportsLog',
  GetReportsLogSuccess = '[ReportsLog] GetReportsLogSuccess',
  GetReportsLogError = '[ReportsLog] GetReportsLogError',
  SetReportsLogFilter = '[ReportsLog] SetReportsLogFilter',
  ExportReportsLog = '[ReportsLog] ExportReportsLog',
  ExportReportsLogSuccess = '[ReportsLog] ExportReportsLogSuccess',
  ExportReportsLogError = '[ReportsLog] ExportReportsLogError',
}

export class GetReportsLogAction implements Action<ActionType> {
  readonly type = ActionType.GetReportsLog;
  constructor(public payload?: { offset: number }) {}
}

export class GetReportsLogSuccessAction implements Action<ActionType> {
  readonly type = ActionType.GetReportsLogSuccess;
  constructor(public payload: { offset: number; data: TReportsLogItem[]; meta: HttpMetadataPagingResponse }) {}
}

export class GetReportsLogErrorAction implements Action<ActionType> {
  readonly type = ActionType.GetReportsLogError;
  constructor(public payload: { error: ApiError }) {}
}

export class SetReportsLogFilterAction implements Action<ActionType> {
  readonly type = ActionType.SetReportsLogFilter;
  constructor(public payload: { filter: TReportsLogFilter }) {}
}

export class ExportReportsLogAction implements Action<ActionType> {
  readonly type = ActionType.ExportReportsLog;
}

export class ExportReportsLogSuccessAction implements Action<ActionType> {
  readonly type = ActionType.ExportReportsLogSuccess;
  constructor(public payload: { name: string; file: Blob }) {}
}

export class ExportReportsLogErrorAction implements Action<ActionType> {
  readonly type = ActionType.ExportReportsLogError;
  constructor(public payload: { error: ApiError }) {}
}

export type Actions =
  | GetReportsLogAction
  | GetReportsLogSuccessAction
  | GetReportsLogErrorAction
  | SetReportsLogFilterAction
  | ExportReportsLogAction
  | ExportReportsLogSuccessAction
  | ExportReportsLogErrorAction;
