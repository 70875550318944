import React, { FC } from 'react';
import { Table, Loader } from 'semantic-ui-react';

import { translations } from '../_translations';
import { formatDate, dateFromISOString } from '../_utils/timeHelper';
import { useTableSort } from '../_hooks';
import { HttpSortDirection } from '../_http/HttpMetadata';
import { Icon } from '../_shared';
import { TReportsLogItem } from './_models/reportsLogItem';
import { TReportsLogFilter } from './_models/reportsLogFilter';

const TABLE_CELL_NO_DATA_SIGN = '/';

interface TableColumn {
  name: string;
  label: string;
  sortable?: boolean;
}

const columns: TableColumn[] = [
  { name: 'timestamp', label: 'REPORTS_TABLE_COLUMN_DATE', sortable: true },
  {
    name: 'teamName',
    label: 'REPORTS_TABLE_COLUMN_TEAM_NAME',
    sortable: true,
  },
  { name: 'teamMembers', label: 'REPORTS_TABLE_COLUMN_TEAM_MEMBERS' },
  { name: 'type', label: 'REPORTS_TABLE_COLUMN_REPORT_TYPE', sortable: true },
  {
    name: 'period',
    label: 'REPORTS_TABLE_COLUMN_PERIOD',
    sortable: true,
  },
  { name: 'subject', label: 'REPORTS_TABLE_COLUMN_LICENSE_PLATE', sortable: true },
  { name: 'state', label: 'REPORTS_TABLE_COLUMN_VEHICLE_STATE', sortable: true },
  { name: 'remarks', label: 'REPORTS_TABLE_COLUMN_REMARKS' },
];

interface Props {
  data: TReportsLogItem[];
  isLoading: boolean;
  setFilter: (partialFilter: TReportsLogFilter) => void;
}

export const ReportsLogTable: FC<Props> = ({ data, isLoading, setFilter }) => {
  const handleSortColumnChange = (column: string, direction: HttpSortDirection) =>
    setFilter({ sortBy: column, sortDirection: direction });
  const { onChangeSortColumn, sortDirectionForColumn } = useTableSort(handleSortColumnChange);

  function renderRow(item: TReportsLogItem) {
    return (
      <Table.Row key={item.id}>
        <Table.Cell>{formatDate(dateFromISOString(item.timestamp))}</Table.Cell>
        <Table.Cell>{item.teamName}</Table.Cell>
        <Table.Cell>{item.teamMembers.join('\n')}</Table.Cell>
        <Table.Cell>{translations.getLabel(`REPORTS_TABLE_REPORT_TYPE_${item.type}`)}</Table.Cell>
        <Table.Cell>{translations.getLabel(`REPORTS_TABLE_REPORT_PERIOD_${item.period}`)}</Table.Cell>
        <Table.Cell>{item.subject || TABLE_CELL_NO_DATA_SIGN}</Table.Cell>
        <Table.Cell className="state-cell">
          {item.state ? <Icon name="SvgCheck" size={2.4} /> : <Icon className="red" name="SvgAlertCircle" size={2.4} />}
        </Table.Cell>
        <Table.Cell className="description-cell">{item.remarks || TABLE_CELL_NO_DATA_SIGN}</Table.Cell>
      </Table.Row>
    );
  }

  function renderHeaderColumn(column: TableColumn) {
    return (
      <Table.HeaderCell
        key={column.name}
        name={column.name}
        onClick={column.sortable ? onChangeSortColumn : null}
        sorted={column.sortable ? sortDirectionForColumn(column.name) : null}
      >
        {translations.getLabel(column.label)}
      </Table.HeaderCell>
    );
  }

  return (
    <Table fixed sortable>
      <Table.Header>
        <Table.Row>{columns.map(column => renderHeaderColumn(column))}</Table.Row>
      </Table.Header>
      <Table.Body>
        {(data || []).map(item => renderRow(item))}
        {isLoading && (
          <Table.Row>
            <Table.Cell colSpan={columns.length} textAlign="center">
              <Loader size="large" inline active />
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};
