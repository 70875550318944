import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ISidebarData } from '../_models';
import SidebarHeader from '../sidebarHeader/SidebarHeader';
import { Button, Icon } from '../../_shared';
import { ShowSidebarTaskIssueDetailAction, ShowSidebarTaskDetailAction } from '../_store/actions';
import { issuesSelectors, tasksSelectors } from '../../_store/selectors';
import { translations } from '../../_translations';
import IssueItem from '../../issues/issue/IssueItem';
import { AppState } from '../../_store/rootReducer';

import './taskIssues.scss';

interface Props {
  data: ISidebarData;
}

const TaskIssues: FC<Props> = ({ data }) => {
  const task = useSelector((state: AppState) => tasksSelectors.getTask(state, data.taskId));
  const issues = useSelector(issuesSelectors.getIssues);
  const dispatch = useDispatch();

  return (
    <div className="sidebar-inner task-issues">
      <div className="sidebar-header-buttons left">
        <Button isTextLink backNavigation onClick={() => dispatch(new ShowSidebarTaskDetailAction({ data }))}>
          <Icon name="SvgChevronLeft" size={2} />
          <span>{translations.getLabel('BACK_TO_TASK_DETAIL')}</span>
        </Button>
      </div>
      <SidebarHeader title={`${task.name} - ${translations.getLabel('ISSUES')}`} />
      <div className="sidebar-content">
        {(issues || [])
          .sort((i1, i2) => new Date(i2.createdAt).getTime() - new Date(i1.createdAt).getTime())
          .map(issue => (
            <IssueItem
              key={issue.id}
              issue={issue}
              onClick={() => dispatch(new ShowSidebarTaskIssueDetailAction({ data: { ...data, issueId: issue.id } }))}
            />
          ))}
      </div>
    </div>
  );
};

export default TaskIssues;
