import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { exhaustMap, map, catchError } from 'rxjs/operators';

import { reportsLogSelectors } from '../../_store/selectors';
import { reportsLogApi } from '../../_store/api';
import { downloadHelper } from '../../_utils';
import {
  ActionType,
  GetReportsLogAction,
  ExportReportsLogSuccessAction,
  GetReportsLogSuccessAction,
  GetReportsLogErrorAction,
  ExportReportsLogErrorAction,
} from './actions';

export const getReportsLogEpic$: Epic = (action$, state$) =>
  action$.ofType(ActionType.GetReportsLog).pipe(
    exhaustMap(({ payload }: GetReportsLogAction) => {
      const filter = reportsLogSelectors.filter(state$.value);
      return from(reportsLogApi.getReportsLog(payload && payload.offset, filter)).pipe(
        map(
          result => new GetReportsLogSuccessAction({ offset: payload && payload.offset, data: result.data, meta: result.meta }),
        ),
        catchError(error => of(new GetReportsLogErrorAction({ error }))),
      );
    }),
  );

export const setReportsLogFilterEpic$: Epic = action$ =>
  action$.ofType(ActionType.SetReportsLogFilter).pipe(map(() => new GetReportsLogAction()));

export const exportReportsLogEpic$: Epic = (action$, state$) =>
  action$.ofType(ActionType.ExportReportsLog).pipe(
    exhaustMap(() => {
      const filter = reportsLogSelectors.filter(state$.value);
      return from(reportsLogApi.exportReportsLog(filter)).pipe(
        map((file: Blob) => new ExportReportsLogSuccessAction({ name: 'check_reports_export.xls', file })),
        catchError(error => of(new ExportReportsLogErrorAction({ error }))),
      );
    }),
  );

export const exportReportsLogSuccessEpic$: Epic = action$ =>
  action$.ofType(ActionType.ExportReportsLogSuccess).pipe(
    exhaustMap(({ payload }: ExportReportsLogSuccessAction) => {
      downloadHelper.downloadFile(payload);
      return of();
    }),
  );

const ReportsEpics = [getReportsLogEpic$, setReportsLogFilterEpic$, exportReportsLogEpic$, exportReportsLogSuccessEpic$];

export default ReportsEpics;
