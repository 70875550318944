import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useDrop } from 'react-dnd';
import classnames from 'classnames';

import { taskMainTeamId } from '../_models/rules';
import { ITeam, ILoadingTeam } from '../../teams/_models/team';
import { canTeamExecuteTask } from '../../teams/_models/rules';
import { DragType } from '../../dragDrop/_model';
import { IDragDropTask, IDropTasksRowCollection } from '../../dragDrop/_model/DragDropTask';
import { PlanTaskAction } from '../_store/actions';
import TasksRow from './TasksRow';

interface Props {
  isUnplanned?: boolean;
  team?: ITeam | ILoadingTeam;
  isLoading?: boolean;
}

const DroppableTasksRow: FC<Props> = ({ isUnplanned, team, isLoading }) => {
  const dispatch = useDispatch();

  const [{ isOver, canDrop }, dropRef] = useDrop<IDragDropTask, {}, IDropTasksRowCollection>({
    accept: DragType.Task,
    drop: (item: IDragDropTask) => {
      const newTeamId = team && team.id;
      const oldTeamId = item.teamId;
      const oldMainTeamId = taskMainTeamId(item.task);
      dispatch(
        new PlanTaskAction({
          task: item.task,
          plannedTeamId: newTeamId,
          unplannedTeamIds: oldTeamId ? [oldTeamId] : null,
          mainTeamId: oldTeamId === oldMainTeamId ? newTeamId : oldMainTeamId,
          startTime: item.task.startTime,
        }),
      );
      return null;
    },
    canDrop: (item: IDragDropTask) => {
      // If this is a planned row, we perform the check if we can plan a task for this team
      if (!isUnplanned && canTeamExecuteTask(team as ITeam, item.task)) {
        return true;
      }
      // If this is the unplanned row, we can only drop a task that has a teamId (is already planned) and has only 1 team
      if (isUnplanned && item.task.teams.length === 1) {
        return true;
      }
      return false;
    },
    collect: (monitor): IDropTasksRowCollection => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const className = classnames('tasks-row-container', { 'drag-over': isOver, 'no-drop': !canDrop });
  return (
    <div className={className} ref={dropRef}>
      <TasksRow team={team} isUnplanned={isUnplanned} isLoading={isLoading} />
    </div>
  );
};

export default DroppableTasksRow;
