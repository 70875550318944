import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { exhaustMap, map, catchError, tap, switchMap } from 'rxjs/operators';
import { startOfDay, endOfDay } from 'date-fns';
import { toast } from 'react-toastify';

import { translations } from '../../_translations';
import { dateFromString } from '../../_utils/timeHelper';
import { ActionType as PlanningActionType, SetCurrentDateAction } from '../../planning/_store/actions';
import { planningSelectors, teamsSelectors } from '../../_store/selectors';
import { teamsApi } from '../../_store/api';
import {
  ActionType,
  GetTeamsAction,
  GetTeamsSuccessAction,
  GetTeamsErrorAction,
  EditTeamAction,
  EditTeamSuccessAction,
  EditTeamErrorAction,
  PlanTeamAction,
  PlanTeamSuccessAction,
  PlanTeamErrorAction,
  GetEmployeesAction,
  GetEmployeesSuccessAction,
  GetEmployeesErrorAction,
  RemoveTeamMemberAction,
  RemoveTeamMemberSuccessAction,
  RemoveTeamMemberErrorAction,
  RemoveTeamAction,
  RemoveTeamSuccessAction,
  RemoveTeamErrorAction,
} from './actions';

export const getTeamsEpic$: Epic = action$ =>
  action$.ofType(ActionType.GetTeams).pipe(
    exhaustMap(({ payload }: GetTeamsAction) =>
      from(teamsApi.getTeams(startOfDay(payload.date), endOfDay(payload.date))).pipe(
        map(data => new GetTeamsSuccessAction({ data })),
        catchError(error => of(new GetTeamsErrorAction({ error }))),
      ),
    ),
  );

export const setCurrentTimeEpic$: Epic = action$ =>
  action$
    .ofType(PlanningActionType.SetCurrentDate)
    .pipe(map(({ payload }: SetCurrentDateAction) => new GetTeamsAction({ date: payload.date })));

export const createTeamEpic$: Epic = action$ =>
  action$.ofType(ActionType.EditTeam).pipe(
    exhaustMap(({ payload }: EditTeamAction) =>
      from(teamsApi.editTeam(payload.name, payload.teamId, dateFromString(payload.day))).pipe(
        map(() => new EditTeamSuccessAction({ name: payload.name, isNewTeam: !payload.teamId })),
        catchError(error => of(new EditTeamErrorAction({ error }))),
      ),
    ),
  );

export const createTeamSuccessEpic$: Epic = (action$, state$) =>
  action$.ofType(ActionType.EditTeamSuccess).pipe(
    tap(({ payload }: EditTeamSuccessAction) =>
      toast(translations.getLabel(payload.isNewTeam ? 'CREATE_TEAM_SUCCESS' : 'EDIT_SUCCESS', { name: payload.name })),
    ),
    switchMap(() => of(new GetTeamsAction({ date: planningSelectors.currentDate(state$.value) }))),
  );

export const planTeamEpic$: Epic = action$ =>
  action$.ofType(ActionType.PlanTeam).pipe(
    exhaustMap(({ payload }: PlanTeamAction) => {
      const memberIndex = payload.team.members.findIndex(teamMember => teamMember.id === payload.member.id);
      let members = [...payload.team.members];
      if (memberIndex !== -1) {
        members.splice(memberIndex, 1, payload.member);
      } else {
        members.push(payload.member);
      }
      if (payload.shouldPlanAllMembers) {
        members = members.map(member => ({
          ...member,
          shiftStart: payload.member.shiftStart,
          shiftEnd: payload.member.shiftEnd,
        }));
      }

      return from(teamsApi.planTeam(payload.team, members, payload.pdaUser)).pipe(
        map(
          data =>
            new PlanTeamSuccessAction({
              data,
              name: payload.member.name,
              isNewTeamMember: memberIndex < 0,
              isForAllMembers: payload.shouldPlanAllMembers,
            }),
        ),
        catchError(error => of(new PlanTeamErrorAction({ error }))),
      );
    }),
  );

export const planTeamSuccessEpic$: Epic = action$ =>
  action$.ofType(ActionType.PlanTeamSuccess).pipe(
    tap(({ payload }: PlanTeamSuccessAction) => {
      let successMessage = 'CHANGE_SHIFT_HOURS_SUCCESS';
      if (payload.isForAllMembers) successMessage = 'CHANGE_ALL_SHIFT_HOURS_SUCCESS';
      if (payload.isNewTeamMember) successMessage = 'PLAN_TEAM_SUCCESS';
      return toast(translations.getLabel(successMessage, { name: payload.name }));
    }),
    exhaustMap(() => of()),
  );

export const getEmployeesEpic$: Epic = (action$, state$) =>
  action$.ofType(ActionType.GetEmployees).pipe(
    exhaustMap(({ payload }: GetEmployeesAction) =>
      from(teamsApi.getEmployees(payload.teamId, planningSelectors.currentDate(state$.value))).pipe(
        map(data => new GetEmployeesSuccessAction({ data })),
        catchError(error => of(new GetEmployeesErrorAction({ error }))),
      ),
    ),
  );

export const removeTeamEpic$: Epic = (action$, state$) =>
  action$.ofType(ActionType.RemoveTeam).pipe(
    exhaustMap(({ payload }: RemoveTeamAction) => {
      const team = teamsSelectors.getTeam(state$.value, payload.teamId);
      return from(teamsApi.removeTeam(payload.teamId)).pipe(
        map(() => new RemoveTeamSuccessAction({ id: team.id, name: team.name })),
        catchError(error => of(new RemoveTeamErrorAction({ error }))),
      );
    }),
  );

export const removeTeamSuccessEpic$: Epic = action$ =>
  action$.ofType(ActionType.RemoveTeamSuccess).pipe(
    tap(({ payload }: RemoveTeamSuccessAction) => toast(translations.getLabel('REMOVE_TEAM_SUCCESS', { name: payload.name }))),
    exhaustMap(() => of()),
  );

export const removeTeamMemberEpic$: Epic = (action$, state$) =>
  action$.ofType(ActionType.RemoveTeamMember).pipe(
    exhaustMap(({ payload }: RemoveTeamMemberAction) => {
      const team = teamsSelectors.getTeam(state$.value, payload.teamId);
      const newMembers = team.members.filter(member => member.id !== payload.memberId);
      const oldMember = team.members.find(member => member.id === payload.memberId);
      return from(teamsApi.planTeam(team, newMembers, team.pdaUser)).pipe(
        map(data => new RemoveTeamMemberSuccessAction({ data, name: oldMember.name })),
        catchError(error => of(new RemoveTeamMemberErrorAction({ error }))),
      );
    }),
  );

export const removeTeamMemberSuccessEpic$: Epic = action$ =>
  action$.ofType(ActionType.RemoveTeamMemberSuccess).pipe(
    tap(({ payload }: RemoveTeamMemberSuccessAction) =>
      toast(translations.getLabel('REMOVE_TEAM_MEMBER_SUCCESS', { name: payload.name })),
    ),
    exhaustMap(() => of()),
  );

const TeamsEpics = [
  getTeamsEpic$,
  setCurrentTimeEpic$,
  createTeamEpic$,
  createTeamSuccessEpic$,
  planTeamEpic$,
  planTeamSuccessEpic$,
  getEmployeesEpic$,
  removeTeamEpic$,
  removeTeamSuccessEpic$,
  removeTeamMemberEpic$,
  removeTeamMemberSuccessEpic$,
];

export default TeamsEpics;
