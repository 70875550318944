import { HttpClient } from '../../_http';
import { IIssue } from '../_models/issue';

export function getIssuesForTask(taskId: string): Promise<IIssue[]> {
  return HttpClient.get<IIssue[]>(`tasks/${taskId}/issues`);
}

export function markIssueAsRead(issueId: string): Promise<void> {
  return HttpClient.post(`issues/${issueId}/read`);
}
