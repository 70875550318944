import { ITeam } from '../_models/team';
import { ApiError } from '../../_http';
import { IEmployee } from '../_models/employee';
import { ActionType, Actions } from './actions';

export interface TeamsState {
  isLoading: boolean;
  teams?: ITeam[];
  error?: ApiError;
  isEditTeamLoading: boolean;
  errorEditTeam?: ApiError;
  isPlanTeamLoading: boolean;
  errorPlanTeam?: ApiError;
  employees?: IEmployee[];
  isGetEmployeesLoading: boolean;
  errorGetEmployees?: ApiError;
  isRemoveTeamLoading: boolean;
  errorRemoveTeam?: ApiError;
  isRemoveTeamMemberLoading: boolean;
  errorRemoveTeamMember?: ApiError;
}

const initialState: TeamsState = {
  isLoading: false,
  isEditTeamLoading: false,
  isPlanTeamLoading: false,
  isGetEmployeesLoading: false,
  isRemoveTeamLoading: false,
  isRemoveTeamMemberLoading: false,
};

const sortTeams = (teams: ITeam[]) =>
  teams
    .sort((team1: ITeam, team2: ITeam) => (team1.name >= team2.name ? 1 : -1))
    .sort((team1: ITeam, team2: ITeam) => {
      // First sorting: teams without members should be on top
      if (team1.members.length && !team2.members.length) return 1;
      if (!team1.members.length && team2.members.length) return -1;
      // Second sorting: teams that are no more valid should be below
      if (new Date(team1.validTo).getTime() < new Date().getTime() && new Date(team2.validTo).getTime() > new Date().getTime())
        return 1;
      if (new Date(team1.validTo).getTime() > new Date().getTime() && new Date(team2.validTo).getTime() < new Date().getTime())
        return -1;
      // Third sorting: chronologically on start time
      if (team1.validFrom > team2.validFrom) return 1;
      if (team1.validFrom < team2.validFrom) return -1;
      // Final sorting: alphabetically on name
      if (team1.name >= team2.name) return 1;
      return -1;
    });

export default function reducer(state = initialState, action: Actions): TeamsState {
  switch (action.type) {
    case ActionType.GetTeams:
      return {
        ...state,
        isLoading: action.payload.visibleRefresh,
      };
    case ActionType.GetTeamsSuccess:
      return {
        ...state,
        isLoading: false,
        teams: sortTeams(action.payload.data),
        error: undefined,
      };
    case ActionType.GetTeamsError:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case ActionType.EditTeam:
      return {
        ...state,
        isEditTeamLoading: true,
        errorEditTeam: undefined,
      };
    case ActionType.EditTeamSuccess:
      return {
        ...state,
        isEditTeamLoading: false,
      };
    case ActionType.EditTeamError:
      return {
        ...state,
        isEditTeamLoading: false,
        errorEditTeam: action.payload.error,
      };
    case ActionType.PlanTeam:
      return {
        ...state,
        isPlanTeamLoading: true,
        errorPlanTeam: undefined,
      };
    case ActionType.PlanTeamSuccess:
      return {
        ...state,
        isPlanTeamLoading: false,
        teams: sortTeams([action.payload.data, ...state.teams.filter(team => team.id !== action.payload.data.id)]),
      };
    case ActionType.PlanTeamError:
      return {
        ...state,
        isPlanTeamLoading: false,
        errorPlanTeam: action.payload.error,
      };

    case ActionType.GetEmployees:
      return {
        ...state,
        isGetEmployeesLoading: true,
        errorGetEmployees: undefined,
      };
    case ActionType.GetEmployeesSuccess:
      return {
        ...state,
        isGetEmployeesLoading: false,
        employees: action.payload.data,
      };
    case ActionType.GetEmployeesError:
      return {
        ...state,
        isGetEmployeesLoading: false,
        errorGetEmployees: action.payload.error,
      };
    case ActionType.RemoveTeam:
      return {
        ...state,
        isRemoveTeamLoading: true,
        errorRemoveTeam: undefined,
      };
    case ActionType.RemoveTeamSuccess:
      return {
        ...state,
        isRemoveTeamLoading: false,
        teams: state.teams.filter(team => team.id !== action.payload.id),
      };
    case ActionType.RemoveTeamError:
      return {
        ...state,
        isRemoveTeamLoading: false,
        errorRemoveTeam: action.payload.error,
      };
    case ActionType.RemoveTeamMember:
      return {
        ...state,
        isRemoveTeamMemberLoading: true,
        errorRemoveTeamMember: undefined,
      };
    case ActionType.RemoveTeamMemberSuccess:
      return {
        ...state,
        isRemoveTeamMemberLoading: false,
        teams: sortTeams([action.payload.data, ...state.teams.filter(team => team.id !== action.payload.data.id)]),
      };
    case ActionType.RemoveTeamMemberError:
      return {
        ...state,
        isRemoveTeamMemberLoading: false,
        errorRemoveTeamMember: action.payload.error,
      };
    default:
      return state;
  }
}
