import { createSelector } from 'reselect';

import { AppState } from '../../_store/rootReducer';
import { ActivityLogState } from './reducer';

const selectNode = (state: AppState) => state.activityLog;

export const isLoading = createSelector(selectNode, (state: ActivityLogState) => state.isLoading);

export const data = createSelector(selectNode, (state: ActivityLogState) => state.data);

export const metadata = createSelector(selectNode, (state: ActivityLogState) => state.metadata);

export const error = createSelector(selectNode, (state: ActivityLogState) => state.error);

export const filter = createSelector(selectNode, (state: ActivityLogState) => state.filter);

export const isExporting = createSelector(selectNode, (state: ActivityLogState) => state.isExporting);
