import { Action } from 'redux';

import { ApiError } from '../../_http';

export enum ActionType {
  Login = '[Auth] Login',
  LoginSuccess = '[Auth] LoginSuccess',
  LoginError = '[Auth] LoginError',
}

export class LoginAction implements Action<ActionType> {
  readonly type = ActionType.Login;
  constructor(public payload: { apiKey: string; noRootRedirect?: boolean }) {}
}

export class LoginSuccessAction implements Action<ActionType> {
  readonly type = ActionType.LoginSuccess;
  constructor(public payload: { apiKey: string; noRootRedirect?: boolean }) {}
}

export class LoginErrorAction implements Action<ActionType> {
  readonly type = ActionType.LoginError;
  constructor(public payload: { error: ApiError }) {}
}

export type Actions = LoginAction | LoginSuccessAction | LoginErrorAction;
