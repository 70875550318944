import React, { FC, useEffect, RefObject, useRef } from 'react';
import { isSameDay, subHours } from 'date-fns';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { timeHelper } from '../../_utils';
import { useToggle, useCurrentTime, useScrollListener } from '../../_hooks';
import { translations } from '../../_translations';
import { planningSelectors } from '../../_store/selectors';

import constants from '../../_styles/constants.module.scss';
import './timeLineArrow.scss';

interface Props {
  parentRef: RefObject<HTMLDivElement>;
}

const arrowWidth = parseInt(constants.timelineArrowWidth);
const arrowHeight = parseInt(constants.timelineArrowHeight);
const teamWidth = parseInt(constants.teamWidth);
const planningPaddingHorizontal = parseInt(constants.planningPaddingHorizontal);
const leftBoundary = planningPaddingHorizontal + teamWidth - arrowHeight / 2;
const rightBoundary = window.innerWidth - planningPaddingHorizontal;

const TimeLineArrow: FC<Props> = ({ parentRef }) => {
  const currentDate = useSelector(planningSelectors.currentDate);

  const [isLeftBoundaryReached, setIsLeftBoundaryReached] = useToggle(false);
  const [isRightBoundaryReached, setIsRightBoundaryReached] = useToggle(false);
  const currentTime = useCurrentTime();
  const ref = useRef<HTMLDivElement>(null);

  // Initialize the arrow on changing date
  useEffect(() => {
    if (isSameDay(new Date(), currentDate)) handleScroll();
  }, [currentDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleScroll = () => {
    if (ref.current) {
      if (ref.current.getBoundingClientRect().left <= leftBoundary) {
        setIsLeftBoundaryReached(true);
      } else if (ref.current.getBoundingClientRect().right >= rightBoundary) {
        setIsRightBoundaryReached(true);
      } else {
        setIsRightBoundaryReached(false);
        setIsLeftBoundaryReached(false);
      }
    }
  };
  useScrollListener(parentRef.current, handleScroll);

  const scrollToNow = () => {
    parentRef.current.scrollTo({
      left: timeHelper.getDateOffset(subHours(new Date(), 1)),
      behavior: 'smooth',
    });
  };

  const className = classnames('time-line-arrow', {
    left: isLeftBoundaryReached,
    right: isRightBoundaryReached,
    fixed: isLeftBoundaryReached || isRightBoundaryReached,
  });
  const hiddenArrowLeft = `calc(${timeHelper.getDateOffset(currentTime)}px + ${teamWidth}px)`;
  const left = isLeftBoundaryReached
    ? `${leftBoundary}px`
    : isRightBoundaryReached
    ? `${rightBoundary - arrowWidth}px`
    : hiddenArrowLeft;
  return (
    isSameDay(currentDate, currentTime) && (
      <>
        <button className={className} onClick={scrollToNow} style={{ left }}>
          <span>{translations.getLabel('NOW')}</span>
        </button>
        {/* We need a hidden arrow to track the real left value of the arrow */}
        <span ref={ref} className="time-line-arrow hidden-arrow" style={{ left: hiddenArrowLeft }} />
      </>
    )
  );
};

export default TimeLineArrow;
