import React from 'react';

import Grid from './grid/Grid';

import './teams.scss';

const Teams = () => (
  <div className="teams">
    <Grid />
  </div>
);

export default Teams;
