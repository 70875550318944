import { HttpClient } from '../../_http';
import { HttpMetadataPagingResponse } from '../../_http/HttpMetadata';
import { TReportsLogFilter } from '../_models/reportsLogFilter';
import { TReportsLogItem } from '../_models/reportsLogItem';

interface GetReportsLogResponse {
  data: TReportsLogItem[];
  meta: HttpMetadataPagingResponse;
}

export function getReportsLog(offset: number, filter: TReportsLogFilter): Promise<GetReportsLogResponse> {
  return HttpClient.get<GetReportsLogResponse>('checks', { ...filter, take: 10, offset });
}

export function exportReportsLog(filter: TReportsLogFilter): Promise<Blob> {
  return HttpClient.get<Blob>('checks/export', { ...filter }, {}, 'blob');
}
