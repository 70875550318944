interface ITaskTeam {
  id: string;
  name: string;
  taskState: TeamTaskState;
  isMainTeam: boolean;
}

export interface ITask {
  id: string;
  name: string;
  type: TaskType;
  state: TaskState;
  startTime: string;
  endTime: string;
  travelStartTime: string;
  travelEndTime: string;
  teams: ITaskTeam[];
  isManuallyCreated: boolean;
  isDelayed?: boolean;
  arrivalFlightNumber?: string;
  departureFlightNumber?: string;
  flightState?: FlightState;
  imat?: string;
  departureTime?: string;
  arrivalTime?: string;
  startedAt?: string;
  endedAt?: string;
  latestPossibleEndTime?: string;
  earliestPossibleStartTime?: string;
  description?: string;
  location?: string;
  cleaningProcedure?: CleaningProcedureType;
  blockTime?: string;
  skippable?: boolean;
  unreadIssuesCount: number;
  executionTime?: ExecutionTime;
  origin?: string;
  destination?: string;
}

export enum TaskState {
  Unplanned = 'UNPLANNED',
  Planned = 'PLANNED',
  Accepted = 'ACCEPTED',
  Started = 'STARTED',
  Ended = 'ENDED',
  CancelledByDispatcher = 'CANCELLED_BY_DISPATCHER',
}

export enum TeamTaskState {
  Created = 'CREATED',
  Accepted = 'ACCEPTED',
  Arrived = 'ARRIVED',
  Started = 'STARTED',
  Ended = 'ENDED',
}

export enum TaskType {
  Flight = 'FLIGHT',
  NonFlight = 'NON_FLIGHT',
}

export enum FlightState {
  InAir = 'IN_AIR',
  Landing = 'LANDING',
  Landed = 'LANDED',
  InBlock = 'IN_BLOCK',
  Departing = 'DEPARTING',
  Cancelled = 'CANCELLED',
}

export enum CleaningProcedureType {
  NBTT = 'NB_TT',
  NBTTComfort = 'NB_TT_COMFORT',
  NBNightStop = 'NB_NIGHT_STOP',
  WBTC = 'WB_TC',
  TLV = 'TLV',
}

export enum ExecutionTime {
  FULL = 'FULL',
  REDUCED = 'REDUCED',
  NONE = 'NONE',
}

export const CleaningProcedureDurations = {
  [CleaningProcedureType.NBTT]: 10,
  [CleaningProcedureType.NBTTComfort]: 10,
  [CleaningProcedureType.NBNightStop]: 40,
  [CleaningProcedureType.WBTC]: 75,
  [CleaningProcedureType.TLV]: 20,
};
