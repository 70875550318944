import React, { FC, useState, useEffect, ReactNode, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addDays } from 'date-fns';

import { useToggle, useActionPromise } from '../../../_hooks';
import { translations } from '../../../_translations';
import { Button, Modal, InputCheckbox, InputFieldTime } from '../../../_shared';
import TeamsDropdown from '../../../teams/teamsDropdown/TeamsDropdown';
import { ITask } from '../../_models/task';
import { isTaskMainTeam } from '../../_models/rules';
import { canTeamExecuteTask } from '../../../teams/_models/rules';
import { TasksApiError } from '../../_store/api';
import UnplanTaskModal from '../unplanTaskModal/UnplanTaskModal';
import { formatTime, formatDate, dateFromISOString } from '../../../_utils/timeHelper';
import { validateNewStartTime } from '../../../_utils/taskHelper';
import { PlanTaskAction } from '../../_store/actions';
import { tasksSelectors } from '../../../_store/selectors';

import './moveTaskModal.scss';

interface Props {
  task: ITask;
  teamId?: string;
  trigger: ReactNode;
  isPlannedTask?: boolean;
}

const MoveTaskModal: FC<Props> = ({ trigger, task, teamId, isPlannedTask }) => {
  const isLoading = useSelector(tasksSelectors.isPlanTaskLoading);
  const error = useSelector(tasksSelectors.errorPlanTask);
  const dispatch = useDispatch();

  const [isModalVisible, toggleModal] = useToggle(false);
  const [isError, toggleError] = useToggle(false);
  const [selectedTeamId, setSelectedTeamId] = useState(teamId);
  const [didClickUnplan, setDidClickUnplan] = useToggle(false);
  const [isStartTimeNextDay, toggleStartTimeNextDay] = useToggle(false);
  const [startTime, setStartTime] = useState<string>(formatTime(task.startTime));
  const [timeError, setTimeError] = useState<string>('');

  useActionPromise(isLoading, toggleModal, toggleError, error);

  useEffect(() => setSelectedTeamId(teamId), [teamId]);

  useEffect(() => {
    setStartTime(formatTime(task.startTime));
  }, [task]);

  function errorMessage(): string {
    if (!error) return null;
    if (error.error === TasksApiError.InvalidPlanningError) return translations.getLabel('SCHEDULE_ERROR');
    if (error.error && error.error.includes('TIME')) {
      const newTimeError = translations.getLabel(error.error, { startTime: formatTime(task.earliestPossibleStartTime) });
      if (timeError !== newTimeError) {
        setTimeError(newTimeError);
        toggleError();
      }

      return null;
    }
    return translations.getLabel('ERROR');
  }

  const clearError = useCallback(() => {
    setTimeError('');
  }, [setTimeError]);

  function onPlanTask() {
    let mainTeamId = selectedTeamId;
    const startDate = formatDate(addDays(dateFromISOString(task.startTime), isStartTimeNextDay ? 1 : 0));
    const validation = validateNewStartTime(task, `${startDate} ${startTime}`);
    if (validation.error) {
      return setTimeError(translations.getLabel(validation.message, { startTime: formatTime(task.earliestPossibleStartTime) }));
    }
    if (task.teams.length > 0) mainTeamId = isTaskMainTeam(task, teamId) ? selectedTeamId : null;
    const unplannedTeamIds = teamId === selectedTeamId ? [] : [teamId]; // If a team is rescheduled in time, don't unplan it
    const plannedTeamId = teamId === selectedTeamId ? '' : selectedTeamId; // If a team is rescheduled, don't add it again
    dispatch(
      new PlanTaskAction({
        task,
        plannedTeamId,
        unplannedTeamIds,
        mainTeamId,
        startTime: validation.startTime,
      }),
    );
    setDidClickUnplan(false);
  }

  function renderUnplanAction() {
    function renderUnplanTextLink(loading?: boolean, onClick?: () => void) {
      return (
        <Button isTextLink negative loading={loading} onClick={onClick}>
          {translations.getLabel('REMOVE_FROM_PLANNING')}
        </Button>
      );
    }

    if (task.teams.length > 1)
      return <UnplanTaskModal task={task} teamId={teamId} trigger={renderUnplanTextLink()} actionAfterClose={toggleModal} />;
    return renderUnplanTextLink(didClickUnplan && isLoading, () => {
      dispatch(new PlanTaskAction({ task, unplannedTeamIds: [teamId] }));
      setDidClickUnplan(true);
    });
  }

  return (
    <Modal className="plan-task-modal" open={isModalVisible} onOpen={toggleModal} onClose={toggleModal} trigger={trigger}>
      <Modal.Header>{translations.getLabel(isPlannedTask ? 'MOVE_TASK' : 'SCHEDULE_TASK')}</Modal.Header>
      <Modal.Content>
        <p>{translations.getLabel(isPlannedTask ? 'MOVE_TO_WHICH_TEAM' : 'TEAM_TO_SCHEDULE')}</p>
        <TeamsDropdown
          error={isError}
          errorMessage={errorMessage()}
          filter={team => canTeamExecuteTask(team, task, true)}
          onChange={(value: string) => {
            toggleError(false);
            setSelectedTeamId(value);
          }}
          value={selectedTeamId}
        />
        <div className="row">
          <InputFieldTime
            labelIcon="SvgStart"
            fluid
            errorMessage={timeError}
            error={!!timeError}
            label={translations.getLabel('EDIT_TASK_FIELD_STARTTIME')}
            onChange={value => {
              setStartTime(value);
              clearError();
            }}
            value={startTime}
          />
          <div /> {/* Placeholder so startime will be half width. In next version, the endtime will be here */}
        </div>
        <div className="row">
          <InputCheckbox
            checked={isStartTimeNextDay}
            label={translations.getLabel('STARTTIME_NEXT_DAY')}
            name="skippable"
            onChange={toggleStartTimeNextDay}
          />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button isTextLink onClick={toggleModal}>
          {translations.getLabel('CANCEL')}
        </Button>
        {isPlannedTask && renderUnplanAction()}
        <Button primary disabled={!selectedTeamId || !startTime} onClick={onPlanTask} loading={!didClickUnplan && isLoading}>
          {translations.getLabel(isPlannedTask ? 'MOVE' : 'SCHEDULE')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default MoveTaskModal;
