import { useLayoutEffect } from 'react';

const useScrollListener = (element: HTMLElement, callback: (event?: Event) => void): void => {
  useLayoutEffect(() => {
    if (!element) return;
    const ref = element;
    ref.addEventListener('scroll', callback);
    return () => ref.removeEventListener('scroll', callback);
  }, [callback, element]);
};

export default useScrollListener;
