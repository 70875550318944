import React, { FC } from 'react';
import classnames from 'classnames';

import Datepicker from '../datepicker/Datepicker';
import { formatDate, dateFromString } from '../../_utils/timeHelper';
import { normalizeDate } from '../../_utils/normalize';
import Icon from '../icon/Icon';
import { translations } from '../../_translations';
import InputField, { InputFieldProps } from './InputField';

import './input.scss';

const InputFieldDate: FC<InputFieldProps> = props => (
  <div className={classnames('input-date-wrapper', { 'has-label': !!props.label })}>
    <InputField {...props} type="text" normalize={normalizeDate} placeholder={translations.getLabel('PLACEHOLDER_DATE')} />
    <Datepicker
      name={props.name}
      selected={dateFromString(props.value)}
      onChange={(date: Date) => props.onChange(formatDate(date), props.name)}
      trigger={
        <span className="datepicker-icon">
          <Icon name="SvgCalendar" size={2.4} />
        </span>
      }
    />
  </div>
);

export default InputFieldDate;
