import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { subHours } from 'date-fns';
import classnames from 'classnames';

import { translations } from '../_translations';
import { timeHelper } from '../_utils';
import { useToggle, useScrollListener } from '../_hooks';
import { teamsSelectors, tasksSelectors } from '../_store/selectors';
import Grid from './grid/Grid';
import ResizableGrids from './grid/ResizableGrids';
import TimeLineArrow from './grid/TimeLineArrow';

import './tasks.scss';

const EXTRA_OFFSET = 10;

const Tasks = () => {
  const teams = useSelector(teamsSelectors.teams);
  const isLoadingTasks = useSelector(tasksSelectors.isLoading);
  const isLoadingTeams = useSelector(teamsSelectors.isLoading);

  const [isAtStartPosition, setIsAtStartPosition] = useToggle(false);
  const [isAtTheEnd, setIsAtTheEnd] = useToggle(false);
  const gridRef = useRef<HTMLDivElement>(null);
  const isLoading = isLoadingTasks || isLoadingTeams;

  useEffect(() => {
    const ref = gridRef.current;
    ref.scrollLeft = timeHelper.getDateOffset(subHours(new Date(), 1));
    setIsAtStartPosition(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleScroll = ({ target }) => {
    const threshold = target.scrollWidth - target.clientWidth - EXTRA_OFFSET;
    // Specific IF condition so we don't invoke the function on every scroll event
    if (!isAtTheEnd && target.scrollLeft > threshold) {
      setIsAtTheEnd(true);
    } else if (isAtTheEnd && target.scrollLeft <= threshold) {
      setIsAtTheEnd(false);
    }
  };
  useScrollListener(gridRef.current, handleScroll);

  return (
    <div className={classnames('tasks', { 'no-gradient': isAtTheEnd })}>
      <div className="grid-wrapper" ref={gridRef}>
        <TimeLineArrow parentRef={gridRef} />
        <ResizableGrids>
          {[
            <Grid
              key="grid-planned"
              title={translations.getLabel('TEAMS')}
              teams={teams}
              isLoading={isLoading}
              isAtStartPosition={isAtStartPosition}
            />,
            <Grid
              key="grid-unplanned"
              title={translations.getLabel('NOT_PLANNED')}
              isUnplanned
              isLoading={isLoading}
              isAtStartPosition={isAtStartPosition}
            />,
          ]}
        </ResizableGrids>
      </div>
    </div>
  );
};

export default Tasks;
