import { ApiError } from '../../_http';
import { HttpMetadataPagingResponse } from '../../_http/HttpMetadata';
import { TReportsLogFilter } from '../_models/reportsLogFilter';
import { TReportsLogItem } from '../_models/reportsLogItem';
import { ActionType, Actions } from './actions';

export interface ReportsLogState {
  isLoading: boolean;
  data?: TReportsLogItem[];
  metadata?: HttpMetadataPagingResponse;
  error?: ApiError;
  filter?: TReportsLogFilter;
  isExporting?: boolean;
  errorExporting?: ApiError;
}

const initialState: ReportsLogState = {
  isLoading: false,
};

export default function reducer(state = initialState, action: Actions): ReportsLogState {
  switch (action.type) {
    case ActionType.GetReportsLog:
      return {
        ...state,
        isLoading: true,
      };
    case ActionType.GetReportsLogSuccess: {
      let currentData = state.data || [];
      if (!action.payload.offset) currentData = []; // Start overnew when the offset was reset
      const updatedIds = action.payload.data.map(value => value.id);
      return {
        ...state,
        isLoading: false,
        data: [...currentData.filter(value => !updatedIds.includes(value.id)), ...action.payload.data],
        metadata: action.payload.meta,
        error: null,
      };
    }
    case ActionType.GetReportsLogError:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case ActionType.SetReportsLogFilter:
      return {
        ...state,
        filter: action.payload.filter,
      };
    case ActionType.ExportReportsLog:
      return {
        ...state,
        isExporting: true,
      };
    case ActionType.ExportReportsLogSuccess:
      return {
        ...state,
        isExporting: false,
        errorExporting: null,
      };
    case ActionType.ExportReportsLogError:
      return {
        ...state,
        isExporting: false,
        errorExporting: action.payload.error,
      };
    default:
      return state;
  }
}
