import React, { FC } from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

import Icon from '../icon/Icon';
import ErrorMessage from '../errorMessage/ErrorMessage';
import { useInputError } from '../../_hooks';

export interface DropdownOption {
  key?: string;
  text: string;
  value: string;
}

export interface InputDropdownProps {
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  fluid?: boolean;
  label?: string;
  labelIcon?: string;
  name?: string;
  onChange?: (value: any, name: string) => void;
  placeholder?: string;
  value?: any;
  options?: DropdownOption[];
}

const InputDropdown: FC<InputDropdownProps> = ({ label, labelIcon, error, errorMessage, onChange, options, ...props }) => {
  const { showError, setDirty } = useInputError(error);

  return (
    <div className="input-wrapper">
      {!!label && (
        <label>
          {!!labelIcon && <Icon name={labelIcon} />}
          <span>{label}</span>
        </label>
      )}
      <Dropdown
        {...props}
        error={showError}
        onChange={(_, data: DropdownProps) => {
          onChange(data.value, data.name);
          setDirty();
        }}
        options={options || []}
        selection
      />
      <ErrorMessage isVisible={showError}>{errorMessage}</ErrorMessage>
    </div>
  );
};

export default InputDropdown;
