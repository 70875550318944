import { IActivityLogItem } from '../_models/activityLogItem';
import { HttpClient } from '../../_http';
import { IActivityLogFilter } from '../_models/activityLogFilter';
import { HttpMetadataPagingResponse } from '../../_http/HttpMetadata';

interface GetActivityLogResponse {
  data: IActivityLogItem[];
  meta: HttpMetadataPagingResponse;
}

export function getActivityLog(offset: number, filter: IActivityLogFilter): Promise<GetActivityLogResponse> {
  return HttpClient.get<GetActivityLogResponse>('history', { ...filter, take: 10, offset });
}

export function exportActivityLog(filter: IActivityLogFilter): Promise<Blob> {
  return HttpClient.get<Blob>('history/export', { ...filter }, {}, 'blob');
}
