import React from 'react';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import 'semantic-ui-less/semantic.less';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { configureStore, history } from './_store/store';
import { sessionStorageHelper } from './_utils';
import { LoginAction } from './auth/_store/actions';

import './index.scss';

if (process.env.REACT_APP_ENV !== 'development_local') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    environment: process.env.REACT_APP_ENV,
  });
}

const store = configureStore();
if (sessionStorageHelper.hasApiKey()) {
  store.dispatch(new LoginAction({ apiKey: sessionStorageHelper.getApiKey(), noRootRedirect: true }));
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
