import React, { useState, BaseSyntheticEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Loader } from 'semantic-ui-react';
import { Button, InputField, ErrorMessage } from '../_shared';
import { ReactComponent as SvgLogo } from '../_assets/svg/login-logo.svg';
import { authenticationSelectors } from '../_store/selectors';
import { translations } from '../_translations';
import { LoginAction } from './_store/actions';

import './login.scss';

const Login = () => {
  const isLoading = useSelector(authenticationSelectors.isLoading);
  const error = useSelector(authenticationSelectors.errorLogin);
  const dispatch = useDispatch();

  const [apiKey, setApiKey] = useState('');

  return (
    <div className="login-container">
      <SvgLogo className="logo" />
      <h2>{translations.getLabel('LOGIN')}</h2>
      {isLoading ? (
        <Loader />
      ) : (
        <form
          onSubmit={(e: BaseSyntheticEvent) => {
            e.preventDefault();
            if (!!apiKey) dispatch(new LoginAction({ apiKey }));
          }}
        >
          <InputField
            className="input"
            placeholder={translations.getLabel('PLACEHOLDER_LOGIN')}
            autoFocus
            value={apiKey}
            onChange={value => setApiKey(value)}
          />
          <ErrorMessage isVisible={!!error}>{translations.getLabel('ERROR_LOGIN')}</ErrorMessage>
          <Button primary disabled={!apiKey} loading={isLoading} onClick={() => dispatch(new LoginAction({ apiKey }))}>
            {translations.getLabel('LOGIN')}
          </Button>
        </form>
      )}
    </div>
  );
};

export default Login;
