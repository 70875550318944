import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isToday, isYesterday, isTomorrow, addDays } from 'date-fns';

import { formatDate } from '../../_utils/timeHelper';
import { translations } from '../../_translations';
import { Icon, Datepicker, Button } from '../../_shared';
import { SetCurrentDateAction } from '../_store/actions';
import { planningSelectors } from '../../_store/selectors';

import './currentDateNavigator.scss';

function dateToString(date: Date): string {
  if (isToday(date)) return translations.getLabel('TODAY');
  if (isTomorrow(date)) return translations.getLabel('TOMORROW');
  if (isYesterday(date)) return translations.getLabel('YESTERDAY');
  return formatDate(date, 'iiii d MMM yyyy');
}

const DatePicker = () => {
  const currentDate = useSelector(planningSelectors.currentDate);
  const dispatch = useDispatch();

  return (
    <div className="planning-date-picker">
      <Icon
        name="SvgChevronLeft"
        size={2.4}
        onClick={() => dispatch(new SetCurrentDateAction({ date: addDays(currentDate, -1) }))}
      />
      <Datepicker
        trigger={
          <span className="datepicker-trigger">
            <Button isTextLink onClick={() => {}}>
              {dateToString(currentDate)}
            </Button>
          </span>
        }
        onChange={(date: Date) => dispatch(new SetCurrentDateAction({ date }))}
        selected={currentDate}
        popperPlacement="bottom"
      />
      <Icon
        name="SvgChevronRight"
        size={2.4}
        onClick={() => dispatch(new SetCurrentDateAction({ date: addDays(currentDate, 1) }))}
      />
    </div>
  );
};

export default DatePicker;
