import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import activityLogReducer, { ActivityLogState } from '../activityLog/_store/reducer';
import authReducer, { AuthState } from '../auth/_store/reducer';
import modalReducer, { ModalState } from '../modal/_store/reducer';
import planningReducer, { PlanningState } from '../planning/_store/reducer';
import sidebarReducer, { SidebarState } from '../sidebar/_store/reducer';
import tasksReducer, { TasksState } from '../tasks/_store/reducer';
import teamsReducer, { TeamsState } from '../teams/_store/reducer';
import issuesReducer, { IssuesState } from '../issues/_store/reducer';
import reportsLogReducer, { ReportsLogState } from '../reports/_store/reducer';

export interface AppState {
  activityLog: ActivityLogState;
  auth: AuthState;
  modal: ModalState;
  planning: PlanningState;
  router: RouterState;
  sidebar: SidebarState;
  tasks: TasksState;
  teams: TeamsState;
  issues: IssuesState;
  reportsLog: ReportsLogState;
}

export default (history: History) =>
  combineReducers<AppState>({
    activityLog: activityLogReducer,
    auth: authReducer,
    modal: modalReducer,
    planning: planningReducer,
    router: connectRouter(history),
    sidebar: sidebarReducer,
    tasks: tasksReducer,
    teams: teamsReducer,
    issues: issuesReducer,
    reportsLog: reportsLogReducer,
  });
