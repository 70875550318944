import { Action } from 'redux';

import { ISidebarData } from '../_models';

export enum ActionType {
  ShowSidebarTaskDetail = '[Sidebar] showSidebarTaskDetail',
  ShowSidebarTaskEdit = '[Sidebar] showSidebarTaskEdit',
  ShowSidebarTaskIssues = '[Sidebar] showSidebarTaskIssues',
  ShowSidebarTaskIssueDetail = '[Sidebar] showSidebarTaskIssueDetail',
  CloseSidebar = '[Sidebar] closeSidebar',
}

export class ShowSidebarTaskDetailAction implements Action<ActionType> {
  readonly type = ActionType.ShowSidebarTaskDetail;
  constructor(public payload: { data: ISidebarData }) {}
}

export class ShowSidebarTaskEditAction implements Action<ActionType> {
  readonly type = ActionType.ShowSidebarTaskEdit;
  constructor(public payload?: { data: ISidebarData }) {}
}

export class ShowSidebarTaskIssuesAction implements Action<ActionType> {
  readonly type = ActionType.ShowSidebarTaskIssues;
  constructor(public payload?: { data: ISidebarData }) {}
}

export class ShowSidebarTaskIssueDetailAction implements Action<ActionType> {
  readonly type = ActionType.ShowSidebarTaskIssueDetail;
  constructor(public payload?: { data: ISidebarData }) {}
}

export class CloseSidebarAction implements Action<ActionType> {
  readonly type = ActionType.CloseSidebar;
}

export type Actions =
  | ShowSidebarTaskDetailAction
  | ShowSidebarTaskEditAction
  | ShowSidebarTaskIssuesAction
  | ShowSidebarTaskIssueDetailAction
  | CloseSidebarAction;
