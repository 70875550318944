import React, { FC } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import { AuthorizedLayout, AuthorizedRoute, UnauthorizedRoute } from './_routing';
import Login from './auth/Login';
import { ToastCloseButton } from './_shared';
import CustomDragLayer from './dragDrop/CustomDragLayer';
import ReduxDelegatedModal from './modal/ReduxDelegatedModal';

import 'react-toastify/dist/ReactToastify.css';
import './app.scss';

const App: FC = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <CustomDragLayer />
      <ToastContainer
        position="bottom-right"
        closeOnClick={false}
        closeButton={<ToastCloseButton />}
        className="toast-container"
        toastClassName="toast"
        bodyClassName="toast-body"
        progressClassName="toast-progress"
      />
      <ReduxDelegatedModal />
      <Switch>
        <UnauthorizedRoute path="/auth/login" component={Login} />
        <AuthorizedRoute path="/" component={AuthorizedLayout} />
        <Redirect to="/" />
      </Switch>
    </DndProvider>
  );
};

export default App;
