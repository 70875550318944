import React, { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isToday } from 'date-fns';

import { ISidebarData } from '../_models';
import SidebarHeader from '../sidebarHeader/SidebarHeader';
import { Button, Icon } from '../../_shared';
import { ShowSidebarTaskIssuesAction, CloseSidebarAction } from '../_store/actions';
import { tasksSelectors, issuesSelectors } from '../../_store/selectors';
import { translations } from '../../_translations';
import { getIndicatorForTeamTaskState } from '../../_utils/taskHelper';
import { dateFromISOString, formatDate } from '../../_utils/timeHelper';
import { AppState } from '../../_store/rootReducer';

import './taskIssueDetail.scss';

interface Props {
  data: ISidebarData;
}

const TaskIssueDetail: FC<Props> = ({ data }) => {
  const task = useSelector((state: AppState) => tasksSelectors.getTask(state, data.taskId));
  const issue = useSelector((state: AppState) => issuesSelectors.getIssue(state, data.issueId));
  const dispatch = useDispatch();

  const [visibleAttachmentIndex, setVisibleAttachmentIndex] = useState(0);

  if (!issue) {
    dispatch(new CloseSidebarAction());
    return null;
  }

  const { icon: taskStateIcon, label: taskStateLabel } = getIndicatorForTeamTaskState(issue.teamTaskState);
  const createdDate = dateFromISOString(issue.createdAt);
  const isCreatedToday = isToday(createdDate);

  return (
    <div className="sidebar-inner task-issue-detail">
      <div className="sidebar-header-buttons left">
        <Button isTextLink backNavigation onClick={() => dispatch(new ShowSidebarTaskIssuesAction({ data }))}>
          <Icon name="SvgChevronLeft" size={2} />
          <span>{translations.getLabel('BACK_TO_TASK_ISSUES', { task: task.name })}</span>
        </Button>
      </div>
      <SidebarHeader title={translations.getLabel(issue.type)}>
        <div className="header-info">
          <h2>{`${translations.getLabel('TASK')} ${task.name}`}</h2>
          <span>{`${isCreatedToday ? `${translations.getLabel('TODAY')} ` : ''}${formatDate(
            createdDate,
            isCreatedToday ? 'HH:mm' : 'HH:mm (d MMM)',
          )}`}</span>
        </div>
      </SidebarHeader>
      <div className="sidebar-content">
        <div className="team-details">
          <div className="issue-detail-item">
            <Icon name={taskStateIcon} size={2} />
            <span>{translations.getLabel(taskStateLabel)}</span>
          </div>
          <div className="issue-detail-item">
            <Icon name="SvgTeam" size={2} />
            <span>{issue.team && issue.team.name}</span>
          </div>
        </div>
        {issue.description && (
          <div className="issue-detail-item">
            <Icon name="SvgComment" size={2} />
            <p className="description">{issue.description}</p>
          </div>
        )}
        {!!(issue.attachments || []).length && (
          <div className="attachments-wrapper">
            <img src={`data:image/png;base64,${issue.attachments[visibleAttachmentIndex].content}`} alt="attachment" />
            <div className="navigation">
              <Icon
                name="SvgChevronLeft"
                size={2.6}
                onClick={() => setVisibleAttachmentIndex(visibleAttachmentIndex - 1 > 0 ? visibleAttachmentIndex - 1 : 0)}
                aria-label={translations.getLabel('PREVIOUS')}
              />
              <span>{`${visibleAttachmentIndex + 1} / ${issue.attachments.length}`}</span>
              <Icon
                name="SvgChevronRight"
                size={2.6}
                onClick={() =>
                  setVisibleAttachmentIndex(
                    visibleAttachmentIndex + 1 < issue.attachments.length
                      ? visibleAttachmentIndex + 1
                      : issue.attachments.length - 1,
                  )
                }
                aria-label={translations.getLabel('NEXT')}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskIssueDetail;
