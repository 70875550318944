import React, { FC, BaseSyntheticEvent, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { translations } from '../../_translations';
import { Button, InputField, InputCheckbox, InputRadio, InputFieldDate, InputFieldTime } from '../../_shared';
import SidebarHeader from '../sidebarHeader/SidebarHeader';
import SidebarFooter from '../sidebarFooter/SidebarFooter';
import { EditTaskAction } from '../../tasks/_store/actions';
import { planningSelectors, tasksSelectors } from '../../_store/selectors';
import CleaningProceduresDropdown from '../../tasks/cleaningProcedures/CleaningProceduresDropdown';
import { TaskType, CleaningProcedureType, ITask, TaskState } from '../../tasks/_models/task';
import { isTaskStateEqualToOrAfterState, canTaskBeRemoved, canTaskBeEdited } from '../../tasks/_models/rules';
import { IEditTask } from '../../tasks/_models/editTask';
import ErrorMessage from '../../_shared/errorMessage/ErrorMessage';
import { formatDate, formatTime, dateFromISOString } from '../../_utils/timeHelper';
import { ApiError, getValidationError, getValidationErrorMessage } from '../../_http';
import { InputFieldProps } from '../../_shared/input/InputField';
import RemoveTaskModal from '../../tasks/modals/removeTaskModal/RemoveTaskModal';
import { usePrevious, useForm } from '../../_hooks';
import { ShowSidebarTaskDetailAction, CloseSidebarAction } from '../_store/actions';
import { ISidebarData } from '../_models';
import { AppState } from '../../_store/rootReducer';
import { updateEndTime } from './validation';

import './taskEdit.scss';

function formatError(error: ApiError, task: ITask) {
  if (error.error === 'INVALID_START_TIME')
    return translations.getLabel(error.error, { startTime: formatTime(task.earliestPossibleStartTime) });
  return translations.getLabel(error.error || 'NEW_TASK_ERROR');
}

function labelForInput(name: string): string {
  return translations.getLabel(`EDIT_TASK_FIELD_${name.toUpperCase()}`);
}

function initialFormFromTask(task: ITask, currentDate: Date): IEditTask {
  if (task) {
    return {
      name: task.name,
      type: task.type,
      startDate: formatDate(dateFromISOString(task.startTime)),
      startTime: formatTime(task.startTime),
      endDate: formatDate(dateFromISOString(task.endTime)),
      endTime: formatTime(task.endTime),
      arrivalFlightNumber: task.arrivalFlightNumber || '',
      imat: task.imat || '',
      departureDate: task.departureTime ? formatDate(dateFromISOString(task.departureTime)) : '',
      departureTime: formatTime(task.departureTime) || '',
      arrivalDate: task.arrivalTime ? formatDate(dateFromISOString(task.arrivalTime)) : '',
      arrivalTime: formatTime(task.arrivalTime) || '',
      description: task.description || '',
      location: task.location || '',
      cleaningProcedure: task.cleaningProcedure,
      skippable: !!task.skippable,
    };
  }
  const currentDateAsString = formatDate(currentDate);
  return {
    name: '',
    type: TaskType.Flight,
    startDate: currentDateAsString,
    startTime: '',
    endDate: currentDateAsString,
    endTime: '',
    arrivalFlightNumber: '',
    imat: '',
    departureDate: currentDateAsString,
    departureTime: '',
    arrivalDate: currentDateAsString,
    arrivalTime: '',
    description: '',
    location: '',
    cleaningProcedure: null,
    skippable: false,
  };
}

interface Props {
  data: ISidebarData;
}

const TaskEdit: FC<Props> = ({ data }) => {
  const task = useSelector((state: AppState) => tasksSelectors.getTask(state, data.taskId));
  const currentDate = useSelector(planningSelectors.currentDate);
  const error = useSelector(tasksSelectors.errorEditTask);
  const isLoading = useSelector(tasksSelectors.isEditTaskLoading);
  const dispatch = useDispatch();

  const initialForm = initialFormFromTask(task, currentDate);
  const { form, setForm, setFormAttribute } = useForm<IEditTask>(initialForm);

  const { teamId } = data;
  const isExistingTask = task && task.id;
  const isTaskAlreadyPlanned = task && isTaskStateEqualToOrAfterState(task.state, TaskState.Planned);
  const isTaskAlreadyAccepted = task && isTaskStateEqualToOrAfterState(task.state, TaskState.Accepted);
  const isTaskAlreadyStarted = task && isTaskStateEqualToOrAfterState(task.state, TaskState.Started);
  const taskShouldNotBeEditable = task && !canTaskBeEdited(task);
  const prevProps = usePrevious({ startTime: form.startTime, cleaningProcedure: form.cleaningProcedure });
  updateEndTime(form, setForm, prevProps, task);

  const handleCancel = useCallback(() => {
    if (task && task.id) dispatch(new ShowSidebarTaskDetailAction({ data: { taskId: task.id, teamId } }));
    else dispatch(new CloseSidebarAction());
  }, [dispatch, task, teamId]);

  // Handle closing of the edit window when task finished
  useEffect(() => {
    if (taskShouldNotBeEditable) {
      handleCancel();
    }
  }, [taskShouldNotBeEditable, handleCancel]);

  function renderInputField(props: InputFieldProps) {
    return (
      <InputField
        type="text"
        {...props}
        error={!!getValidationError(error, props.name)}
        fluid
        label={labelForInput(props.name)}
        onChange={setFormAttribute}
        value={form[props.name]}
      />
    );
  }

  function renderInputRowDateTime(dateProps: InputFieldProps, timeProps: InputFieldProps) {
    // Show 'isAfter' and 'isBefore' errors here
    const dateValidationError = getValidationError(error, dateProps.name);
    const timeValidationError = getValidationError(error, timeProps.name);
    let errorMessage: string;
    if (timeValidationError && timeValidationError.constraints) {
      if (timeValidationError.constraints.isAfter) {
        errorMessage = translations.getLabel(`ERROR_${timeProps.name.toUpperCase()}_ISAFTER`);
      }

      if (timeValidationError.constraints.isBefore) {
        errorMessage = translations.getLabel(`ERROR_${timeProps.name.toUpperCase()}_ISBEFORE`);
      }
    }

    return (
      <>
        <div className="row">
          <InputFieldDate
            {...dateProps}
            error={!!dateValidationError || !!errorMessage}
            fluid
            label={labelForInput(dateProps.name)}
            onChange={setFormAttribute}
            value={form[dateProps.name]}
          />
          <InputFieldTime
            {...timeProps}
            error={!!timeValidationError || !!errorMessage}
            fluid
            label={labelForInput(timeProps.name)}
            onChange={setFormAttribute}
            value={form[timeProps.name]}
          />
        </div>
        <ErrorMessage isVisible={!!errorMessage}>{errorMessage}</ErrorMessage>
      </>
    );
  }

  function renderAutomaticallyCreatedTaskForm() {
    return (
      <>
        {/* Only show starttime when it's an existing flightTask, all the other cases are handled in renderManuallyCreatedTaskForm  */}
        {isExistingTask && form.type === TaskType.Flight && !task.isManuallyCreated && (
          <>
            {renderInputRowDateTime(
              {
                disabled: isTaskAlreadyStarted,
                labelIcon: 'SvgCalendar',
                name: 'startDate',
              },
              {
                disabled: isTaskAlreadyStarted,
                label: translations.getLabel('EDIT_TASK_FIELD_START_TIME'),
                labelIcon: 'SvgStart',
                name: 'startTime',
              },
            )}
            {renderInputRowDateTime(
              {
                name: 'endDate',
                labelIcon: 'SvgCalendar',
              },
              {
                name: 'endTime',
                labelIcon: 'SvgFinished',
              },
            )}
          </>
        )}
        {form.type === TaskType.Flight && !isTaskAlreadyPlanned && (
          <div className="row">
            <CleaningProceduresDropdown
              disabled={isTaskAlreadyStarted}
              error={!!getValidationError(error, 'cleaningProcedure')}
              onChange={(value: CleaningProcedureType, name: string) => value && setFormAttribute(value, name)}
              value={form.cleaningProcedure}
            />
          </div>
        )}
        <div className="row">
          {renderInputField({
            name: 'description',
            labelIcon: 'SvgComment',
          })}
        </div>
        <div className="row">
          <InputCheckbox
            checked={form.skippable}
            disabled={isTaskAlreadyAccepted}
            error={!!getValidationError(error, 'skippable')}
            label={translations.getLabel('EDIT_TASK_FIELD_SKIPPABLE')}
            name="skippable"
            onChange={setFormAttribute}
          />
        </div>
      </>
    );
  }

  function renderManuallyCreatedTaskForm() {
    return (
      <>
        {!isExistingTask && (
          <div className="row">
            <InputRadio
              checked={form.type === TaskType.Flight}
              label={translations.getLabel('TASK_TYPE_FLIGHT')}
              name="type"
              onChange={() => setForm({ ...initialForm, type: TaskType.Flight })}
              value={TaskType.Flight}
            />
            <InputRadio
              checked={form.type === TaskType.NonFlight}
              label={translations.getLabel('TASK_TYPE_NON_FLIGHT')}
              name="type"
              onChange={() => setForm({ ...initialForm, type: TaskType.NonFlight })}
              value={TaskType.NonFlight}
            />
          </div>
        )}
        {form.type === TaskType.NonFlight && (
          <div className="row">
            {renderInputField({
              name: 'name',
              placeholder: translations.getLabel('EDIT_TASK_FIELD_TITLE_PLACEHOLDER'),
            })}
          </div>
        )}
        {form.type === TaskType.Flight && (
          <>
            <div className="row">
              {renderInputField({ name: 'arrivalFlightNumber' })}
              {renderInputField({ name: 'imat' })}
            </div>
            {renderInputRowDateTime(
              {
                name: 'arrivalDate',
                labelIcon: 'SvgCalendar',
              },
              {
                name: 'arrivalTime',
                labelIcon: 'SvgLanding',
              },
            )}
            {renderInputRowDateTime(
              {
                name: 'departureDate',
                labelIcon: 'SvgCalendar',
              },
              {
                name: 'departureTime',
                labelIcon: 'SvgTakeoff',
              },
            )}
            <div className="divider" />
          </>
        )}
        {renderInputRowDateTime(
          {
            disabled: isTaskAlreadyAccepted,
            labelIcon: 'SvgCalendar',
            name: 'startDate',
          },
          {
            disabled: isTaskAlreadyAccepted,
            label: translations.getLabel('EDIT_TASK_FIELD_START_TIME'),
            labelIcon: 'SvgStart',
            name: 'startTime',
          },
        )}
        {renderInputRowDateTime(
          {
            name: 'endDate',
            labelIcon: 'SvgCalendar',
          },
          {
            name: 'endTime',
            labelIcon: 'SvgFinished',
          },
        )}
        <div className="row">
          {renderInputField({
            disabled: isTaskAlreadyStarted,
            labelIcon: 'SvgLocation',
            name: 'location',
          })}
        </div>
        {renderAutomaticallyCreatedTaskForm()}
      </>
    );
  }

  return (
    <form
      className="sidebar-inner task-edit"
      onSubmit={(e: BaseSyntheticEvent) => {
        e.preventDefault();
        dispatch(new EditTaskAction({ data: form, taskId: isExistingTask ? task.id : null }));
      }}
    >
      <SidebarHeader title={translations.getLabel(isExistingTask ? 'EDIT_TASK' : 'NEW_TASK')} />
      <div className="sidebar-content">
        {!task || task.isManuallyCreated ? renderManuallyCreatedTaskForm() : renderAutomaticallyCreatedTaskForm()}
        {!!error && (
          <div className="row">
            <ErrorMessage isVisible>
              {!error.validationErrors ? formatError(error, task) : getValidationErrorMessage(error, labelForInput)}
            </ErrorMessage>
          </div>
        )}
      </div>
      <SidebarFooter>
        <Button isTextLink type="button" onClick={handleCancel}>
          {translations.getLabel('CANCEL')}
        </Button>
        {isExistingTask && canTaskBeRemoved(task) && (
          <RemoveTaskModal
            taskId={task.id}
            trigger={
              <Button isTextLink negative>
                {translations.getLabel('REMOVE')}
              </Button>
            }
          />
        )}
        <Button primary type="submit" loading={isLoading}>
          {translations.getLabel('SAVE')}
        </Button>
      </SidebarFooter>
    </form>
  );
};

export default TaskEdit;
