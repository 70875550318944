import React from 'react';
import { useSelector } from 'react-redux';

import { translations } from '../../_translations';
import { teamsSelectors } from '../../_store/selectors';
import TeamRow from './TeamRow';

import './grid.scss';

const Grid = () => {
  const teams = useSelector(teamsSelectors.teams);
  const isLoading = useSelector(teamsSelectors.isLoading);

  return (
    <div className="teams-grid">
      <div className="teams-grid-header">
        <div className="title-wrapper">
          <span>{translations.getLabel('TEAM_NAME').toUpperCase()}</span>
        </div>
        <div className="description-wrapper">
          <span>{translations.getLabel('PDA_USER').toUpperCase()}</span>
          <span>{translations.getLabel('OTHER_MEMBERS').toUpperCase()}</span>
        </div>
      </div>
      <div className="teams-grid-content">
        {teams && !!teams.length && teams.map(team => <TeamRow key={team.id} team={team} isLoading={isLoading} />)}
      </div>
    </div>
  );
};

export default Grid;
