import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import classnames from 'classnames';

import { ITeamMember } from '../_models/teamMember';
import RemoveTeamMemberModal from '../modals/removeTeamMemberModal/RemoveTeamMemberModal';
import { Icon } from '../../_shared';
import { timeHelper } from '../../_utils';
import { ITeam } from '../_models/team';
import { canShiftHoursBeChanged } from '../_models/rules';
import ChangeShiftModal from '../modals/changeShiftModal/ChangeShiftModal';
import { formatDateRange } from '../../_utils/timeHelper';
import { useModal } from '../../_hooks';
import { ShowWarningModalAction } from '../../modal/_store/actions';
import { translations } from '../../_translations';
import { tasksSelectors } from '../../_store/selectors';
import { AppState } from '../../_store/rootReducer';

import './teamMemberInfo.scss';

interface Props {
  team: ITeam;
  teamMember?: ITeamMember;
  isLoading?: boolean;
  hasShiftOverlap?: boolean;
}

const TeamMemberInfo: FC<Props> = ({ team, teamMember, isLoading, hasShiftOverlap }) => {
  const tasks = useSelector((state: AppState) => tasksSelectors.getTasksForTeam(state, team.id));
  const dispatch = useDispatch();

  const [renderShiftModal, showShiftModal] = useModal(modalProps => (
    <ChangeShiftModal team={team} teamMember={teamMember} {...modalProps} />
  ));
  const [renderRemoveTeamMemberModal, showRemoveTeamMemberModal] = useModal(modalProps => (
    <RemoveTeamMemberModal memberId={teamMember.id} teamId={team.id} {...modalProps} />
  ));

  if (isLoading) {
    return (
      <div className="team-info">
        <p className="name">
          <Skeleton />
        </p>
        <p className="shift">
          <Skeleton />
        </p>
      </div>
    );
  }

  if (!teamMember) return null;

  const date = formatDateRange(teamMember.shiftStart, teamMember.shiftEnd, {
    format: 'd MMM',
    showSingleDay: true,
    firstMonthFormat: 'd',
  });
  const teamMemberClassNames = classnames('team-member-info', { 'shift-overlap': hasShiftOverlap });

  const handleWarningModal = (showModal: () => void, content: string): void => {
    if (canShiftHoursBeChanged(tasks, team)) {
      showModal();
    } else {
      dispatch(
        new ShowWarningModalAction({
          data: {
            title: translations.getLabel('WARNING'),
            content,
          },
        }),
      );
    }
  };

  return (
    <>
      <div className={teamMemberClassNames}>
        <p className="name">{teamMember.name}</p>
        <button
          className="shift"
          onClick={() => {
            handleWarningModal(showShiftModal, translations.getLabel('CHANGE_SHIFT_HOURS_WARNING'));
          }}
        >
          <span>{`${timeHelper.formatTime(teamMember.shiftStart)} - ${timeHelper.formatTime(teamMember.shiftEnd)}`}</span>
          {!!date && <span>{date}</span>}
        </button>
        {hasShiftOverlap && <Icon name="SvgAlertCircle" className="overlap-icon" size={1.6} />}
        {!teamMember.isPdaUser && (
          <div className="delete-action-wrapper">
            <Icon
              name="SvgTrash"
              size={1.6}
              onClick={() => {
                handleWarningModal(showRemoveTeamMemberModal, translations.getLabel('REMOVE_TEAM_MEMBER_WARNING'));
              }}
            />
          </div>
        )}
      </div>
      {renderShiftModal()}
      {renderRemoveTeamMemberModal()}
    </>
  );
};

export default TeamMemberInfo;
