import { createSelector } from 'reselect';

import { AppState } from '../../_store/rootReducer';
import { AuthState } from './reducer';

const selectNode = (state: AppState) => state.auth;

export const isLoading = createSelector(selectNode, (state: AuthState) => state.isLoading);

export const errorLogin = createSelector(selectNode, (state: AuthState) => state.errorLogin);

export const isLoggedIn = createSelector(selectNode, (state: AuthState) => state.isLoggedIn);
