import React, { FC } from 'react';
import classnames from 'classnames';

import Badge, { BadgeProps } from '../badge/Badge';
import { TaskState, ITask, TeamTaskState } from '../../tasks/_models/task';
import { taskStatesWithoutTeamTaskState, hasReducedExecutionTime, hasNoExecutionTime } from '../../tasks/_models/rules';
import { translations } from '../../_translations';
import { timeHelper } from '../../_utils';

import './taskBadge.scss';

interface Props {
  task: ITask;
  isTime?: boolean;
  isDivider?: boolean;
  teamId?: string;
}

const TEAMTASK_TASKSTATE_MAPPING = {
  [TeamTaskState.Created]: TaskState.Planned,
  [TeamTaskState.Accepted]: TaskState.Accepted,
  [TeamTaskState.Started]: TaskState.Started,
  [TeamTaskState.Ended]: TaskState.Ended,
  [TeamTaskState.Arrived]: TaskState.Accepted,
};

const getPropsForTaskState = (state: TaskState, teamTaskState: TeamTaskState, task: ITask): BadgeProps => {
  const reducedTime = hasReducedExecutionTime(task);
  const noTime = hasNoExecutionTime(task);

  switch (state) {
    case TaskState.Accepted:
      if (teamTaskState === TeamTaskState.Arrived) {
        return {
          icon: 'SvgCar',
          label: translations.getLabel('ARRIVED'),
          className: 'arrived',
        };
      }

      return {
        icon: 'SvgWaiting',
        label: translations.getLabel('SCHEDULED_ACCEPTED'),
        className: classnames('accepted', { 'no-time': noTime, 'reduced-time': reducedTime }),
      };
    case TaskState.Planned:
      return {
        icon: 'SvgWaiting',
        label: translations.getLabel('SCHEDULED_NOT_ACCEPTED'),
        className: classnames('planned', { 'no-time': noTime, 'reduced-time': reducedTime }),
      };
    case TaskState.Ended:
      return {
        icon: 'SvgFinished',
        label: translations.getLabel('ENDED'),
        className: 'ended',
      };
    case TaskState.Started:
      return {
        icon: 'SvgStart',
        label: translations.getLabel('STARTED'),
        className: 'started',
      };
    case TaskState.CancelledByDispatcher:
      return {
        icon: 'SvgClose',
        label: translations.getLabel('CANCELLED_BY_DISPATCH'),
        className: 'cancelled',
      };
    case TaskState.Unplanned:
    default:
      return {
        icon: null,
        label: translations.getLabel('NOT_SCHEDULED'),
        className: 'unplanned',
      };
  }
};

const getBadgeProps = (task: ITask, teamId: string, isTime: boolean): BadgeProps => {
  const teamTaskState = teamId && task.teams && (task.teams.find(team => team.id === teamId) || {}).taskState;
  const matchingTaskState = TEAMTASK_TASKSTATE_MAPPING[teamTaskState];
  let state = matchingTaskState;

  // Display a matching taskState for a certain teamTaskState when not UNPLANNED or cancelled
  if (taskStatesWithoutTeamTaskState.includes(state) || !matchingTaskState) {
    state = task.state;
  }

  const badgeProps = getPropsForTaskState(state, teamTaskState, task);

  if (isTime) {
    return {
      ...badgeProps,
      label: `${timeHelper.formatTime(task.startedAt || task.startTime)} - ${timeHelper.formatTime(
        task.endedAt || task.endTime,
      )}`,
    };
  }
  return {
    ...badgeProps,
    icon: null,
  };
};

const TaskBadge: FC<Props> = ({ task, teamId, isTime, isDivider }) => {
  const props = getBadgeProps(task, teamId, isTime);

  const BadgeComponent = <Badge {...props} className={`${props.className} task-badge`} />;

  if (!isDivider) return BadgeComponent;
  return <div className="badge-divider">{BadgeComponent}</div>;
};

export default TaskBadge;
