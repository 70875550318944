import React, { FC, useState } from 'react';
import { Input } from 'semantic-ui-react';
import Skeleton from 'react-loading-skeleton';

import { translations } from '../../_translations';
import { Icon } from '../../_shared';
import { IEmployee } from '../_models/employee';
import { formatTime } from '../../_utils/timeHelper';

import './employeesSearch.scss';

interface Props {
  onSelect: (employee: IEmployee) => void;
  isLoading: boolean;
  employees?: IEmployee[];
}

function searchEmployees(searchValue: string, employees?: IEmployee[]): IEmployee[] {
  return (employees || []).filter(
    employee => searchValue.length < 2 || employee.name.toLowerCase().includes(searchValue.toLowerCase()),
  );
}

const EmployeesSearch: FC<Props> = ({ employees, onSelect, isLoading }) => {
  const [searchValue, setSearchValue] = useState('');
  const employeeSearchResults = searchEmployees(searchValue, employees);
  return (
    <div className="employees-search">
      <Input
        placeholder={translations.getLabel('SEARCH_EMPLOYEE_PLACEHOLDER')}
        fluid
        iconPosition="left"
        icon={<Icon name="SvgSearch" size={1.8} />}
        value={searchValue}
        onChange={(_, data) => setSearchValue(data.value)}
      />
      {searchValue && <Icon name="SvgCloseCircle" size={2} onClick={() => setSearchValue('')} />}
      <div className="employees-wrapper">
        {isLoading && <Skeleton />}
        {!isLoading &&
          !!employeeSearchResults.length &&
          employeeSearchResults.map(employee => (
            <button key={employee.id} className="employee-option" onClick={() => onSelect(employee)}>
              <span>{employee.name}</span>
              <span>{`${formatTime(employee.shiftStart)} - ${formatTime(employee.shiftEnd)}`}</span>
            </button>
          ))}
        {!isLoading && !employeeSearchResults.length && (
          <span className="no-employees">{translations.getLabel('NO_EMPLOYEES_FOUND')}</span>
        )}
      </div>
    </div>
  );
};

export default EmployeesSearch;
