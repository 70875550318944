import { SidebarType, ISidebarData } from '../_models';
import { ActionType, Actions } from './actions';

export interface SidebarState {
  isOpen: boolean;
  data?: ISidebarData;
  type?: SidebarType;
}

const initialState: SidebarState = {
  isOpen: false,
  data: {},
  type: null,
};

const actionTypeToSidebarType = {
  [ActionType.ShowSidebarTaskDetail]: SidebarType.TaskDetail,
  [ActionType.ShowSidebarTaskEdit]: SidebarType.TaskEdit,
  [ActionType.ShowSidebarTaskIssues]: SidebarType.TaskIssues,
  [ActionType.ShowSidebarTaskIssueDetail]: SidebarType.TaskIssueDetail,
};

export default function reducer(state = initialState, action: Actions): SidebarState {
  switch (action.type) {
    case ActionType.ShowSidebarTaskDetail:
    case ActionType.ShowSidebarTaskIssues:
    case ActionType.ShowSidebarTaskIssueDetail:
    case ActionType.ShowSidebarTaskEdit:
      return {
        ...state,
        isOpen: true,
        type: actionTypeToSidebarType[action.type],
        data: action.payload ? action.payload.data : {},
      };
    case ActionType.CloseSidebar:
      return {
        ...state,
        isOpen: false,
        data: {},
      };
    default:
      return state;
  }
}
