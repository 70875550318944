import React, { ReactNode, FC } from 'react';

import './sidebarFooter.scss';

interface Props {
  children: ReactNode;
}

const SidebarFooter: FC<Props> = ({ children }) => {
  return <footer className="sidebar-footer">{children}</footer>;
};

export default SidebarFooter;
