import React from 'react';
import { XYCoord, useDragLayer } from 'react-dnd';

import Task from '../tasks/task/Task';
import { DragType } from './_model';
import { IDragDropTask } from './_model/DragDropTask';

import './customDragLayer.scss';

interface CustomDragLayerCollection {
  item: IDragDropTask;
  isDragging: boolean;
  initialOffset?: XYCoord;
  currentOffset?: XYCoord;
}

const CustomDragLayer: React.FC = () => {
  const layerProps = useDragLayer<CustomDragLayerCollection>(monitor => ({
    item: monitor.getItem(),
    isDragging: monitor.isDragging(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
  }));

  function getPreviewCSS(layerProps: CustomDragLayerCollection) {
    const { initialOffset, currentOffset } = layerProps;
    if (!initialOffset || !currentOffset) return { display: 'none' };

    // Only drag on Y axis
    const transform = `translate(${initialOffset.x}px, ${currentOffset.y}px)`;
    return {
      transform,
      WebkitTransform: transform,
    };
  }

  function renderPreview() {
    const { item } = layerProps;
    switch (item.type) {
      case DragType.Task:
        return <Task task={item.task} teamId={item.teamId} />;
      default:
        return null;
    }
  }

  if (!layerProps.isDragging) return null;
  return (
    <div className="custom-drag-layer">
      <div style={getPreviewCSS(layerProps)}>{renderPreview()}</div>
    </div>
  );
};

export default CustomDragLayer;
