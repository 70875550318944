import { createSelector } from 'reselect';

import { AppState } from '../../_store/rootReducer';
import { TeamsState } from './reducer';

const selectNode = (state: AppState) => state.teams;

export const isLoading = createSelector(selectNode, (state: TeamsState) => state.isLoading);

export const teams = createSelector(selectNode, (state: TeamsState) => state.teams);

export const error = createSelector(selectNode, (state: TeamsState) => state.error);

export const isEditTeamLoading = createSelector(selectNode, (state: TeamsState) => state.isEditTeamLoading);

export const errorEditTeam = createSelector(selectNode, (state: TeamsState) => state.errorEditTeam);

export const isGetEmployeesLoading = createSelector(selectNode, (state: TeamsState) => state.isGetEmployeesLoading);

export const errorGetEmployees = createSelector(selectNode, (state: TeamsState) => state.errorGetEmployees);

export const isPlanTeamLoading = createSelector(selectNode, (state: TeamsState) => state.isPlanTeamLoading);

export const errorPlanTeam = createSelector(selectNode, (state: TeamsState) => state.errorPlanTeam);

export const employees = createSelector(selectNode, (state: TeamsState) => state.employees);

export const getTeam = (state: AppState, teamId: string) => {
  return teams(state).find(({ id }) => id === teamId);
};

export const isRemoveTeamLoading = createSelector(selectNode, (state: TeamsState) => state.isRemoveTeamLoading);

export const errorRemoveTeam = createSelector(selectNode, (state: TeamsState) => state.errorRemoveTeam);

export const isRemoveTeamMemberLoading = createSelector(selectNode, (state: TeamsState) => state.isRemoveTeamMemberLoading);

export const errorRemoveTeamMember = createSelector(selectNode, (state: TeamsState) => state.errorRemoveTeamMember);
