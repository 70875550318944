import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useToggle, useActionPromise } from '../../../_hooks';
import { translations } from '../../../_translations';
import { Button, ErrorMessage, Modal } from '../../../_shared';
import { RemoveTeamMemberAction } from '../../_store/actions';
import { teamsSelectors } from '../../../_store/selectors';

interface Props {
  teamId: string;
  memberId: string;
  hideModal: () => void;
}

const RemoveTeamMemberModal: FC<Props> = ({ teamId, memberId, hideModal }) => {
  const isLoading = useSelector(teamsSelectors.isRemoveTeamMemberLoading);
  const error = useSelector(teamsSelectors.errorRemoveTeamMember);
  const dispatch = useDispatch();

  const [isError, toggleError] = useToggle(false);

  useActionPromise(isLoading, hideModal, toggleError, error);

  return (
    <Modal className="remove-team-member-modal" open onClose={hideModal}>
      <Modal.Header>{translations.getLabel('REMOVE_TEAM_MEMBER')}</Modal.Header>
      <Modal.Content>
        <p>{translations.getLabel('CONFIRM_REMOVE_TEAM_MEMBER')}</p>
        <ErrorMessage isVisible={isError}>{translations.getLabel('REMOVE_TEAM_MEMBER_ERROR')}</ErrorMessage>
      </Modal.Content>
      <Modal.Actions>
        <Button isTextLink onClick={hideModal}>
          {translations.getLabel('CANCEL')}
        </Button>
        <Button negative loading={isLoading} onClick={() => dispatch(new RemoveTeamMemberAction({ teamId, memberId }))}>
          {translations.getLabel('REMOVE')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default RemoveTeamMemberModal;
