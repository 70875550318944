import { Action } from 'redux';

import { ApiError } from '../../_http';
import { IIssue } from '../_models/issue';

export enum ActionType {
  GetIssues = '[Issues] GetIssues',
  GetIssuesSuccess = '[Issues] GetIssuesSuccess',
  GetIssuesError = '[Issues] GetIssuesError',
  MarkIssueAsRead = '[Issues] MarkIssueAsRead',
  MarkIssueAsReadSuccess = '[Issues] MarkIssueAsReadSuccess',
  MarkIssueAsReadError = '[Issues] MarkIssueAsReadError',
}

export class GetIssuesAction implements Action<ActionType> {
  readonly type = ActionType.GetIssues;
  constructor(public payload: { taskId: string }) {}
}

export class GetIssuesSuccessAction implements Action<ActionType> {
  readonly type = ActionType.GetIssuesSuccess;
  constructor(public payload: { data: IIssue[] }) {}
}

export class GetIssuesErrorAction implements Action<ActionType> {
  readonly type = ActionType.GetIssuesError;
  constructor(public payload: { error: ApiError }) {}
}

export class MarkIssueAsReadAction implements Action<ActionType> {
  readonly type = ActionType.MarkIssueAsRead;
  constructor(public payload: { taskId: string; issueId: string }) {}
}

export class MarkIssueAsReadSuccessAction implements Action<ActionType> {
  readonly type = ActionType.MarkIssueAsReadSuccess;
  constructor(public payload: { taskId: string }) {}
}

export class MarkIssueAsReadErrorAction implements Action<ActionType> {
  readonly type = ActionType.MarkIssueAsReadError;
  constructor(public payload: { error: ApiError }) {}
}

export type Actions =
  | GetIssuesAction
  | GetIssuesSuccessAction
  | GetIssuesErrorAction
  | MarkIssueAsReadAction
  | MarkIssueAsReadSuccessAction
  | MarkIssueAsReadErrorAction;
