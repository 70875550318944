export enum SidebarType {
  TaskDetail = 'TASK_DETAIL',
  TaskEdit = 'TASK_EDIT',
  TaskIssues = 'TASK_ISSUES',
  TaskIssueDetail = 'TASK_ISSUE_DETAIL',
}

export interface ISidebarData {
  taskId?: string;
  teamId?: string;
  issueId?: string;
}
