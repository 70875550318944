import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { isBefore } from 'date-fns';

import { ITeam } from '../_models/team';
import TeamInfo from '../teamInfo/TeamInfo';
import TeamMemberInfo from '../teamMemberInfo/TeamMemberInfo';
import AddTeamMemberModal from '../modals/addTeamMemberModal/AddTeamMemberModal';
import { Icon } from '../../_shared';
import { translations } from '../../_translations';
import { ITeamMember } from '../_models/teamMember';
import { dateFromISOString } from '../../_utils/timeHelper';
import { useModal } from '../../_hooks';
import { GetEmployeesAction } from '../_store/actions';

import './teamRow.scss';

interface Props {
  team: ITeam;
  isLoading: boolean;
}

function checkShiftOverlap(member: ITeamMember, team: ITeam): boolean {
  const parseDate = (iso: string) => {
    const date = dateFromISOString(iso);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  };
  const shiftStartEarlier = isBefore(parseDate(member.shiftStart), parseDate(team.validFrom));
  const shiftEndsLater = isBefore(parseDate(team.validTo), parseDate(member.shiftEnd));
  return shiftStartEarlier || shiftEndsLater;
}

const addTeamMemberButton = (onClick: () => void) => (
  <button className="add-team-member-button" onClick={onClick}>
    <Icon name="SvgAdd" size={1.6} />
    <span>{translations.getLabel('ADD')}</span>
  </button>
);

const TeamRow: FC<Props> = ({ team, isLoading }) => {
  const dispatch = useDispatch();
  const [renderAddTeamMemberModal, showAddTeamMemberModal] = useModal(
    modalProps => <AddTeamMemberModal team={team} {...modalProps} />,
    () => dispatch(new GetEmployeesAction({ teamId: team.id })),
  );
  const [renderAddPdaHolderModal, showAddPdaHolderModal] = useModal(
    modalProps => <AddTeamMemberModal team={team} isPdaHolder {...modalProps} />,
    () => dispatch(new GetEmployeesAction({ teamId: team.id })),
  );
  const teamMembers = (team && team.members) || [];
  const pdaUser = teamMembers.find(member => member.isPdaUser);

  return (
    <div className="team-row" key={team.id}>
      <TeamInfo key={team.id} team={team} isLoading={isLoading} />
      <div className="pda-user-wrapper">
        {pdaUser ? <TeamMemberInfo team={team} teamMember={pdaUser} /> : addTeamMemberButton(showAddPdaHolderModal)}
      </div>
      <div className="other-members-wrapper">
        {teamMembers
          .filter(member => !member.isPdaUser)
          .map(member => (
            <TeamMemberInfo key={member.id} team={team} teamMember={member} hasShiftOverlap={checkShiftOverlap(member, team)} />
          ))}
        {!!teamMembers.length && addTeamMemberButton(showAddTeamMemberModal)}
      </div>
      {renderAddTeamMemberModal()}
      {renderAddPdaHolderModal()}
    </div>
  );
};

export default TeamRow;
