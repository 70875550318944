import { addMinutes, isValid, isSameDay } from 'date-fns';

import { ITask, CleaningProcedureDurations, CleaningProcedureType } from '../../tasks/_models/task';
import { IEditTask } from '../../tasks/_models/editTask';
import { dateFromTime, formatTime, dateFromString, formatDate } from '../../_utils/timeHelper';

export const updateEndTime = (
  form: IEditTask,
  setForm: (IEditTask) => void,
  prevProps: { startTime?: string; cleaningProcedure?: CleaningProcedureType },
  task?: ITask,
) => {
  if (task && prevProps && (prevProps.startTime !== form.startTime || prevProps.cleaningProcedure !== form.cleaningProcedure)) {
    const newEndTime = addMinutes(dateFromTime(form.startTime), CleaningProcedureDurations[form.cleaningProcedure]);
    if (isValid(newEndTime) && formatTime(newEndTime) !== form.endTime) {
      setForm({ ...form, endTime: formatTime(newEndTime) });
      if (!isSameDay(newEndTime, dateFromString(form.endDate))) {
        setForm({ ...form, endDate: formatDate(newEndTime) });
      }
    }
  }
};
