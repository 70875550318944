import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';

import { Icon } from '../_shared';
import { sidebarSelectors } from '../_store/selectors';
import { translations } from '../_translations';
import { CloseSidebarAction } from './_store/actions';
import { SidebarType } from './_models';
import TaskDetail from './taskDetail/TaskDetail';
import TaskEdit from './taskEdit/TaskEdit';
import TaskIssues from './taskIssues/TaskIssues';
import TaskIssueDetail from './taskIssueDetail/TaskIssueDetail';

import './sidebar.scss';

const Sidebar = () => {
  const isOpen = useSelector(sidebarSelectors.isOpen);
  const data = useSelector(sidebarSelectors.data);
  const type = useSelector(sidebarSelectors.type);
  const dispatch = useDispatch();

  useEffect(() => {
    const downHandler = ({ keyCode }) => {
      if (keyCode === 27) dispatch(new CloseSidebarAction());
    };
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const className = classnames('sidebar', {
    open: isOpen,
  });

  return (
    <aside className={className}>
      <Icon
        name="SvgClose"
        aria-label={translations.getLabel('CLOSE')}
        size={2.4}
        onClick={() => dispatch(new CloseSidebarAction())}
      />
      {isOpen && (
        <>
          {type === SidebarType.TaskDetail && <TaskDetail data={data} />}
          {type === SidebarType.TaskEdit && <TaskEdit data={data} />}
          {type === SidebarType.TaskIssues && <TaskIssues data={data} />}
          {type === SidebarType.TaskIssueDetail && <TaskIssueDetail data={data} />}
        </>
      )}
    </aside>
  );
};

export default Sidebar;
