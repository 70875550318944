import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { matchPath, Route, RouteComponentProps, Switch } from 'react-router-dom';

import { Button, Icon, SwitchButton } from '../_shared';
import { translations } from '../_translations';
import Tasks from '../tasks/Tasks';
import Teams from '../teams/Teams';
import { usePolling, useModal } from '../_hooks';
import { tasksSelectors } from '../_store/selectors';
import { RELOAD_INTERVAL } from '../_constants';
import { ToggleAllTaskGroupsAction } from '../tasks/_store/actions';
import { ShowSidebarTaskEditAction } from '../sidebar/_store/actions';
import EditTeamModal from '../teams/modals/editTeamModal/EditTeamModal';
import { PollRealtimeDataAction } from './_store/actions';
import CurrentDateNavigator from './currentDateNavigator/CurrentDateNavigator';

import './planning.scss';

const Planning: FC<RouteComponentProps> = ({ match, location }) => {
  const hasTaskGroups = useSelector(tasksSelectors.hasTaskGroups);
  const hasExpandedTaskGroups = useSelector(tasksSelectors.hasExpandedTaskGroups);
  const dispatch = useDispatch();
  usePolling(RELOAD_INTERVAL, () => dispatch(new PollRealtimeDataAction()));
  const [renderCreateTeamModal, showCreateTeamModal] = useModal(modalProps => <EditTeamModal {...modalProps} />);

  const isTeamsActive = !!matchPath(location.pathname, {
    path: `${match.url}/teams`,
  });
  const toggleOptions = [
    {
      key: 'tasks',
      label: translations.getLabel('TASKS'),
      handleClick: () => dispatch(push(match.url)),
    },
    {
      key: 'teams',
      label: translations.getLabel('TEAMS'),
      handleClick: () => dispatch(push(`${match.url}/teams`)),
    },
  ];
  return (
    <div className="planning-wrapper">
      <div className="planning-button-bar">
        <SwitchButton options={toggleOptions} selectedValue={isTeamsActive ? 'teams' : 'tasks'} />
        <CurrentDateNavigator />
        <Switch>
          {/* Teams buttons */}
          <Route
            path={`${match.url}/teams`}
            render={() => (
              <div>
                <Button onClick={showCreateTeamModal} negative>
                  <Icon name="SvgAdd" size={2.2} />
                  <span>{translations.getLabel('NEW_TEAM')}</span>
                </Button>
                {renderCreateTeamModal()}
              </div>
            )}
          />
          {/* Tasks buttons */}
          <Route
            render={() => (
              <div>
                <Button
                  primary
                  disabled={!hasTaskGroups}
                  onClick={() => dispatch(new ToggleAllTaskGroupsAction({ isOpen: !hasExpandedTaskGroups }))}
                >
                  {translations.getLabel(hasExpandedTaskGroups ? 'TASK_GROUPS_COLLAPSE' : 'TASK_GROUPS_EXPAND')}
                </Button>
                <Button negative onClick={() => dispatch(new ShowSidebarTaskEditAction())}>
                  <Icon name="SvgAdd" size={2.2} />
                  <span>{translations.getLabel('NEW_TASK')}</span>
                </Button>
              </div>
            )}
          />
        </Switch>
      </div>
      <Switch>
        {/* Teams */}
        <Route path={`${match.url}/teams`} component={Teams} />
        {/* Tasks */}
        <Route component={Tasks} />
      </Switch>
    </div>
  );
};

export default Planning;
