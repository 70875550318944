import React, { FC, ReactNode } from 'react';

import Icon from '../icon/Icon';

import styles from './errorMessage.module.scss';

interface Props {
  children: ReactNode;
  isVisible: boolean;
}

const ErrorMessage: FC<Props> = ({ children, isVisible }) => {
  if (isVisible && !!children)
    return (
      <div className={`${styles.errorMessage} error-message`}>
        <Icon size={2} name="SvgAlertCircle" />
        <span>{children}</span>
      </div>
    );
  return null;
};

export default ErrorMessage;
