import { useEffect, useState, ChangeEvent } from 'react';

interface Response {
  setInputRef: (ref: HTMLInputElement) => void;
  remainCursorPositionOnChange: (event: ChangeEvent<HTMLInputElement>, normalizedValue: string) => void;
}

const useRemainInputCursorPosition = (): Response => {
  const [inputRef, setInputRef] = useState<HTMLInputElement>();
  const [position, setPosition] = useState<number>(0);

  useEffect(() => {
    if (inputRef) inputRef.setSelectionRange(position, position);
  }, [inputRef, position]);

  const remainCursorPositionOnChange = (event: ChangeEvent<HTMLInputElement>, normalizedValue: string) => {
    const valueHasBeenNormalized =
      event.target.value &&
      event.target.value.length < normalizedValue.length &&
      event.target.selectionStart === event.target.value.length;
    if (valueHasBeenNormalized) setPosition(event.target.selectionStart + (normalizedValue.length - event.target.value.length));
    else setPosition(event.target.selectionStart);
  };

  return {
    setInputRef,
    remainCursorPositionOnChange,
  };
};

export default useRemainInputCursorPosition;
