import { Epic } from 'redux-observable';
import { of } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';

import { GetTeamsAction } from '../../teams/_store/actions';
import { GetTasksAction } from '../../tasks/_store/actions';
import { planningSelectors } from '../../_store/selectors';
import { ActionType } from './actions';

export const pollRealtimeDataEpic$: Epic = (action$, state$) =>
  action$.ofType(ActionType.PollRealtimeData).pipe(
    exhaustMap(() => {
      const date = planningSelectors.currentDate(state$.value);
      return of(new GetTasksAction({ date }), new GetTeamsAction({ date }));
    }),
  );

const PlanningEpics = [pollRealtimeDataEpic$];

export default PlanningEpics;
