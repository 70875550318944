import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Modal, Button, ErrorMessage } from '../../../_shared';
import { translations } from '../../../_translations';
import { PlanTeamAction } from '../../_store/actions';
import { ITeam } from '../../_models/team';
import { ITeamMember } from '../../_models/teamMember';
import { useActionPromise } from '../../../_hooks';
import { teamsSelectors } from '../../../_store/selectors';

interface Props {
  hideModal: () => void;
  team: ITeam;
  member: ITeamMember;
}

const ChangeTeamMembersShiftsModal: FC<Props> = ({ hideModal, team, member }) => {
  const isLoading = useSelector(teamsSelectors.isPlanTeamLoading);
  const error = useSelector(teamsSelectors.errorPlanTeam);
  const dispatch = useDispatch();
  useActionPromise(isLoading, hideModal, () => {}, error);

  return (
    <Modal open onClose={hideModal}>
      <Modal.Header>{translations.getLabel('CHANGE_SHIFT')}</Modal.Header>
      <Modal.Content>
        <p>{translations.getLabel('CHANGE_SHIFT_MESSAGE')}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          onClick={() => {
            dispatch(new PlanTeamAction({ team, member, pdaUser: null, shouldPlanAllMembers: true }));
          }}
        >
          {translations.getLabel('ALL_TEAM_MEMBERS')}
        </Button>
        <Button primary onClick={hideModal}>
          {translations.getLabel('ONLY_PDA_USER')}
        </Button>
        <ErrorMessage isVisible={!!error}>{translations.getLabel('PLAN_TEAM_ERROR')}</ErrorMessage>
      </Modal.Actions>
    </Modal>
  );
};

export default ChangeTeamMembersShiftsModal;
