import { Action } from 'redux';

import { ITask } from '../_models/task';
import { shouldConfirmPlanTask } from '../_models/rules';
import { IEditTask } from '../_models/editTask';
import { ApiError } from '../../_http';

export enum ActionType {
  GetTasks = '[Tasks] GetTasks',
  GetTasksSuccess = '[Tasks] GetTasksSuccess',
  GetTasksError = '[Tasks] GetTasksError',
  EditTask = '[Tasks] EditTask',
  EditTaskSuccess = '[Tasks] EditTaskSuccess',
  EditTaskError = '[Tasks] EditTaskError',
  PlanTask = '[Tasks] PlanTask',
  PlanTaskSuccess = '[Tasks] PlanTaskSuccess',
  PlanTaskError = '[Tasks] PlanTaskError',
  PlanTaskCancel = '[Tasks] PlanTaskCancel',
  RemoveTask = '[Tasks] RemoveTask',
  RemoveTaskSuccess = '[Tasks] RemoveTaskSuccess',
  RemoveTaskError = '[Tasks] RemoveTaskError',
  EndTask = '[Tasks] EndTask',
  EndTaskSuccess = '[Tasks] EndTaskSuccess',
  EndTaskError = '[Tasks] EndTaskError',
  StartTask = '[Tasks] StartTask',
  StartTaskSuccess = '[Tasks] StartTaskSuccess',
  StartTaskError = '[Tasks] StartTaskError',
  ToggleTaskGroup = '[Tasks] ToggleTaskGroup',
  ToggleAllTaskGroups = '[Tasks] ToggleAllTaskGroups',
}

export class GetTasksAction implements Action<ActionType> {
  readonly type = ActionType.GetTasks;
  constructor(public payload: { date: Date; visibleRefresh?: boolean }) {}
}

export class GetTasksSuccessAction implements Action<ActionType> {
  readonly type = ActionType.GetTasksSuccess;
  constructor(public payload: { data: ITask[] }) {}
}

export class GetTasksErrorAction implements Action<ActionType> {
  readonly type = ActionType.GetTasksError;
  constructor(public payload: { error: ApiError }) {}
}

export class EditTaskAction implements Action<ActionType> {
  readonly type = ActionType.EditTask;
  constructor(public payload: { data: IEditTask; taskId?: string }) {}
}

export class EditTaskSuccessAction implements Action<ActionType> {
  readonly type = ActionType.EditTaskSuccess;
  constructor(public payload: { isNewTask: boolean; taskName: string }) {}
}

export class EditTaskErrorAction implements Action<ActionType> {
  readonly type = ActionType.EditTaskError;
  constructor(public payload: { error: ApiError }) {}
}

export class PlanTaskAction implements Action<ActionType> {
  readonly type = ActionType.PlanTask;
  constructor(
    public payload: { task: ITask; plannedTeamId?: string; unplannedTeamIds?: string[]; mainTeamId?: string; startTime?: string },
    public confirmed?: boolean,
  ) {
    this.confirmed = confirmed || !shouldConfirmPlanTask(payload.task, payload.unplannedTeamIds);
  }
}

export class PlanTaskSuccessAction implements Action<ActionType> {
  readonly type = ActionType.PlanTaskSuccess;
  constructor(public payload: { taskId: string }) {}
}

export class PlanTaskErrorAction implements Action<ActionType> {
  readonly type = ActionType.PlanTaskError;
  constructor(public payload: { error: ApiError; taskId: string }) {}
}

export class PlanTaskCancelAction implements Action<ActionType> {
  readonly type = ActionType.PlanTaskCancel;
  constructor(public payload: { taskId: string }) {}
}

export class RemoveTaskAction implements Action<ActionType> {
  readonly type = ActionType.RemoveTask;
  constructor(public payload: { taskId: string }) {}
}

export class RemoveTaskSuccessAction implements Action<ActionType> {
  readonly type = ActionType.RemoveTaskSuccess;
  constructor(public payload: { taskId: string }) {}
}

export class RemoveTaskErrorAction implements Action<ActionType> {
  readonly type = ActionType.RemoveTaskError;
  constructor(public payload: { error: ApiError }) {}
}

export class EndTaskAction implements Action<ActionType> {
  readonly type = ActionType.EndTask;
  constructor(public payload: { taskId: string }) {}
}

export class EndTaskSuccessAction implements Action<ActionType> {
  readonly type = ActionType.EndTaskSuccess;
}

export class EndTaskErrorAction implements Action<ActionType> {
  readonly type = ActionType.EndTaskError;
  constructor(public payload: { error: ApiError }) {}
}

export class StartTaskAction implements Action<ActionType> {
  readonly type = ActionType.StartTask;
  constructor(public payload: { taskId: string }) {}
}

export class StartTaskSuccessAction implements Action<ActionType> {
  readonly type = ActionType.StartTaskSuccess;
}

export class StartTaskErrorAction implements Action<ActionType> {
  readonly type = ActionType.StartTaskError;
  constructor(public payload: { error: ApiError }) {}
}

export class ToggleTaskGroupAction implements Action<ActionType> {
  readonly type = ActionType.ToggleTaskGroup;
  constructor(public payload: { taskIds: string[]; isOpen: boolean; teamId: string }) {}
}

export class ToggleAllTaskGroupsAction implements Action<ActionType> {
  readonly type = ActionType.ToggleAllTaskGroups;
  constructor(public payload: { isOpen: boolean }) {}
}

export type Actions =
  | GetTasksAction
  | GetTasksSuccessAction
  | GetTasksErrorAction
  | EditTaskAction
  | EditTaskSuccessAction
  | EditTaskErrorAction
  | PlanTaskAction
  | PlanTaskSuccessAction
  | PlanTaskErrorAction
  | PlanTaskCancelAction
  | RemoveTaskAction
  | RemoveTaskSuccessAction
  | RemoveTaskErrorAction
  | EndTaskAction
  | EndTaskSuccessAction
  | EndTaskErrorAction
  | StartTaskAction
  | StartTaskSuccessAction
  | StartTaskErrorAction
  | ToggleTaskGroupAction
  | ToggleAllTaskGroupsAction;
