import React from 'react';
import { isSameDay } from 'date-fns';
import { useSelector } from 'react-redux';

import { timeHelper } from '../../_utils';
import { useCurrentTime } from '../../_hooks';
import { planningSelectors } from '../../_store/selectors';

import './timeLine.scss';

const TimeLine = () => {
  const currentDate = useSelector(planningSelectors.currentDate);

  const currentTime = useCurrentTime();

  return (
    isSameDay(currentDate, currentTime) && (
      <time className="current-time" style={{ left: `${timeHelper.getDateOffset(currentTime)}px` }} />
    )
  );
};

export default TimeLine;
