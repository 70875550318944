import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { exhaustMap, map, catchError } from 'rxjs/operators';

import { activityLogSelectors } from '../../_store/selectors';
import { activityLogApi } from '../../_store/api';
import { downloadHelper } from '../../_utils';
import {
  ActionType,
  GetActivityLogAction,
  ExportActivityLogSuccessAction,
  GetActivityLogSuccessAction,
  GetActivityLogErrorAction,
  ExportActivityLogErrorAction,
} from './actions';

export const getActivityLogEpic$: Epic = (action$, state$) =>
  action$.ofType(ActionType.GetActivityLog).pipe(
    exhaustMap(({ payload }: GetActivityLogAction) => {
      const filter = activityLogSelectors.filter(state$.value);
      return from(activityLogApi.getActivityLog(payload && payload.offset, filter)).pipe(
        map(
          result => new GetActivityLogSuccessAction({ offset: payload && payload.offset, data: result.data, meta: result.meta }),
        ),
        catchError(error => of(new GetActivityLogErrorAction({ error }))),
      );
    }),
  );

export const setActivityLogFilterEpic$: Epic = action$ =>
  action$.ofType(ActionType.SetActivityLogFilter).pipe(map(() => new GetActivityLogAction()));

export const exportActivityLogEpic$: Epic = (action$, state$) =>
  action$.ofType(ActionType.ExportActivityLog).pipe(
    exhaustMap(() => {
      const filter = activityLogSelectors.filter(state$.value);
      return from(activityLogApi.exportActivityLog(filter)).pipe(
        map((file: Blob) => new ExportActivityLogSuccessAction({ name: 'task_history_export.xls', file })),
        catchError(error => of(new ExportActivityLogErrorAction({ error }))),
      );
    }),
  );

export const exportActivityLogSuccessEpic$: Epic = action$ =>
  action$.ofType(ActionType.ExportActivityLogSuccess).pipe(
    exhaustMap(({ payload }: ExportActivityLogSuccessAction) => {
      downloadHelper.downloadFile(payload);
      return of();
    }),
  );

const TeamsEpics = [getActivityLogEpic$, setActivityLogFilterEpic$, exportActivityLogEpic$, exportActivityLogSuccessEpic$];

export default TeamsEpics;
