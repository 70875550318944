import { ITeam } from '../_models/team';
import { ISOStringFromDate } from '../../_utils/timeHelper';
import { HttpClient } from '../../_http';
import { ITeamMember } from '../_models/teamMember';
import { IEmployee } from '../_models/employee';

export function getTeams(from: Date, to: Date): Promise<ITeam[]> {
  return HttpClient.get<ITeam[]>('teams', { from: ISOStringFromDate(from), to: ISOStringFromDate(to) });
}

export function editTeam(name: string, teamId?: string, day?: Date): Promise<void> {
  if (teamId) {
    return HttpClient.put(`teams/${teamId}`, HttpClient.parseRequestPayload({ name }));
  }
  return HttpClient.post('teams', HttpClient.parseRequestPayload({ name, day }));
}

export function removeTeam(teamId: string): Promise<void> {
  return HttpClient.delete(`teams/${teamId}`);
}

export function getEmployees(teamId: string, day: Date): Promise<IEmployee[]> {
  return HttpClient.get<IEmployee[]>(`cleaner_availabilities?excludeTeam=${teamId}&day=${ISOStringFromDate(day)}`);
}

export function planTeam(team: ITeam, members: ITeamMember[], pdaUser?: string): Promise<ITeam> {
  return HttpClient.post<ITeam>(`teams/${team.id}/plan`, { members, pdaUser: pdaUser || team.pdaUser });
}
