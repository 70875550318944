import { createSelector } from 'reselect';

import { AppState } from '../../_store/rootReducer';
import { ITask } from '../_models/task';
import { ITaskGroup } from '../_models/taskGroup';
import { TasksState } from './reducer';

const selectNode = (state: AppState) => state.tasks;

export const isLoading = createSelector(selectNode, (state: TasksState) => state.isLoading);

export const tasksUnplanned = createSelector(selectNode, (state: TasksState) => state.tasksUnplanned);

export const tasksPlanned = createSelector(selectNode, (state: TasksState) => state.tasksPlanned);

export const taskGroups = createSelector(selectNode, (state: TasksState) => state.taskGroups);

export const hasTaskGroups = createSelector(
  selectNode,
  (state: TasksState) => Object.values(state.taskGroups).flatMap(ids => ids).length > 0,
);

export const hasExpandedTaskGroups = createSelector(selectNode, (state: TasksState) =>
  Object.keys(state.taskGroups).some(teamId =>
    state.taskGroups[teamId].some(group => !group.taskIds.some(taskId => state.collapsedTaskIds[teamId].includes(taskId))),
  ),
);

export const collapsedTaskIds = createSelector(selectNode, (state: TasksState) => state.collapsedTaskIds);

export const tasksPlannedFlat = createSelector(selectNode, (state: TasksState) =>
  Object.values(state.tasksPlanned).flatMap(tasks => tasks),
);

export const getAllTasks = createSelector(
  [tasksUnplanned, tasksPlannedFlat],
  (tasksUnplanned: ITask[], tasksPlanned: ITask[]) => [...tasksPlanned, ...tasksUnplanned],
);

export const getTask = (state: AppState, taskId: string) => {
  return getAllTasks(state).find(task => task.id === taskId);
};

export const getTasksForTeam = (state: AppState, teamId: string): ITask[] => {
  const tasks = tasksPlanned(state);
  return (tasks && tasks[teamId]) || [];
};

export const getTaskGroupsForTeam = (state: AppState, teamId: string): ITaskGroup[] => {
  const groups = taskGroups(state);
  return (groups && groups[teamId]) || [];
};

export const collapsedTaskIdsForTeam = (state: AppState, teamId: string): string[] => {
  const taskIds = collapsedTaskIds(state);
  return (taskIds && taskIds[teamId]) || [];
};

export const isEditTaskLoading = createSelector(selectNode, (state: TasksState) => state.isEditTaskLoading);

export const errorEditTask = createSelector(selectNode, (state: TasksState) => state.errorEditTask);

export const isPlanTaskLoading = createSelector(selectNode, (state: TasksState) => state.isPlanTaskLoading);

export const errorPlanTask = createSelector(selectNode, (state: TasksState) => state.errorPlanTask);

export const loadingPlanTaskIds = createSelector(selectNode, (state: TasksState) => state.loadingPlanTaskIds);

export const isRemoveTaskLoading = createSelector(selectNode, (state: TasksState) => state.isRemoveTaskLoading);

export const errorRemoveTask = createSelector(selectNode, (state: TasksState) => state.errorRemoveTask);

export const isEndTaskLoading = createSelector(selectNode, (state: TasksState) => state.isEndTaskLoading);

export const errorEndTask = createSelector(selectNode, (state: TasksState) => state.errorEndTask);

export const isStartTaskLoading = createSelector(selectNode, (state: TasksState) => state.isStartTaskLoading);

export const errorStartTask = createSelector(selectNode, (state: TasksState) => state.errorEndTask);
