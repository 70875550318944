import { ApiError } from '../../_http';
import { ActionType, Actions } from './actions';

export interface AuthState {
  isLoading: boolean;
  isLoggedIn: boolean;
  errorLogin?: ApiError;
}

const initialState: AuthState = {
  isLoading: false,
  isLoggedIn: false,
  errorLogin: null,
};

export default function reducer(state = initialState, action: Actions): AuthState {
  switch (action.type) {
    case ActionType.Login:
      return {
        ...state,
        isLoading: false,
        errorLogin: null,
      };
    case ActionType.LoginSuccess:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
      };
    case ActionType.LoginError:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        errorLogin: action.payload.error,
      };
    default:
      return state;
  }
}
