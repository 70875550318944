import React, { FC, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useToggle, useActionPromise } from '../../../_hooks';
import { translations } from '../../../_translations';
import { Button, ErrorMessage, Modal } from '../../../_shared';
import { ITask } from '../../_models/task';
import { isTaskMainTeam } from '../../_models/rules';
import ChangeTaskMainTeamModal from '../changeTaskMainTeamModal/ChangeTaskMainTeamModal';
import { PlanTaskAction } from '../../_store/actions';
import { tasksSelectors } from '../../../_store/selectors';

interface Props {
  task: ITask;
  teamId?: string;
  trigger: ReactNode;
  actionAfterClose: () => void;
}

const UnplanTaskModal: FC<Props> = ({ trigger, task, teamId, actionAfterClose }) => {
  const isLoading = useSelector(tasksSelectors.isPlanTaskLoading);
  const error = useSelector(tasksSelectors.errorPlanTask);
  const dispatch = useDispatch();

  const [isModalVisible, toggleModal] = useToggle(false);
  const [isError, toggleError] = useToggle(false);
  const [didClickAll, setDidClickAll] = useToggle(false);

  useActionPromise(isLoading, toggleModal, toggleError, error);

  function renderOnlyThisTeamButton() {
    const renderButton = (isLoading: boolean, onClick = () => {}) => (
      <Button primary loading={isLoading} onClick={onClick}>
        {translations.getLabel('MODAL_UNPLAN_TASK_BUTTON_SINGLE')}
      </Button>
    );
    if (isTaskMainTeam(task, teamId) && task.teams.length > 1) {
      return (
        <ChangeTaskMainTeamModal
          task={task}
          teamIdToUnplan={teamId}
          trigger={renderButton(false)}
          actionAfterClose={() => {
            toggleModal();
            actionAfterClose();
          }}
        />
      );
    }
    return renderButton(!didClickAll && isLoading, () => {
      dispatch(new PlanTaskAction({ task, unplannedTeamIds: [teamId] }));
      setDidClickAll(false);
    });
  }

  function formatError(): string {
    if (!error) return null;
    if (error.error === 'INVALID_PLANNING_ERROR') return translations.getLabel('UNPLAN_TASK_INVALID');
    return translations.getLabel('UNPLAN_TASK_ERROR');
  }

  return (
    <Modal open={isModalVisible} onOpen={toggleModal} onClose={toggleModal} trigger={trigger}>
      <Modal.Header>{translations.getLabel('MODAL_UNPLAN_TASK_TITLE')}</Modal.Header>
      <Modal.Content>
        <p>{translations.getLabel('MODAL_UNPLAN_TASK_BODY')}</p>
        <ErrorMessage isVisible={isError}>{formatError()}</ErrorMessage>
      </Modal.Content>
      <Modal.Actions>
        <Button isTextLink onClick={toggleModal}>
          {translations.getLabel('CANCEL')}
        </Button>
        <Button
          primary
          loading={didClickAll && isLoading}
          onClick={() => {
            dispatch(new PlanTaskAction({ task, unplannedTeamIds: task.teams.map(team => team.id) }));
            setDidClickAll(true);
          }}
        >
          {translations.getLabel('MODAL_UNPLAN_TASK_BUTTON_ALL')}
        </Button>
        {renderOnlyThisTeamButton()}
      </Modal.Actions>
    </Modal>
  );
};

export default UnplanTaskModal;
