import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { ITask } from '../_models/task';
import { canTeamTaskBeMoved } from '../_models/rules';
import { DragType } from '../../dragDrop/_model';
import { IDragDropTask, IDragTasksRowCollection } from '../../dragDrop/_model/DragDropTask';
import { tasksSelectors } from '../../_store/selectors';
import Task from './Task';

interface Props {
  task: ITask;
  teamId?: string;
  isLoading: boolean;
  index: number;
}

const DraggableTask: React.FC<Props> = ({ task, teamId, isLoading, index }) => {
  const isPlanningTask = useSelector(tasksSelectors.loadingPlanTaskIds).includes(task.id);

  const [dragProps, dragRef, preview] = useDrag<IDragDropTask, {}, IDragTasksRowCollection>({
    item: { type: DragType.Task, task, teamId },
    canDrag: () => !isPlanningTask && canTeamTaskBeMoved(task, teamId),
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.2 : 1,
    }),
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div ref={dragRef} style={{ opacity: isPlanningTask ? 0.2 : dragProps.opacity }}>
      <Task task={task} teamId={teamId} isLoading={isLoading} index={index} />
    </div>
  );
};

export default DraggableTask;
