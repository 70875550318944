import React, { FC, useState, useEffect, useRef, ReactElement } from 'react';

import { useToggle } from '../../_hooks';
import { sessionStorageHelper } from '../../_utils';

import './resizableGrids.scss';

interface Props {
  children: ReactElement[];
}

const ResizableGrids: FC<Props> = ({ children }) => {
  const [isResizing, toggleIsResizing] = useToggle(false);
  const [initialPos, setInitialPos] = useState<number>(0);
  const [gridHeight, setGridHeight] = useState<number>(sessionStorageHelper.getGridHeight() || 70);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const ref = containerRef.current;
    ref.addEventListener('mousemove', resizePanel);
    window.addEventListener('mouseup', stopResize);
    return () => {
      ref.removeEventListener('mousemove', resizePanel);
      window.removeEventListener('mouseup', stopResize);
    };
  }, [isResizing, containerRef]); // eslint-disable-line react-hooks/exhaustive-deps

  // Save dates to sessionstorage after resizing
  useEffect(() => {
    if (!isResizing) {
      sessionStorageHelper.setGridHeight(gridHeight);
    }
  }, [isResizing]); // eslint-disable-line react-hooks/exhaustive-deps

  const resizePanel = (event: MouseEvent) => {
    if (isResizing) {
      const delta = ((event.clientY - initialPos) / containerRef.current.offsetHeight) * 100;
      const currentGridHeight = gridHeight + delta;
      if (currentGridHeight > 90 || currentGridHeight < 10) return; // Keep a minimum size
      setGridHeight(currentGridHeight);
    }
  };

  const startResize = (event: React.MouseEvent) => {
    toggleIsResizing(true);
    setInitialPos(event.clientY);
  };

  const stopResize = () => {
    if (isResizing) {
      toggleIsResizing(false);
    }
  };

  return (
    <div className="panel-container" ref={containerRef}>
      <div style={{ height: `${gridHeight}%` }}>{React.cloneElement(children[0], { isResizing })}</div>

      <div className="resizer-container">
        <div onMouseDown={startResize} className="resizer" />
      </div>

      <div style={{ height: `calc(100% - ${gridHeight}%)` }}>{React.cloneElement(children[1], { isResizing })}</div>
    </div>
  );
};

export default ResizableGrids;
