import React, { FC } from 'react';
import classNames from 'classnames';

import './switchButton.scss';
interface Props {
  selectedValue?: string;
  options: { key: string; label: string; handleClick: () => void }[];
}

const SwitchButton: FC<Props> = ({ options, selectedValue }) => {
  return (
    <div>
      <div className="button-group">
        {options.map(({ label, key, handleClick }) => (
          <button key={key} onClick={handleClick} className={classNames({ selected: key === selectedValue })}>
            {label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SwitchButton;
