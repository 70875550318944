import React, { FC } from 'react';
import classnames from 'classnames';
import { Table, Loader } from 'semantic-ui-react';

import { translations } from '../_translations';
import { formatDate, formatTime, differenceBetweenDates, dateFromISOString } from '../_utils/timeHelper';
import { useTableSort } from '../_hooks';
import { HttpSortDirection } from '../_http/HttpMetadata';
import { IActivityLogItem } from './_models/activityLogItem';
import { IActivityLogFilter } from './_models/activityLogFilter';

const TABLE_CELL_NO_DATA_SIGN = '/';

interface TableColumn {
  name: string;
  label: string;
  sortable?: boolean;
}

const columns: TableColumn[] = [
  { name: 'startedAt', label: 'HISTORY_TABLE_COLUMN_DATE', sortable: true },
  {
    name: 'teamName',
    label: 'HISTORY_TABLE_COLUMN_TEAM_NAME',
    sortable: true,
  },
  { name: 'teamMembers', label: 'HISTORY_TABLE_COLUMN_TEAM_MEMBERS' },
  { name: 'type', label: 'HISTORY_TABLE_COLUMN_TYPE', sortable: true },
  {
    name: 'flightNumber',
    label: 'HISTORY_TABLE_COLUMN_FLIGHT_NUMBER',
    sortable: true,
  },
  { name: 'imat', label: 'HISTORY_TABLE_COLUMN_IMAT', sortable: true },
  { name: 'description', label: 'HISTORY_TABLE_COLUMN_DESCRIPTION' },
  {
    name: 'startTime',
    label: 'HISTORY_TABLE_COLUMN_START_TIME',
    sortable: true,
  },
  { name: 'endTime', label: 'HISTORY_TABLE_COLUMN_END_TIME', sortable: true },
  { name: 'overTime', label: 'HISTORY_TABLE_COLUMN_OVER_TIME' },
  { name: 'remarks', label: 'REMARKS' },
  { name: 'cleaningProcedure', label: 'CLEANING_PROCEDURE' },
];

interface Props {
  data: IActivityLogItem[];
  isLoading: boolean;
  setFilter: (partialFilter: IActivityLogFilter) => void;
}

const ActivityLogTable: FC<Props> = ({ data, isLoading, setFilter }) => {
  const handleSortColumnChange = (column: string, direction: HttpSortDirection) =>
    setFilter({ sortBy: column, sortDirection: direction });
  const { onChangeSortColumn, sortDirectionForColumn } = useTableSort(handleSortColumnChange);

  function renderOverTimeCell(item: IActivityLogItem) {
    const { value, positive } = differenceBetweenDates(dateFromISOString(item.endedAt), dateFromISOString(item.endTime));
    return <span className={classnames('over-time-cell', { positive })}>{`${positive ? '+' : '-'} ${value}`}</span>;
  }

  function renderRow(item: IActivityLogItem) {
    return (
      <Table.Row key={item.id}>
        <Table.Cell>{formatDate(dateFromISOString(item.startedAt))}</Table.Cell>
        <Table.Cell>{item.teamName}</Table.Cell>
        <Table.Cell>{item.teamMembers.join('\n')}</Table.Cell>
        <Table.Cell>{translations.getLabel(`TASK_TYPE_${item.type}`)}</Table.Cell>
        <Table.Cell>{item.flightNumber || TABLE_CELL_NO_DATA_SIGN}</Table.Cell>
        <Table.Cell>{item.imat || TABLE_CELL_NO_DATA_SIGN}</Table.Cell>
        <Table.Cell className="description-cell">{item.description || TABLE_CELL_NO_DATA_SIGN}</Table.Cell>
        <Table.Cell>{formatTime(item.startedAt)}</Table.Cell>
        <Table.Cell>{formatTime(item.endedAt)}</Table.Cell>
        <Table.Cell>{renderOverTimeCell(item)}</Table.Cell>
        <Table.Cell>
          {!!item.numberOfIssues &&
            `${item.numberOfIssues} ${translations.getLabel(item.numberOfIssues === 1 ? 'ISSUE' : 'ISSUES').toLowerCase()}`}
        </Table.Cell>
        <Table.Cell>{item.cleaningProcedure || TABLE_CELL_NO_DATA_SIGN}</Table.Cell>
      </Table.Row>
    );
  }

  function renderHeaderColumn(column: TableColumn) {
    return (
      <Table.HeaderCell
        key={column.name}
        name={column.name}
        onClick={column.sortable ? onChangeSortColumn : null}
        sorted={column.sortable ? sortDirectionForColumn(column.name) : null}
      >
        {translations.getLabel(column.label)}
      </Table.HeaderCell>
    );
  }

  return (
    <Table fixed sortable>
      <Table.Header>
        <Table.Row>{columns.map(column => renderHeaderColumn(column))}</Table.Row>
      </Table.Header>
      <Table.Body>
        {(data || []).map(item => renderRow(item))}
        {isLoading && (
          <Table.Row>
            <Table.Cell colSpan={columns.length} textAlign="center">
              <Loader size="large" inline active />
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

export default ActivityLogTable;
