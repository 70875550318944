import { createSelector } from 'reselect';

import { AppState } from '../../_store/rootReducer';
import { SidebarState } from './reducer';

const selectNode = (state: AppState) => state.sidebar;

export const data = createSelector(selectNode, (state: SidebarState) => state.data);

export const isOpen = createSelector(selectNode, (state: SidebarState) => state.isOpen);

export const type = createSelector(selectNode, (state: SidebarState) => state.type);
