import { ITask, TaskState, TeamTaskState, ExecutionTime, TaskType } from './task';

export const taskStatesWithoutTeamTaskState = [TaskState.Unplanned, TaskState.CancelledByDispatcher];

export const canTaskBeRemoved = (task: ITask): boolean => {
  return (task.state === TaskState.Planned || task.state === TaskState.Unplanned) && task.isManuallyCreated;
};

export const canTaskBeEdited = (task: ITask): boolean => {
  return (
    task && task.state !== TaskState.CancelledByDispatcher && !task.teams.some(team => team.taskState === TeamTaskState.Ended)
  );
};

export const canTaskBeEnded = (task: ITask): boolean => {
  return task && (task.state === TaskState.Started || task.state === TaskState.Accepted);
};

export const canTaskBeStarted = (task: ITask): boolean => {
  return task && [TaskState.Accepted, TaskState.Planned].includes(task.state);
};

export const canAddTaskTeamToTask = (task: ITask): boolean => {
  return task.state === TaskState.Started || task.state === TaskState.Accepted || task.state === TaskState.Planned;
};

export const canTaskBeMoved = (task: ITask): boolean => {
  return !isTaskStateEqualToOrAfterState(task.state, TaskState.Started);
};

export const canTeamTaskBeMoved = (task: ITask, teamId?: string): boolean => {
  // A planned task without teams should also be draggable
  if (task.state === TaskState.Planned && task.teams.length < 1) return true;
  if (taskStatesWithoutTeamTaskState.includes(task.state)) {
    return !isTaskStateEqualToOrAfterState(task.state, TaskState.Started);
  }

  const currentTeam = teamId && task.teams.find(team => team.id === teamId);

  return currentTeam && !isTeamTaskStateEqualToOrAfterState(currentTeam.taskState, TeamTaskState.Started);
};

export const shouldConfirmPlanTask = (task: ITask, unplannedTeamIds: string[]): boolean => {
  if (!unplannedTeamIds || !unplannedTeamIds.length) return false;
  return unplannedTeamIds.some(teamId => {
    const team = task.teams.find(team => team.id === teamId);
    return team && isTeamTaskStateEqualToOrAfterState(team.taskState, TeamTaskState.Accepted);
  });
};

export const isTeamTaskStateEqualToOrAfterState = (teamTaskState: TeamTaskState, otherState: TeamTaskState): boolean => {
  const order = [
    TeamTaskState.Created,
    TeamTaskState.Accepted,
    TeamTaskState.Arrived,
    TeamTaskState.Started,
    TeamTaskState.Ended,
  ];
  return order.indexOf(teamTaskState) >= order.indexOf(otherState);
};

export const isTaskStateEqualToOrAfterState = (state: TaskState, otherState: TaskState): boolean => {
  const order = [
    TaskState.Unplanned,
    TaskState.Planned,
    TaskState.Accepted,
    TaskState.Started,
    TaskState.Ended,
    TaskState.CancelledByDispatcher,
  ];
  return order.indexOf(state) >= order.indexOf(otherState);
};

export const isTaskMainTeam = (task: ITask, teamId: string): boolean => {
  return !!task.teams.find(team => team.isMainTeam && team.id === teamId);
};

export const taskMainTeamId = (task: ITask): string => {
  const mainTeam = task.teams.find(team => team.isMainTeam);
  return mainTeam && mainTeam.id;
};

export const taskDerivedStartDate = (task: ITask): Date => new Date(task.travelStartTime);
export const taskDerivedEndDate = (task: ITask): Date => new Date(task.travelEndTime);

export const hasExecutionTimeWarning = (task: ITask): boolean => {
  return task && task.state !== TaskState.Unplanned && [ExecutionTime.NONE, ExecutionTime.REDUCED].includes(task.executionTime);
};

export const hasIncompleteWarning = (task: ITask): boolean => {
  return task && task.type === TaskType.Flight && !task.departureTime;
};

export const hasNoExecutionTime = (task: ITask): boolean => {
  return task && task.state !== TaskState.Unplanned && task.executionTime === ExecutionTime.NONE;
};

export const hasReducedExecutionTime = (task: ITask): boolean => {
  return task && task.state !== TaskState.Unplanned && task.executionTime === ExecutionTime.REDUCED;
};
