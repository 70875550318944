import classnames from 'classnames';
import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import AddTaskTeamModal from '../../tasks/modals/addTaskTeamModal/AddTaskTeamModal';
import ChangeTaskMainTeamModal from '../../tasks/modals/changeTaskMainTeamModal/ChangeTaskMainTeamModal';
import EndTaskModal from '../../tasks/modals/endTaskModal/EndTaskModal';
import MoveTaskModal from '../../tasks/modals/moveTaskModal/MoveTaskModal';
import RemoveTaskModal from '../../tasks/modals/removeTaskModal/RemoveTaskModal';
import {
  canAddTaskTeamToTask,
  canTaskBeEdited,
  canTaskBeEnded,
  canTaskBeRemoved,
  canTaskBeStarted,
  canTeamTaskBeMoved,
  hasExecutionTimeWarning,
  hasIncompleteWarning,
  isTeamTaskStateEqualToOrAfterState,
} from '../../tasks/_models/rules';
import { ExecutionTime, ITask, TaskState, TaskType, TeamTaskState } from '../../tasks/_models/task';
import { Button, Icon, TaskBadge } from '../../_shared';
import { issuesSelectors, tasksSelectors } from '../../_store/selectors';
import { translations } from '../../_translations';
import { timeHelper } from '../../_utils';
import SidebarFooter from '../sidebarFooter/SidebarFooter';
import SidebarHeader from '../sidebarHeader/SidebarHeader';
import { ISidebarData } from '../_models';
import { ShowSidebarTaskEditAction, ShowSidebarTaskIssuesAction } from '../_store/actions';

import { GetIssuesAction } from '../../issues/_store/actions';
import { AppState } from '../../_store/rootReducer';
import './taskDetail.scss';
import StartTaskModal from '../../tasks/modals/startTaskModal/StartTaskModal';

interface Props {
  data: ISidebarData;
}

function badgesForTask(task: ITask): string[] {
  const badges = [];
  if (task.isManuallyCreated && task.type !== TaskType.NonFlight) badges.push(translations.getLabel('MANUALLY_CREATED'));
  if (task.teams && task.teams.length > 1) badges.push(translations.getLabel('X_TEAMS', { amount: task.teams.length }));
  return badges;
}

function getTeamTaskStateTranslation(state: TeamTaskState): string {
  switch (state) {
    case TeamTaskState.Created:
      return translations.getLabel('SCHEDULED_NOT_ACCEPTED').toUpperCase();
    case TeamTaskState.Accepted:
      return translations.getLabel('SCHEDULED_ACCEPTED').toUpperCase();
    default:
      return translations.getLabel(state).toUpperCase();
  }
}

const TaskDetails: FC<Props> = ({ data }) => {
  const task = useSelector((state: AppState) => tasksSelectors.getTask(state, data.taskId));
  const dispatch = useDispatch();
  const issues = useSelector(issuesSelectors.getIssues);
  const isLoading = useSelector(issuesSelectors.isLoading);

  const { teamId } = data;
  const currentTeam = task && task.teams.find(team => team.id === teamId);

  useEffect(() => {
    if (!data.taskId) return;
    dispatch(new GetIssuesAction({ taskId: data.taskId }));
  }, [data.taskId, dispatch]);

  const renderFooterButtons = useCallback(() => {
    const isPlanned = !!currentTeam && isTeamTaskStateEqualToOrAfterState(currentTeam.taskState, TeamTaskState.Created);
    return (
      <>
        {canAddTaskTeamToTask(task) && (
          <AddTaskTeamModal task={task} trigger={<Button isTextLink>{translations.getLabel('EXTRA_TEAM')}</Button>} />
        )}
        {canTeamTaskBeMoved(task, teamId) && (
          <MoveTaskModal
            isPlannedTask={isPlanned}
            teamId={teamId}
            task={task}
            trigger={<Button primary>{translations.getLabel(isPlanned ? 'MOVE' : 'SCHEDULE')}</Button>}
          />
        )}
      </>
    );
  }, [currentTeam, task, teamId]);

  return (
    <div className="sidebar-inner task-detail">
      <div className="sidebar-header-buttons right">
        {canTaskBeStarted(task) && <StartTaskModal taskId={task.id} trigger={<Icon name="SvgStart" size={2.4} />} />}
        {canTaskBeEnded(task) && <EndTaskModal taskId={task.id} trigger={<Icon name="SvgFinished" size={2.4} />} />}
        {canTaskBeEdited(task) && (
          <Icon
            name="SvgEdit"
            size={2.4}
            onClick={() => dispatch(new ShowSidebarTaskEditAction({ data: { taskId: task.id, teamId } }))}
          />
        )}
        {task && task.id && canTaskBeRemoved(task) && (
          <RemoveTaskModal taskId={task.id} trigger={<Icon id="remove-task" name="SvgTrash" size={2.4} />} />
        )}
      </div>
      <SidebarHeader title={task.name} badges={badgesForTask(task)} />
      <div className="sidebar-content">
        <div className="row with-icon">
          {!!(task && task.blockTime) && (
            <div className="col">
              <Icon name="SvgLanding" size={2.4} />
              <div className="values">
                <span className="value">{timeHelper.formatTime(task.blockTime)}</span>
                <span className="sub-value">
                  {new Date(task.blockTime).toLocaleDateString('nl-BE', { month: 'long', day: 'numeric' })}
                </span>
              </div>
            </div>
          )}
          {!!(task && task.departureTime) && (
            <div className="col">
              <Icon name="SvgTakeoff" size={2.4} />
              <div className="values">
                <span className="value">{timeHelper.formatTime(task.departureTime)}</span>
                <span className="sub-value">
                  {new Date(task.departureTime).toLocaleDateString('nl-BE', { month: 'long', day: 'numeric' })}
                </span>
              </div>
            </div>
          )}
          {hasIncompleteWarning(task) && (
            <div className="col">
              <Icon name="SvgTakeoff" size={2.4} />
              <span className="value unknown-value">{translations.getLabel('UNKNOWN')}</span>
            </div>
          )}
        </div>
        <div className="row flight-info">
          <div className="col">
            <label>{translations.getLabel('FLIGHT_NUMBER')}</label>
            <span className={classnames('value', task && !task.arrivalFlightNumber && 'unknown-value')}>
              {(task && task.arrivalFlightNumber) || translations.getLabel('UNKNOWN')}
            </span>
          </div>
          <div className="col">
            <label>{translations.getLabel('FLIGHT_NUMBER')}</label>
            <span className={classnames('value', task && !task.departureFlightNumber && 'unknown-value')}>
              {(task && task.departureFlightNumber) || translations.getLabel('UNKNOWN')}
            </span>
          </div>
        </div>
        <div className="row flight-info">
          <div className="col">
            <label>{translations.getLabel('IATA')}</label>
            <span className={classnames('value', task && !task.origin && 'unknown-value')}>
              {(task && task.origin) || translations.getLabel('UNKNOWN')}
            </span>
          </div>
          <div className="col">
            <label>{translations.getLabel('IATA')}</label>
            <span className={classnames('value', task && !task.destination && 'unknown-value')}>
              {(task && task.destination) || translations.getLabel('UNKNOWN')}
            </span>
          </div>
        </div>
        {!!(task && task.imat) && (
          <div className="row flight-info">
            <div className="col">
              <label>{translations.getLabel('IMAT')}</label>
              <span className="value">{task.imat}</span>
            </div>
          </div>
        )}
        <TaskBadge task={task} teamId={teamId} isDivider />
        <div className="row with-icon">
          <div className="col">
            <Icon name="SvgStart" size={2.4} />
            <div className="values">
              <span className="value-light">{timeHelper.formatTime(task.startedAt) || '-'}</span>
              <span className="sub-value">
                {translations.getLabel('PLANNED_ON', { time: timeHelper.formatTime(task.startTime) })}
              </span>
            </div>
          </div>
          {!!(task && task.location) && (
            <div className="col">
              <Icon name="SvgLocation" size={2.4} /> <span className="value-light">{task.location}</span>
            </div>
          )}
        </div>
        <div className="row with-icon">
          <div className="col">
            <Icon name="SvgFinished" size={2.4} />
            <div className="values">
              <span className="value-light">{timeHelper.formatTime(task.endedAt) || '-'}</span>
              <span className="sub-value">
                {translations.getLabel('PLANNED_ON', { time: timeHelper.formatTime(task.endTime) })}
              </span>
            </div>
          </div>
          {!!(task && task.cleaningProcedure) && (
            <div className="col">
              <Icon name="SvgClean" size={2.4} />{' '}
              <span className="value-light">{translations.getLabel(`CLEANING_PROCEDURE_${task.cleaningProcedure}`)}</span>
            </div>
          )}
        </div>
        <div className="ui divider"></div>
        <div className="row issues">
          {isLoading ? (
            <div className="col">
              <Loader active inline /> <span>{translations.getLabel('ISSUES_LOADING')}</span>
            </div>
          ) : issues && issues.length > 0 ? (
            <>
              <Button negative isTextLink onClick={() => dispatch(new ShowSidebarTaskIssuesAction({ data }))}>
                <span>{`${issues.length} ${translations.getLabel(issues.length === 1 ? 'ISSUE' : 'ISSUES').toLowerCase()}`}</span>
                <Icon name="SvgChevronRight" size={2} />
              </Button>
              {!!task.unreadIssuesCount && (
                <span className="unread-issues">{`(${task.unreadIssuesCount} ${translations.getLabel('UNREAD')})`}</span>
              )}
            </>
          ) : (
            <span className="empty-issues">{translations.getLabel('ISSUES_EMPTY')}</span>
          )}
        </div>
        {(hasExecutionTimeWarning(task) || hasIncompleteWarning(task)) && <div className="ui divider"></div>}
        {hasExecutionTimeWarning(task) && (
          <div className="row with-icon">
            <div
              className={classnames('col', {
                'no-time': task.executionTime === ExecutionTime.NONE,
                'reduced-time': task.executionTime === ExecutionTime.REDUCED,
              })}
            >
              <Icon name="SvgAlertCircle" size={2.4} />
              <span className="value-light">
                {translations.getLabel(
                  task.executionTime === ExecutionTime.NONE ? 'WARNING_NO_TIME_FOR_TASK' : 'WARNING_REDUCED_TIME_FOR_TASK',
                )}
              </span>
            </div>
          </div>
        )}
        {hasIncompleteWarning(task) && (
          <div className="row with-icon">
            <div className="col no-time">
              <Icon name="SvgAlertCircle" size={2.4} />{' '}
              <span className="value-light">{translations.getLabel('TASK_MISSING_DEPARTURE_TIME')}</span>
            </div>
          </div>
        )}
        {task && task.teams && task.teams.length > 1 && (
          <>
            <div className="divider" />
            <div className="row with-icon">
              <div className="col teams">
                <div className="values">
                  <div className="team">
                    {currentTeam.isMainTeam && <Icon className="main-team-icon" name="SvgCrown" size={2.4} />}
                    <span className="value-light">{currentTeam.name}</span>
                    <span className="task-state">{getTeamTaskStateTranslation(currentTeam.taskState)}</span>
                    {currentTeam.isMainTeam && canAddTaskTeamToTask(task) && (
                      <ChangeTaskMainTeamModal task={task} trigger={<Icon name="SvgEdit" size={2} />} />
                    )}
                  </div>
                  <label>{translations.getLabel('REMAINING_TEAMS')}</label>
                  {task.teams
                    .filter(team => team.id !== currentTeam.id)
                    .map(team => (
                      <div className="team" key={team.id}>
                        {team.isMainTeam && <Icon className="main-team-icon" name="SvgCrown" size={2.4} />}
                        <span className="value-light">{team.name}</span>
                        <span className="task-state">{getTeamTaskStateTranslation(team.taskState)}</span>
                        {team.isMainTeam && canAddTaskTeamToTask(task) && (
                          <ChangeTaskMainTeamModal task={task} trigger={<Icon name="SvgEdit" size={2} />} />
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        )}
        {!!(task && task.description) && (
          <div className="row with-icon">
            <div className="col">
              <Icon name="SvgComment" size={2.4} /> <span className="value-light">{task.description}</span>
            </div>
          </div>
        )}
      </div>
      {task && task.state !== TaskState.Ended && <SidebarFooter>{renderFooterButtons()}</SidebarFooter>}
    </div>
  );
};

export default TaskDetails;
