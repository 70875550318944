import { isBefore, isAfter } from 'date-fns';

import { taskDerivedStartDate, taskDerivedEndDate } from '../../tasks/_models/rules';
import { ITask, TeamTaskState } from '../../tasks/_models/task';
import { ITeam } from './team';

export const canTeamExecuteTask = (team: ITeam, task: ITask, allowSameTeam = false): boolean => {
  // Should not be in task teams already
  if (!allowSameTeam && !!task.teams.find(t => t.id === team.id)) return false;
  // Should fall in shift
  const validFrom = new Date(team.validFrom);
  const validTo = new Date(team.validTo);
  const startTime = taskDerivedStartDate(task);
  const endTime = taskDerivedEndDate(task);
  return isBefore(validFrom, startTime) && isAfter(validTo, endTime);
};

export const canShiftHoursBeChanged = (tasks: ITask[], team: ITeam): boolean => {
  const taskStates = tasks.map(task => (task.teams.find(taskTeam => taskTeam.id === team.id) || {}).taskState);
  return !taskStates.some(taskState => taskState === TeamTaskState.Arrived || taskState === TeamTaskState.Started);
};
