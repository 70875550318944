import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import classnames from 'classnames';

import { ITeam } from '../_models/team';
import { Icon } from '../../_shared';
import { timeHelper } from '../../_utils';
import EditTeamModal from '../modals/editTeamModal/EditTeamModal';
import RemoveTeamModal from '../modals/removeTeamModal/RemoveTeamModal';
import { translations } from '../../_translations';
import { formatDateRange } from '../../_utils/timeHelper';
import { useModal } from '../../_hooks';

import './teamInfo.scss';

interface Props {
  team?: ITeam;
  isLoading?: boolean;
  hideActions?: boolean;
}

const TeamInfo: FC<Props> = ({ team, isLoading, hideActions }) => {
  const [renderEditTeamModal, showEditTeamModal] = useModal(modalProps => <EditTeamModal team={team} {...modalProps} />);
  const [renderRemoveTeamModal, showRemoveTeamModal] = useModal(modalProps => (
    <RemoveTeamModal teamId={team.id} {...modalProps} />
  ));

  if (isLoading) {
    return (
      <div className="team-info">
        <p className="name">
          <Skeleton />
        </p>
        <p className="shift">
          <Skeleton />
        </p>
        <p className="pda-user-loading">
          <Skeleton />
        </p>
      </div>
    );
  }

  if (!team) return null;
  const pdaUser = team.members && team.members.find(member => member.isPdaUser);

  const date = `\n\r${formatDateRange(team.validFrom, team.validTo, { format: 'd MMM', firstMonthFormat: 'd' }) || ''}`;
  const teamInfoClassName = classnames('team-info', { 'with-actions': !hideActions });
  return (
    <div className={teamInfoClassName}>
      <div>
        <p className="name">{team.name}</p>
        {pdaUser && (
          <p className="shift">
            {`${timeHelper.formatTime(team.validFrom)} - ${timeHelper.formatTime(team.validTo)}`}
            {!!date && <span className="shift-date">{date}</span>}
          </p>
        )}
        <span className="amount-of-members">{team.members && team.members.length}</span>
        {hideActions ? (
          <div className="pda-user">
            <Icon name="SvgAccount" size={1.6} />
            <p>{pdaUser && pdaUser.name}</p>
          </div>
        ) : (
          <div className="actions">
            <Icon
              className="team-edit"
              onClick={showEditTeamModal}
              label={translations.getLabel('EDIT_TEAM')}
              name="SvgEdit"
              size={1.6}
            />
            <Icon
              className="team-remove"
              onClick={showRemoveTeamModal}
              label={translations.getLabel('REMOVE_TEAM')}
              name="SvgTrash"
              size={1.6}
            />
            {renderEditTeamModal()}
            {renderRemoveTeamModal()}
          </div>
        )}
      </div>
      {!hideActions && (
        <div className="actions">
          <Icon
            className="team-edit"
            onClick={showEditTeamModal}
            label={translations.getLabel('EDIT_TEAM')}
            name="SvgEdit"
            size={1.6}
          />
          {renderEditTeamModal()}
        </div>
      )}
    </div>
  );
};

export default TeamInfo;
