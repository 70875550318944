import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';

import { ITaskGroup } from '../_models/taskGroup';
import { timeHelper } from '../../_utils';
import { Icon } from '../../_shared';
import { translations } from '../../_translations';
import { ToggleTaskGroupAction } from '../_store/actions';
import { planningSelectors, tasksSelectors } from '../../_store/selectors';
import { AppState } from '../../_store/rootReducer';

import './taskGroup.scss';

interface Props {
  taskGroup: ITaskGroup;
  teamId: string;
}

const TaskGroup: FC<Props> = ({ taskGroup, teamId }) => {
  const currentDate = useSelector(planningSelectors.currentDate);
  const collapsedTaskIds = useSelector((state: AppState) => tasksSelectors.collapsedTaskIdsForTeam(state, teamId));
  const dispatch = useDispatch();

  if (taskGroup.taskIds.length <= 1) return null;
  const isOpen = !collapsedTaskIds.some(id => taskGroup.taskIds.includes(id));

  const { offsetStart, offsetEnd, isPrevDay } = timeHelper.getDateOffsetWithBoundaries(
    taskGroup.startDate,
    taskGroup.endDate,
    currentDate,
  );
  return (
    <div
      className={classnames('timeline-task-group', { 'overflow-left': isPrevDay })}
      style={{ left: offsetStart, width: offsetEnd - offsetStart }}
      onClick={() =>
        dispatch(
          new ToggleTaskGroupAction({
            taskIds: taskGroup.taskIds,
            isOpen: !isOpen,
            teamId,
          }),
        )
      }
    >
      <Icon name={isOpen ? 'SvgChevronDown' : 'SvgChevronRight'} size={2.4} />
      <span className="title">{translations.getLabel('AMOUNT_OF_TASKS', { amount: taskGroup.taskIds.length })}</span>
      <span className="time">
        {timeHelper.formatTime(taskGroup.startDate)} - {timeHelper.formatTime(taskGroup.endDate)}
      </span>
    </div>
  );
};

export default TaskGroup;
