import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { push } from 'connected-react-router';
import { tap, switchMap, map, catchError, exhaustMap } from 'rxjs/operators';

import { sessionStorageHelper } from '../../_utils';
import { SetCurrentDateAction } from '../../planning/_store/actions';
import { authenticationApi } from '../../_store/api';
import { ActionType, LoginAction, LoginSuccessAction, LoginErrorAction } from './actions';

export const loginEpic$: Epic = action$ =>
  action$.ofType(ActionType.Login).pipe(
    exhaustMap(({ payload }: LoginAction) =>
      from(authenticationApi.login(payload.apiKey)).pipe(
        map(() => new LoginSuccessAction(payload)),
        catchError(error => of(new LoginErrorAction({ error }))),
      ),
    ),
  );

export const loginSuccessEpic$: Epic = action$ =>
  action$.ofType(ActionType.LoginSuccess).pipe(
    tap(({ payload }: LoginSuccessAction) => sessionStorageHelper.setApiKey(payload.apiKey)),
    switchMap(({ payload }: LoginSuccessAction) => {
      if (!payload.noRootRedirect) {
        return of(new SetCurrentDateAction({ date: new Date() }), push('/'));
      }
      return of(new SetCurrentDateAction({ date: new Date() }));
    }),
  );

export const loginErrorEpic$: Epic = action$ =>
  action$.ofType(ActionType.LoginError).pipe(
    tap(() => sessionStorageHelper.removeApiKey()),
    switchMap(() => of(push('/auth/login'))),
  );

const TasksEpics = [loginEpic$, loginSuccessEpic$, loginErrorEpic$];

export default TasksEpics;
