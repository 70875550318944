import React from 'react';
import { NavLink } from 'react-router-dom';

import { SvgLogo } from '../../_assets/svg';
import { translations } from '../../_translations';

import './menu.scss';

const Menu = () => {
  return (
    <nav className="main-menu">
      <aside>
        <SvgLogo />
      </aside>
      <NavLink to={'/planning'}>{translations.getLabel('PLANNING')}</NavLink>
      <NavLink to={'/activity-log'}>{translations.getLabel('HISTORY')}</NavLink>
      <NavLink to={'/reports'}>{translations.getLabel('REPORTS')}</NavLink>
    </nav>
  );
};

export default Menu;
