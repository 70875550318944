import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { GRID_ROW_HEIGHT, GRID_ROW_HEIGHT_UNPLANNED, GRID_ROW_HEIGHT_UNPLANNED_LOADING } from '../../_constants';
import { ITeam } from '../../teams/_models/team';
import TeamInfo from '../../teams/teamInfo/TeamInfo';
import { taskHelper } from '../../_utils';
import { tasksSelectors } from '../../_store/selectors';
import { AppState } from '../../_store/rootReducer';

import constants from '../../_styles/constants.module.scss';

interface Props {
  team?: ITeam;
  isUnplanned?: boolean;
  isLoading?: boolean;
}

const TeamRow: FC<Props> = ({ team, isUnplanned, isLoading }) => {
  const teamId = team && team.id;
  const tasks = useSelector(
    isUnplanned ? tasksSelectors.tasksUnplanned : (state: AppState) => tasksSelectors.getTasksForTeam(state, teamId),
  );
  const taskGroups = useSelector((state: AppState) => tasksSelectors.getTaskGroupsForTeam(state, teamId));
  const collapsedTaskIds = useSelector((state: AppState) => tasksSelectors.collapsedTaskIdsForTeam(state, teamId));

  const rowHeight = isUnplanned ? (isLoading ? GRID_ROW_HEIGHT_UNPLANNED_LOADING : GRID_ROW_HEIGHT_UNPLANNED) : GRID_ROW_HEIGHT;
  const taskRows = taskHelper.reduceTasks(tasks);
  const hasGroups = taskGroups.length > 0;

  // Remove the tasks that shouldn't be shown (if a taskGroup is collapsed)
  const filteredTasks = (taskRows || [])
    .map(taskRow => taskRow.filter(task => !collapsedTaskIds.includes(task.id)))
    .filter(taskRow => taskRow.length);

  const rowSpan = filteredTasks.length;
  return (
    <>
      <div
        className="team"
        style={{
          height: `${(rowHeight * rowSpan || rowHeight) + (hasGroups && rowSpan ? parseFloat(constants.taskGroupHeight) : 0)}rem`,
        }}
      >
        <TeamInfo team={team} isLoading={isLoading && !isUnplanned} hideActions />
      </div>
      {/* We need a separate separator div, to match the height of the task-row-wrapper in a task-row */}
      <div className="team-separator" />
    </>
  );
};

export default TeamRow;
