import { useEffect } from 'react';

import { ApiError } from '../_http';
import usePrevious from './usePrevious';

const useActionPromise = (isLoading: boolean, onSuccess: () => void, onError: () => void, error?: ApiError) => {
  const prevProps = usePrevious({ isLoading });

  useEffect(() => {
    if (prevProps && prevProps.isLoading && !isLoading) {
      if (!error) {
        onSuccess();
      } else {
        onError();
      }
    }
  });
};

export default useActionPromise;
