import React, { FC } from 'react';
import Icon from '../icon/Icon';

import './badge.scss';

export interface BadgeProps {
  label: string;
  icon?: string;
  className?: string;
}

const Badge: FC<BadgeProps> = ({ label, icon, className }) => {
  return (
    <span className={`badge ${className}`}>
      {!!icon && <Icon size={1.2} name={icon} />} {label}
    </span>
  );
};

Badge.defaultProps = {
  className: '',
};

export default Badge;
