import React, { FC } from 'react';
import classnames from 'classnames';
import Icon from '../icon/Icon';
import './nightStopTooltip.scss';
import { translations } from '../../_translations';

interface Props {
  isFirstRow: boolean;
}

const NightStopTooltip: FC<Props> = ({ isFirstRow }) => {
  return (
    <div className={classnames('nightStopIcon', isFirstRow && 'isFirst')} aria-label={translations.getLabel('NIGHT_STOP_TASK')}>
      <Icon name="SvgMoon" size={1.3} aria-hidden />
    </div>
  );
};

export default NightStopTooltip;
