import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { CloseModalAction } from '../_store/actions';
import { IModalData } from '../_models';
import { Button, Modal } from '../../_shared';
import { translations } from '../../_translations';

interface Props {
  data?: IModalData;
}

const WarningModal: FC<Props> = ({ data }) => {
  const dispatch = useDispatch();

  const closeModal = () => dispatch(new CloseModalAction());

  return (
    <Modal open onClose={closeModal}>
      <Modal.Header>{data.title}</Modal.Header>
      <Modal.Content>
        <p>{data.content}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={closeModal}>
          {translations.getLabel('CLOSE')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default WarningModal;
