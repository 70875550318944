import { ApiError } from '../../_http';
import { IIssue } from '../_models/issue';
import { Actions, ActionType } from './actions';

export interface IssuesState {
  isLoading: boolean;
  isMarkIssueAsReadLoading: boolean;
  error?: ApiError;
  errorMarkIssueAsRead?: ApiError;
  issues: IIssue[];
}

const initialState: IssuesState = {
  isLoading: false,
  isMarkIssueAsReadLoading: false,
  issues: [],
};

export default function reducer(state = initialState, action: Actions): IssuesState {
  switch (action.type) {
    case ActionType.GetIssues:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case ActionType.GetIssuesSuccess:
      return {
        ...state,
        isLoading: false,
        issues: action.payload.data,
      };
    case ActionType.GetIssuesError:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case ActionType.MarkIssueAsRead:
      return {
        ...state,
        isMarkIssueAsReadLoading: true,
        errorMarkIssueAsRead: null,
      };
    case ActionType.MarkIssueAsReadSuccess:
      return {
        ...state,
        isMarkIssueAsReadLoading: false,
      };
    case ActionType.MarkIssueAsReadError:
      return {
        ...state,
        isMarkIssueAsReadLoading: false,
        errorMarkIssueAsRead: action.payload.error,
      };
    default:
      return state;
  }
}
