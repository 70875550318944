import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { translations } from '../_translations';
import { useInfiniteScroll } from '../_hooks';
import { activityLogSelectors } from '../_store/selectors';
import { IActivityLogFilter } from './_models/activityLogFilter';
import ActivityLogFilter from './ActivityLogFilter';
import ActivityLogTable from './ActivityLogTable';
import { GetActivityLogAction, SetActivityLogFilterAction } from './_store/actions';

import './activityLog.scss';

const ActivityLog = () => {
  const data = useSelector(activityLogSelectors.data);
  const metadata = useSelector(activityLogSelectors.metadata);
  const filter = useSelector(activityLogSelectors.filter);
  const isLoading = useSelector(activityLogSelectors.isLoading);
  const dispatch = useDispatch();

  const ref = useRef<HTMLDivElement>(null);
  const { resetOffset } = useInfiniteScroll(
    (offset: number) => dispatch(new GetActivityLogAction({ offset })),
    metadata,
    isLoading,
    ref,
  );

  const setFilter = (partialFilter: IActivityLogFilter) => {
    dispatch(new SetActivityLogFilterAction({ filter: { ...filter, ...partialFilter } }));
    resetOffset();
  };

  const noData = !isLoading && data && data.length === 0;
  return (
    <div ref={ref} className="activity-log">
      <ActivityLogFilter setFilter={setFilter} />
      {noData ? (
        <div className="no-data">
          <span>{translations.getLabel('NO_DATA_SEARCH')}</span>
          <span>{translations.getLabel('HISTORY_TABLE_NO_DATA')}</span>
        </div>
      ) : (
        <ActivityLogTable data={data} isLoading={isLoading} setFilter={setFilter} />
      )}
    </div>
  );
};

export default ActivityLog;
