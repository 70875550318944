import { useEffect, useState, RefObject } from 'react';

import { HttpMetadataPagingResponse } from '../_http/HttpMetadata';

const SCROLL_OFFSET = 150;

interface Response {
  resetOffset: () => void;
}

const useInfiniteScroll = (
  fetchData: (offset: number) => void,
  metadata: HttpMetadataPagingResponse,
  isLoading: boolean,
  ref: RefObject<HTMLDivElement>,
): Response => {
  const [offset, setOffset] = useState<number>(0);

  function didFetchTotal(): boolean {
    return metadata && offset === metadata.totalCount;
  }

  function shouldFetchData(): boolean {
    if (isLoading) return false;
    if (didFetchTotal()) return false;
    return window.scrollY + window.innerHeight >= document.documentElement.scrollHeight - SCROLL_OFFSET;
  }

  function startFetchData() {
    const newOffset = offset + (metadata ? metadata.count : 0);
    setOffset(newOffset);
    fetchData(newOffset);
  }

  useEffect(() => {
    if (ref.current.scrollHeight < window.innerHeight && !didFetchTotal()) {
      startFetchData();
    }
  }, [metadata]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleScroll = () => {
      if (shouldFetchData()) {
        startFetchData();
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoading, metadata]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    resetOffset: () => setOffset(0),
  };
};

export default useInfiniteScroll;
