import { isBefore, isAfter, endOfDay, startOfDay, format, parse, isValid, isSameDay, isSameMonth } from 'date-fns';
import { nl } from 'date-fns/locale';
import { differenceInSeconds } from 'date-fns/esm';

import constants from '../_styles/constants.module.scss';

export const getQuartersOfTheDay = (): Date[] => {
  const times = [];

  for (let index = 0; index < 24; index++) {
    for (let i = 0; i < 4; i++) {
      times.push(new Date(0, 0, 0, index, i * 15));
    }
  }

  return times;
};

export const getDateOffset = (date: Date): number => {
  const quarter = 15;
  const hour = date.getHours() * 60;
  const minutes = date.getMinutes();

  return ((hour + minutes) / quarter) * parseInt(constants.quarterWidth);
};

export const getDateOffsetWithBoundaries = (
  startDate: Date,
  endDate: Date,
  currentDate: Date,
): { offsetStart: number; offsetEnd: number; isPrevDay?: boolean; isNextDay?: boolean } => {
  // Calculate if startDate is outside the current Date
  const isPrevDay = isBefore(startDate, startOfDay(currentDate));
  const realStartDate = isPrevDay ? startOfDay(currentDate) : startDate;
  const offsetStart = getDateOffset(realStartDate) - (isPrevDay ? parseInt(constants.taskOverflow) : 0);

  // Calculate if endDate is outside the current Date
  const isNextDay = isAfter(endDate, endOfDay(currentDate));
  const realEndDate = isNextDay ? endOfDay(currentDate) : endDate;
  const offsetEnd = getDateOffset(realEndDate) + (isNextDay ? parseInt(constants.taskOverflow) : 0);

  return { offsetStart, offsetEnd, isPrevDay, isNextDay };
};

export const formatDate = (date: Date, formatString = 'dd/MM/yyyy'): string => {
  if (!isValid(date)) return null;
  return format(date, formatString, { locale: nl });
};

export const formatTime = (time: string | Date): string => {
  if (!time) return null;
  return formatDate(new Date(time), 'HH:mm');
};

export const dateFromString = (dateString: string, formatString = 'dd/MM/yyyy'): Date => {
  if (!dateString) return null;
  const date = parse(dateString, formatString, new Date(), { locale: nl });
  if (!isValid(date)) return null;
  return date;
};

export const dateFromTime = (timeString: string): Date => {
  return dateFromString(timeString, 'HH:mm');
};

export const dateFromISOString = (isoString?: string): Date => {
  if (!isoString) return null;
  return new Date(isoString);
};

export const ISOStringFromDate = (date?: Date): string => {
  if (!isValid(date)) return null;
  return date.toISOString();
};

export const differenceBetweenDates = (dateLeft: Date, dateRight: Date): { value: string; positive: boolean } => {
  const numberToString = (value: number): string => `${`${value}`.length === 1 ? '0' : ''}${Math.abs(value)}`;
  const totalSeconds = differenceInSeconds(dateLeft, dateRight);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return { value: `${numberToString(minutes)}m ${numberToString(seconds)}s`, positive: totalSeconds >= 0 };
};

export const formatDateRange = (
  start: string,
  end: string,
  options: { format: string; firstMonthFormat?: string; showSingleDay?: boolean },
): string => {
  const startDate = dateFromISOString(start);
  const endDate = dateFromISOString(end);
  if (!isValid(endDate) || !isValid(startDate)) return null;
  if (isSameDay(startDate, endDate)) return options.showSingleDay ? formatDate(endDate, options.format) : null;
  if (isSameMonth(startDate, endDate) && options.firstMonthFormat) {
    return `(${formatDate(startDate, options.firstMonthFormat)} - ${formatDate(endDate, options.format)})`;
  }
  // Different month
  return `(${formatDate(startDate, options.format)} - ${formatDate(endDate, options.format)})`;
};
