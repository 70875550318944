import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import classnames from 'classnames';

import { translations } from '../../_translations';
import { TaskBadge, Icon, NightStopTooltip } from '../../_shared';
import { ShowSidebarTaskDetailAction } from '../../sidebar/_store/actions';
import { CleaningProcedureType, ITask, TaskState } from '../_models/task';
import {
  isTaskMainTeam,
  taskDerivedStartDate,
  taskDerivedEndDate,
  isTaskStateEqualToOrAfterState,
  hasExecutionTimeWarning,
  hasNoExecutionTime,
  hasReducedExecutionTime,
  hasIncompleteWarning,
} from '../_models/rules';
import { timeHelper } from '../../_utils';
import { planningSelectors, sidebarSelectors } from '../../_store/selectors';

import './task.scss';

interface Props {
  task: ITask;
  teamId?: string;
  isLoading?: boolean;
  index?: number;
}

function timeWindowStyle(task: ITask, currentDate: Date, taskOffsetStart: number): { width: number; left: number } {
  const { offsetStart, offsetEnd } = timeHelper.getDateOffsetWithBoundaries(
    new Date(task.startedAt || task.earliestPossibleStartTime),
    new Date(task.latestPossibleEndTime),
    currentDate,
  );
  return { width: offsetEnd - offsetStart, left: offsetStart - taskOffsetStart };
}

function shouldShowTimeWindow(task: ITask): boolean {
  if (isTaskStateEqualToOrAfterState(task.state, TaskState.Ended)) return false;
  return !!task.latestPossibleEndTime && !!task.earliestPossibleStartTime;
}

const Task: FC<Props> = ({ task, teamId, isLoading, index }) => {
  const currentDate = useSelector(planningSelectors.currentDate);
  const sidebarData = useSelector(sidebarSelectors.data);
  const dispatch = useDispatch();

  const { offsetStart, offsetEnd, isPrevDay } = timeHelper.getDateOffsetWithBoundaries(
    taskDerivedStartDate(task),
    taskDerivedEndDate(task),
    currentDate,
  );
  const width = offsetEnd - offsetStart;

  if (isLoading)
    return (
      <div className="timeline-task" style={{ left: offsetStart, width }}>
        <Skeleton height={80} width={150} />
      </div>
    );
  const isSelectedTask = sidebarData.taskId === task.id;
  const isSelectedTeam = isSelectedTask && sidebarData.teamId === teamId;
  const wrapperClassName = classnames('timeline-task', task.state.toLowerCase(), {
    'overflow-left': isPrevDay,
    'selected-team': isSelectedTeam,
    'selected-task': isSelectedTask,
    'no-time': hasNoExecutionTime(task),
    'reduced-time': hasReducedExecutionTime(task),
  });

  return (
    <div
      className={wrapperClassName}
      onClick={() => dispatch(new ShowSidebarTaskDetailAction({ data: { taskId: task.id, teamId } }))}
      style={{ left: offsetStart, width }}
    >
      {shouldShowTimeWindow(task) && (
        <div className="time-window not-working-block" style={timeWindowStyle(task, currentDate, offsetStart)} />
      )}
      <div className="content">
        <div className="title-wrapper">
          <span>
            {task.cleaningProcedure === CleaningProcedureType.NBNightStop && <NightStopTooltip isFirstRow={index === 0} />}
          </span>
          <span className={classnames('title', { delayed: task.isDelayed })}>
            {hasExecutionTimeWarning(task) && <Icon name="SvgAlertCircle" size={1.2} />}
            {task.name} / {task.imat || '-'}
          </span>
        </div>
        {task.flightState && (
          <span className={`flight-state ${task.flightState.toLowerCase()}`}>{translations.getLabel(task.flightState)}</span>
        )}
        {hasIncompleteWarning(task) && task.state !== TaskState.Unplanned && (
          <div className="incomplete-alert">{translations.getLabel('INCOMPLETE_ALERT').toLocaleLowerCase()}</div>
        )}
        {task.teams && task.teams.length > 1 && (
          <div className="teams-info">
            <span className="teams-count">
              {task.teams.length} <Icon name="SvgTeam" size={1.2} />
            </span>
            {isTaskMainTeam(task, teamId) && (
              <span className="main-team">
                <Icon name="SvgCrown" size={1.2} />
              </span>
            )}
          </div>
        )}
        {!!task.teams.length && task.unreadIssuesCount > 0 && <p className="issues">{task.unreadIssuesCount}</p>}
      </div>
      <TaskBadge task={task} isTime teamId={teamId} />
    </div>
  );
};

export default Task;
