import React, { FC } from 'react';
import { endOfDay } from 'date-fns';

import { GRID_ROW_HEIGHT } from '../../_constants';
import { timeHelper } from '../../_utils';
import { ITeam } from '../../teams/_models/team';

interface Props {
  team: ITeam;
  currentDate: Date;
  isLoading?: boolean;
}

const NotWorkingBlock: FC<Props> = ({ team, currentDate }) => {
  const { offsetStart, offsetEnd } = timeHelper.getDateOffsetWithBoundaries(
    new Date(team.validFrom),
    new Date(team.validTo),
    currentDate,
  );
  const offsetEndOfDay = timeHelper.getDateOffset(endOfDay(currentDate));

  return (
    <>
      <div className="not-working-block" style={{ height: `${GRID_ROW_HEIGHT}rem`, left: 0, width: offsetStart }} />
      <div
        className="not-working-block"
        style={{ height: `${GRID_ROW_HEIGHT}rem`, left: offsetEnd, width: offsetEndOfDay - offsetEnd }}
      />
    </>
  );
};

export default NotWorkingBlock;
