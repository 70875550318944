import { useState, useEffect } from 'react';
import { RELOAD_INTERVAL } from '../_constants';

const useCurrentTime = (): Date => {
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  useEffect(() => {
    const timeout = setInterval(() => setCurrentTime(new Date()), RELOAD_INTERVAL);
    return () => clearTimeout(timeout);
  }, []);

  return currentTime;
};

export default useCurrentTime;
