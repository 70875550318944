import { Action } from 'redux';

export enum ModalType {
  Confirmation = 'CONFIRMATION',
  Warning = 'WARNING',
}

export interface IModalData {
  title: string;
  content: string;
}

export interface IConfirmationModalData extends IModalData {
  confirmText: string;
  confirmAction: () => Action;
  cancelAction: () => Action;
}
