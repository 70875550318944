import { ApiError } from '../../_http';
import { IActivityLogItem } from '../_models/activityLogItem';
import { HttpMetadataPagingResponse } from '../../_http/HttpMetadata';
import { IActivityLogFilter } from '../_models/activityLogFilter';
import { ActionType, Actions } from './actions';

export interface ActivityLogState {
  isLoading: boolean;
  data?: IActivityLogItem[];
  metadata?: HttpMetadataPagingResponse;
  error?: ApiError;
  filter?: IActivityLogFilter;
  isExporting?: boolean;
  errorExporting?: ApiError;
}

const initialState: ActivityLogState = {
  isLoading: false,
};

export default function reducer(state = initialState, action: Actions): ActivityLogState {
  switch (action.type) {
    case ActionType.GetActivityLog:
      return {
        ...state,
        isLoading: true,
      };
    case ActionType.GetActivityLogSuccess: {
      let currentData = state.data || [];
      if (!action.payload.offset) currentData = []; // Start overnew when the offset was reset
      const updatedIds = action.payload.data.map(value => value.id);
      return {
        ...state,
        isLoading: false,
        data: [...currentData.filter(value => !updatedIds.includes(value.id)), ...action.payload.data],
        metadata: action.payload.meta,
        error: null,
      };
    }
    case ActionType.GetActivityLogError:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case ActionType.SetActivityLogFilter:
      return {
        ...state,
        filter: action.payload.filter,
      };
    case ActionType.ExportActivityLog:
      return {
        ...state,
        isExporting: true,
      };
    case ActionType.ExportActivityLogSuccess:
      return {
        ...state,
        isExporting: false,
        errorExporting: null,
      };
    case ActionType.ExportActivityLogError:
      return {
        ...state,
        isExporting: false,
        errorExporting: action.payload.error,
      };
    default:
      return state;
  }
}
